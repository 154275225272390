import { ContextManager } from "~/modules/contextManager/ContextManager";
import { TelemetryClient } from "~/modules/telemetry/TelemetryClient/TelemetryClient";
import { TelemetryClientImpl } from "~/modules/telemetry/TelemetryClient/TelemetryClientImpl/TelemetryClientImpl";

export function createTelemetryClient<U extends object>(ctx: ContextManager) {
    return (name: string): TelemetryClient<U> => {
        const telemetryClient = new TelemetryClientImpl<U>(ctx);
        telemetryClient.name = name;
        return telemetryClient;
    };
}
