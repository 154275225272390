import RoutingProperties, { RoutingData } from './RoutingProperties';
import { mapKeysToCamelCase } from './util/MapKeys';
import { MediaChannelType } from './cbm_sdk_client/types/MediaChannelType';





export enum ParticipantType {
    Agent = 'agent',
    Customer = 'customer',
    Supervisor = 'supervisor',
    External = 'external',
    Unknown = 'unknown'
}





export type MediaProperties = {
    
    [key: string]: any;
};





export type ParticipantEventData = {
    


    participant_sid: string;
    


    type: ParticipantType;
    


    channel_type: MediaChannelType;
    


    channel_sid: string;
    


    interaction_sid: string;
    


    routing_properties: RoutingData | null;
    


    media_properties: MediaProperties | null;
};





export type ParticipantData = {
    


    sid: string;
    


    type: ParticipantType;
    


    channel_type: MediaChannelType;
    


    channel_sid: string;
    


    interaction_sid: string;
    


    routing_properties: RoutingData | null;
    


    media_properties: MediaProperties | null;
};





class CbmParticipant {
    


    readonly participantSid: string;

    


    readonly type: ParticipantType;

    


    readonly channelType: MediaChannelType;

    


    readonly channelSid: string;

    


    readonly interactionSid: string;

    


    readonly lastReadMessageIndex?: number;

    


    readonly lastReadTimestamp?: Date;

    


    readonly routingProperties?: RoutingProperties;

    


    readonly mediaProperties?: MediaProperties;

    constructor(participantData: ParticipantEventData) {
        this.participantSid = participantData.participant_sid;
        this.channelSid = participantData.channel_sid;
        this.interactionSid = participantData.interaction_sid;
        this.type = participantData.type;
        this.channelType = participantData.channel_type;

        



        if (participantData.routing_properties !== null) {
            this.routingProperties = new RoutingProperties(participantData.routing_properties);
        }

        





        if (participantData.media_properties !== null) {
            this.mediaProperties = mapKeysToCamelCase(participantData.media_properties);
        }
    }
}





export type Participant = {
    


    sid: string;
    


    type: ParticipantType;
    


    channelSid: string;
    


    mediaProperties: MediaProperties | null;
};

export default CbmParticipant;
