












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { HttpAdapterImpl } from "~/backend/HttpAdapter/HttpAdapterImpl";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods"
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { AccountConfigData } from "../model/accountConfigData";
import { PublicConfigResponse } from "../model/publicConfigResponse";

import { ConfigurationService }  from "./configuration.serviceInterface";

export class ConfigurationServiceImpl implements ConfigurationService {
    private httpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.httpAdapter = new HttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.httpAdapter.setIsEdgeSupported(true);
    }

    






    public async fetchConfiguration(queryParamName?: 'UiVersion', queryParamValue?: string, options?: object): Promise<AccountConfigData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.httpAdapter.get<AccountConfigData>(`https://flex-api[region].twilio.com/v1/Configuration?${encodeURIComponent(String(queryParamName))}=${encodeURIComponent(String(queryParamValue))}`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ConfigurationService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;
            }

            return Promise.reject(e)
        }
        const processedResponse = convertDateStringValuesToDate<AccountConfigData>(response);
        return processedResponse;
    }

    






    public async fetchPublicConfiguration(queryParamName: 'AccountSid' | 'RuntimeDomain', queryParamValue: string): Promise<PublicConfigResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (queryParamName === null || queryParamName === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter queryParamName was null or undefined.");
        }
        if (queryParamValue === null || queryParamValue === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter queryParamValue was null or undefined.");
        }

        let response;
        try {
            response = await this.httpAdapter.get<PublicConfigResponse>(`https://flex-api[region].twilio.com/v1/Configuration/Public?${encodeURIComponent(String(queryParamName))}=${encodeURIComponent(String(queryParamValue))}`, undefined);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ConfigurationService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;
            }

            return Promise.reject(e)
        }
        const processedResponse = convertDateStringValuesToDate<PublicConfigResponse>(response);
        return processedResponse;
    }

    





    public async updateConfiguration(accountConfigData: Partial<AccountConfigData>): Promise<AccountConfigData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountConfigData === null || accountConfigData === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountConfigData was null or undefined.");
        }

        let response;
        try {
            response = await this.httpAdapter.post<AccountConfigData>(`https://flex-api[region].twilio.com/v1/Configuration`, AuthenticationMethod.Basic, accountConfigData);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ConfigurationService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;
            }

            return Promise.reject(e)
        }
        const processedResponse = convertDateStringValuesToDate<AccountConfigData>(response);
        return processedResponse;
    }
}

