import * as React from "react";
import * as ApolloClient from "@apollo/client";




export interface DataProviderProps {
    children: JSX.Element;
    dataClient: ApolloClient.ApolloClient<ApolloClient.NormalizedCacheObject>;
}




export const DataProvider = ({ children, dataClient }: DataProviderProps): JSX.Element => {
    return (
        <>{dataClient && <ApolloClient.ApolloProvider client={dataClient}>{children}</ApolloClient.ApolloProvider>}</>
    );
};

DataProvider.displayName = "DataProvider";
