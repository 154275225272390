
import { mergeSchemas } from "@graphql-tools/schema";
import { stitchSchemas } from "@graphql-tools/stitch";
import type { IResolvers, TypeSource } from "@graphql-tools/utils";
import { GraphQLSchema } from "graphql/type";
import { GraphQLError } from "graphql";
import { DataClientError } from "../error";
import { fetchLocalSchema } from "./localSchema";
import { fetchRemoteSchema } from "./remoteSchema";

const customErrorHandler = (error: GraphQLError) => {
    
    return new GraphQLError(
        error.message,
        error.nodes,
        error.source,
        error.positions,
        error.path,
        error.originalError,
        {
            ...error.extensions,
            classification: error.extensions?.classification || "GraphQL error"
        }
    );
};

const getGatewaySchema = async (
    uri: string,
    token: string,
    customSchema?: { typeDefs: TypeSource; resolvers: IResolvers | IResolvers[] },
    localSchema?: { typeDefs: TypeSource; resolvers: IResolvers | IResolvers[] }
): Promise<GraphQLSchema | null> => {
    try {
        const schemas: Array<{
            schema: GraphQLSchema;
            executor?: any;
            transforms?: any[];
        }> = [];

        const remoteSchema = await fetchRemoteSchema({ uri, token });
        if (remoteSchema) {
            schemas.push({
                ...remoteSchema,
                transforms: [
                    {
                        transformRequest: (originalRequest: any) => originalRequest,
                        transformResult: (originalResult: any) => {
                            if (originalResult.errors) {
                                return {
                                    ...originalResult,
                                    errors: originalResult.errors.map(customErrorHandler)
                                };
                            }
                            return originalResult;
                        }
                    }
                ]
            });
        }

        if (localSchema) {
            const fetchedLocalSchema = fetchLocalSchema(localSchema);
            if (fetchedLocalSchema) {
                schemas.push(fetchedLocalSchema);
            }
        }

        if (!schemas.length) {
            return null;
        }

        const stitchedSchemas = stitchSchemas({
            subschemas: schemas
        });

        if (customSchema) {
            return mergeSchemas({
                schemas: [stitchedSchemas],
                resolvers: customSchema.resolvers,
                typeDefs: customSchema.typeDefs
            });
        }
        return stitchedSchemas;
    } catch (error) {
        throw new Error(DataClientError.GraphqlSchemaStitchingFailed);
    }
};

export { getGatewaySchema };
