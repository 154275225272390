import { ApolloClient, ApolloLink, Resolvers, split, DocumentNode } from "@apollo/client/core";
import { HttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";
import { from } from "@apollo/client/link/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import type { TypePolicies } from "@apollo/client/cache/inmemory/policies";
import { setContext } from "@apollo/client/link/context";
import { createClient } from "graphql-ws";
import { WebSocket } from "ws";
import { print } from "graphql";
import { getEnvironmentConfig } from "~/modules/config";
import { buildRegionalHost } from "~/utils/regionUtil";
import { DataClientError } from "../error";
import { cache } from "./cache";




export interface DataClientOptions {
    connectToDevTools?: boolean;
    localSchema?: { typeDefs: string | string[] | DocumentNode | DocumentNode[]; resolvers: Resolvers | Resolvers[] };
    errorLink?: ApolloLink;
    typePolicies?: TypePolicies;
}




export type GetToken = () => Promise<string> | string;




export interface CreateDataClientOptions extends DataClientOptions {
    region?: string;
}




export const createDataClient = async (tokenOrGetToken: string | GetToken, options?: CreateDataClientOptions) => {
    const MAX_HEADER_SIZE = 8000; 

    try {
        const environmentConfig = getEnvironmentConfig();
        const { region } = environmentConfig || {};
        const regionToUse = buildRegionalHost(region || options?.region || "");
        const graphQLSubscriptionUri = `wss://event-bridge${regionToUse}.twilio.com/graphql-subscriptions`;
        const graphQLApiUri = `https://preview${regionToUse}.twilio.com/Flex/graphql`;
        const tokenIsFunction = typeof tokenOrGetToken === "function";

        const links = [];
        if (options?.errorLink) {
            links.push(options.errorLink);
        }

        const authLink = setContext(async (_, { headers }) => {
            const authLinkToken = tokenIsFunction ? await (tokenOrGetToken as GetToken)() : tokenOrGetToken;
            const auth = btoa(`token:${authLinkToken}`);

            return {
                headers: {
                    ...headers,
                    authorization: auth ? `Basic ${auth}` : ""
                }
            };
        });
        links.push(authLink);

        const retryLink = new RetryLink({
            attempts: {
                max: 5
            },
            delay: {
                initial: 1000,
                max: 3000
            }
        });
        links.push(retryLink);

        const dataClientHttpLink = () => {
            return new ApolloLink((operation, forward) => {
                const { headers } = operation.getContext();
                const query = print(operation.query);
                const headerSize = JSON.stringify(headers).length;
                const variables = JSON.stringify(operation.variables);
                const fullUrlSize = `${graphQLApiUri}?query=${encodeURIComponent(query)}&variables=${encodeURIComponent(
                    variables
                )}`.length;

                // If the total header size is exceeding the limit, we switch to using POST requests
                // Otherwise, we use GET requests for queries for better caching
                const useGET = fullUrlSize + headerSize <= MAX_HEADER_SIZE;

                const httpLink = new HttpLink({ uri: graphQLApiUri, useGETForQueries: useGET });
                return httpLink.request(operation, forward);
            });
        };
        links.push(dataClientHttpLink()); // This is a terminating link. It should be the last one in the chain.

        const wsUri = graphQLSubscriptionUri;
        const wsLink = new GraphQLWsLink(
            createClient({
                url: wsUri,
                // Websocket polyfill when running FlexSDK outside of a browser instance
                ...(typeof WebSocket === "undefined" && { webSocketImpl: WebSocket }),
                connectionParams: async () => ({
                    authToken: `Bearer ${tokenIsFunction ? await (tokenOrGetToken as GetToken)() : tokenOrGetToken}`
                })
            })
        );

        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === "OperationDefinition" && definition.operation === "subscription";
            },
            wsLink,
            from(links)
        );

        if (options?.typePolicies) {
            cache.policies.addTypePolicies(options?.typePolicies);
        }

        const client = new ApolloClient({
            cache,
            connectToDevTools: options?.connectToDevTools ?? false,
            link: splitLink,
            defaultOptions: {
                query: {
                    fetchPolicy: "network-only", 
                    errorPolicy: "all" 
                }
            },
            typeDefs: options?.localSchema?.typeDefs,
            resolvers: options?.localSchema?.resolvers
        });
        return Promise.resolve(client);
    } catch (error) {
        throw new Error(DataClientError.GraphqlApolloClientInitializationFailed);
    }
};
