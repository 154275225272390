import { debuggerErrorCodes, ErrorCode, publicErrorDetails } from "./ErrorCodes";
import { InternalErrorCode, internalErrorDetails } from "./InternalErrorCodes";

const internalToPublicErrorMap: Map<InternalErrorCode, ErrorCode> = new Map([
    [InternalErrorCode.SyncConnectionError, ErrorCode.ConnectionError],
    [InternalErrorCode.SyncAccessForbiddenError, ErrorCode.Forbidden],
    [InternalErrorCode.SyncMapNotFoundError, ErrorCode.NotFound],
    [InternalErrorCode.SyncMapItemNotFoundError, ErrorCode.NotFound],
    [InternalErrorCode.SyncInvalidMapItemDataError, ErrorCode.InvalidParams],
    [InternalErrorCode.SyncInvalidMapItemKeyError, ErrorCode.InvalidParams],
    [InternalErrorCode.SyncNameNotFoundError, ErrorCode.NotFound],
    [InternalErrorCode.SyncNameAlreadyExistsError, ErrorCode.InvalidState],
    [InternalErrorCode.SyncInvalidNameError, ErrorCode.InvalidParams]
]);

function isPublicErrorCode(errorCode: ErrorCode | InternalErrorCode): boolean {
    const publicError = Object.values(ErrorCode).find((code: number) => code === errorCode);
    return !!publicError;
}

export function isInternalErrorCode(errorCode: ErrorCode | InternalErrorCode): boolean {
    const internalError = Object.values(InternalErrorCode).find((code: number) => code === errorCode);
    return !!internalError;
}

export function isKnownErrorCode(errorCode: ErrorCode | InternalErrorCode): boolean {
    return isPublicErrorCode(errorCode) || isInternalErrorCode(errorCode);
}

export function isDebuggerErrorCode(errorCode: ErrorCode): boolean {
    return debuggerErrorCodes.includes(errorCode);
}

function getPublicErrorCodeMessage(errorCode: ErrorCode | InternalErrorCode): string | undefined {
    return publicErrorDetails[errorCode as ErrorCode];
}

function getInternalErrorCodeMessage(errorCode: ErrorCode | InternalErrorCode): string | undefined {
    return internalErrorDetails[errorCode as InternalErrorCode];
}

export function getMessage(errorCode: ErrorCode | InternalErrorCode): string {
    return getPublicErrorCodeMessage(errorCode) || getInternalErrorCodeMessage(errorCode) || "Error";
}

export function getPublicErrorCode(internalErrorCode: InternalErrorCode): ErrorCode {
    const publicErrorCode = internalToPublicErrorMap.get(internalErrorCode);
    return publicErrorCode || ErrorCode.Unknown;
}

export function isDownstreamErrorCode(errorCode: ErrorCode | undefined): boolean {
    return !errorCode || errorCode === ErrorCode.NetworkError || errorCode === ErrorCode.Unknown;
}
