import { Task } from 'twilio-taskrouter';
import isEmpty from 'lodash/isEmpty';
import { MediaChannelType } from './types/MediaChannelType';
import { getRoute, INTERACTION_GET_CHANNEL_PARTICIPANTS } from '../util/Routes';
import { getInteractionSid } from '../util/FlexInteractionSid';
import CbmParticipant, { ParticipantData } from '../CbmParticipant';
import { get } from '../util/Request';

type GetParticipantsResponse = {
    interaction_sid: string;
    channel_sid: string;
    channel_type: string;
    participants: Array<ParticipantData>;
};








export default async function getParticipants(task: Task, channelSid: string): Promise<CbmParticipant[]> {
    if (!channelSid) {
        throw new TypeError(`Failed to get Participants in a channel in Task sid=${task.sid}. Missing a channel sid.`);
    }

    if (isEmpty(task.attributes)) {
        throw new TypeError(`Failed to get Participants in a channel in Task sid=${task.sid}. Missing attributes.`);
    }

    const interactionSid = getInteractionSid(task);
    const requestURL = getRoute(INTERACTION_GET_CHANNEL_PARTICIPANTS, interactionSid, channelSid).path;
    const response = await get<GetParticipantsResponse>(requestURL);
    if (response.participants) {
        return response.participants.map(
            (participantData) =>
                new CbmParticipant({
                    ...participantData,
                    participant_sid: participantData.sid,
                    channel_type: response.channel_type as MediaChannelType
                })
        );
    }

    throw new TypeError(
        `Failed to get Participants in a channel in Task sid=${task.sid}. ` +
            `Response does not contain participants object.`
    );
}
