





export class TwilsockReplyError extends Error {
    reply: {
        status: {
            code: number;
        };
    };
}

export function isTwilsockReplyError(error: Error): boolean {
    const hasReply = Object.prototype.hasOwnProperty.call(error, "reply");
    const hasStatus = hasReply && Object.prototype.hasOwnProperty.call((error as TwilsockReplyError).reply, "status");
    const hasCode =
        hasStatus && Object.prototype.hasOwnProperty.call((error as TwilsockReplyError).reply.status, "code");

    return hasReply && hasStatus && hasCode;
}
