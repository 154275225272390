import { ContextManager } from "~/modules/contextManager/ContextManager";
import { AuthenticatorDataContainer } from "./AuthenticatorDataContainer";

export class AuthenticatorDataContainerImpl implements AuthenticatorDataContainer {
    #accountSid: string;

    constructor(ctx?: ContextManager) {}

    get accountSid(): string {
        return this.#accountSid;
    }

    set accountSid(accountSid: string) {
        this.#accountSid = accountSid;
    }
}

let instance: AuthenticatorDataContainer;
export const getAuthenticatorDataContainerImplInstance: () => AuthenticatorDataContainer = () => {
    instance = instance || new AuthenticatorDataContainerImpl();
    return instance;
};
