import { Supervisor, WorkerOptions, Workspace, WorkspaceOptions } from "twilio-taskrouter";
import { LogLevelDesc } from "loglevel";
import { Logger, LoggerName, getLogger } from "~/modules/logger";
import type { TaskRouter } from "./TaskRouter";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import type { Session } from "~/modules/session/Session/Session";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { getEnvironmentConfig } from "~/modules/config";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";

type LogLevelString = "error" | "warn" | "info" | "debug" | "trace" | "silent";
export class TaskRouterImpl implements TaskRouter {
    readonly #logger: Logger;

    readonly #session: Session;

    readonly #clientOptions: ClientOptionsStore;

    #worker: Supervisor;

    #workspace: Workspace;

    constructor(ctx: ContextManager) {
        this.#session = ctx.getInstanceOf(SessionImpl);
        this.#clientOptions = ctx.getInstanceOf(ClientOptionsStore);
        this.#logger = getLogger(ctx)(LoggerName.TaskRouter);
    }

    async init(workerInstance?: Supervisor, workspaceInstance?: Workspace): Promise<void> {
        if (workerInstance) {
            this.#worker = workerInstance;
        }

        if (workspaceInstance) {
            this.#workspace = workspaceInstance;
        }

        if (this.#worker && this.#workspace) {
            return Promise.resolve();
        }

        const environmentConfig = getEnvironmentConfig();
        const { region, regionNonFlex } = environmentConfig || {};
        const configRegion = regionNonFlex || this.#clientOptions.regionNonFlex || region || this.#clientOptions.region;

        if (!this.#worker) {
            const workerOptions = this.#clientOptions?.taskRouterOptions?.workerOptions || {};

            workerOptions.logLevel =
                workerOptions?.logLevel || this.mapToLogLevelString(this.#clientOptions?.logger?.level);

            if (configRegion !== undefined) {
                workerOptions.region = configRegion;
            }

            this.#worker = new Supervisor(this.#session.token, workerOptions);
        }

        let workspaceOptions = this.#clientOptions?.taskRouterOptions?.workspaceOptions;
        if (configRegion !== undefined) {
            workspaceOptions = {};
            workspaceOptions.region = configRegion;
        }

        if (!this.#workspace && workerInstance) {
            this.#workspace = new Workspace(this.#session.token, workspaceOptions, this.#worker.workspaceSid);
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            this.#worker.on("ready", () => {
                this.#workspace = new Workspace(this.#session.token, workspaceOptions, this.#worker.workspaceSid);
                resolve();
            });
        });
    }

    updateToken(token: string): void {
        this.#logger.debug("updateToken called");

        if (this.#worker) {
            try {
                this.worker.updateToken(token);
            } catch (error) {
                this.#logger.error("Failed to update token on worker", error);
            }
        }

        if (this.#workspace) {
            try {
                this.workspace.updateToken(token);
            } catch (error) {
                this.#logger.error("Failed to update token on workspace", error);
            }
        }

        this.#logger.debug("updateToken completed");
    }

    async createTask(to: string, from: string, workflowSid: string, taskQueueSid: string, options = {}) {
        

        
        return this.#worker.createTask(to, from, workflowSid, taskQueueSid, options);
    }

    get worker(): Supervisor {
        return this.#worker;
    }

    get workspace(): Workspace {
        return this.#workspace;
    }

    private mapToLogLevelString(logLevel: LogLevelDesc): LogLevelString {
        const logLevelMapping: Record<string, number> = {
            trace: 0,
            debug: 1,
            info: 2,
            warn: 3,
            error: 4,
            silent: 5
        };

        if (typeof logLevel === "number") {
            switch (logLevel) {
                case 0:
                    return "trace";
                case 1:
                    return "debug";
                case 2:
                    return "info";
                case 3:
                    return "warn";
                case 4:
                    return "error";
                case 5:
                    return "silent";
                default:
                    return "warn";
            }
        } else if (typeof logLevel === "string") {
            const lowercaseLogLevel = logLevel.toLowerCase();
            if (lowercaseLogLevel in logLevelMapping) {
                return lowercaseLogLevel as LogLevelString;
            }
        }
        return "warn";
    }
}





export type { WorkerOptions };





export type { WorkspaceOptions };
