import { Subscription } from "rxjs";
import { ContextManager } from "../contextManager/ContextManager";
import { reportError } from "../error/ThrowError/reportError";
import ErrorReporter from "./ErrorReporter";

const subscriptions: Array<Subscription> = [];

export const initErrorSubscriptions = (ctx: ContextManager) => {
    subscriptions.push(
        ctx.getInstanceOf(ErrorReporter).telemetryErrorStream.subscribe({
            next: (err) => reportError(ctx)(err)
        })
    );
};

export const clearErrorSubscriptions = (): void => {
    subscriptions.forEach((sub) => sub.unsubscribe());
    subscriptions.splice(0);
};

export const getSubscriptions = (): Array<Subscription> => subscriptions.concat();
