import { Task } from 'twilio-taskrouter';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { post } from '../util/Request';
import { getRoute, INTERACTION_MODIFY_PARTICIPANT } from '../util/Routes';
import getChannels from './GetChannels';
import { ParticipantResponse } from './types/AddParticipant';









export default async function setEndConferenceOnExit(
    task: Task,
    participantSid: string,
    endConferenceOnExit: boolean
): Promise<ParticipantResponse> {
    if (!participantSid) {
        throw new TypeError(
            `Error calling method setEndConferenceOnExit(). <string>participantSid is a required parameter.`
        );
    }

    if (typeof task.attributes === 'undefined') {
        throw new Error("Task's attributes are not set");
    }

    const channels = await getChannels(task);
    const channelSid = channels.find((channel) => channel.type === 'voice')!.sid;
    const interactionSid = getInteractionSid(task);

    const requestURL = getRoute(INTERACTION_MODIFY_PARTICIPANT, interactionSid, channelSid, participantSid).path;
    const requestParams = {
        media_properties: {
            
            end_conference_on_exit: endConferenceOnExit
        }
    };

    return post<ParticipantResponse>(requestURL, requestParams);
}
