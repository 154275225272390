


export enum LoggerName {
    Client = "flex:Client",
    Error = "flex:Error",
    Retry = "flex:Retry",
    Session = "flex:Session",
    Twilsock = "flex:Twilsock",
    Auth = "flex:Auth",
    Config = "flex:Config",
    Sync = "flex:Sync",
    SyncAdapter = "flex:SyncAdapter",
    Storage = "flex:Storage",
    ProfileConnector = "flex:ProfileConnector",
    VirtualAgentData = "flex:VirtualAgentData",
    Actions = "flex:Actions",
    TaskRouter = "flex:TaskRouter",
    CBM = "flex:CBM",
    Voice = "flex:Voice",
    Call = "flex:Call",
    DataClient = "flex:DataClient",
    Analytics = "flex:Analytics"
    
}




export enum SdkLoggerName {
    Twilsock = "twilsock"
}




export enum TelemetryLoggerName {
    TelemetryClient = "TelemetryClient",
    TelemetryProcessor = "TelemetryProcessor",
    TelemetrySdkClient = "TelemetrySdkClient"
}




export type InternalLoggerName = LoggerName | SdkLoggerName | TelemetryLoggerName;
