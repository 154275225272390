import { PausedEmailConversationsResponse } from './types/PauseEmailConversation';

import getPausedChannels from './GetPausedChannels';








export default async function getPausedEmailConversations(
    taskStatus: string,
    pageSize: number
): Promise<PausedEmailConversationsResponse> {
    if (!taskStatus) {
        throw new TypeError(
            'Error calling method getPausedEmailConversations(). <string>status is a required parameter.'
        );
    }

    return getPausedChannels(pageSize) as Promise<PausedEmailConversationsResponse>;
}
