import { LogLevelNumbers, MethodFactory } from "loglevel";
import { LogEntry, Severity } from "~/modules/logger/Logger/LogEntry";

export const createLogEntry =
    (originalFactory: MethodFactory) => (methodName: string, logLevel: LogLevelNumbers, loggerName: string) => {
        return (firstParam: LogEntry | unknown, ...messages: unknown[]): void => {
            const isLogEntry = firstParam instanceof LogEntry;
            if (!isLogEntry) {
                messages.unshift(firstParam);
            }

            const logEntry = isLogEntry ? (firstParam as LogEntry) : new LogEntry(loggerName, methodName as Severity);
            const method = originalFactory(methodName, logLevel, loggerName);
            return method(logEntry, ...messages);
        };
    };
