import React from "react";
import ReactDOM from "react-dom";
import "regenerator-runtime/runtime";
import * as Flex from "@twilio/flex-ui";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import Logout from "./Logout";

const mountNode = document.getElementById("root");
document.title = "RX Copilot";
window.onload = () => {
  /*** Loader */
  const outloader = document.createElement("div");
  outloader.className = "out-loader"; 
  const loader = document.createElement("div");
  loader.className = "loader";
  outloader.appendChild(loader);
  root.appendChild(outloader);

  const predefinedConfig = window.appConfig || {};
  const checkPopupBlocked = () => {
    const testPopup = window.open("", "_blank", "width=1,height=1");
    if (testPopup === null) {
      return false;
    } else {
      testPopup.close();
      return true;
    }
  };
  if (window.opener && !window.opener.closed) {
    
    console.log("This URL is opened in a subwindow (popup).");
    // Handle subwindow-specific logic here
  } else if (window.self !== window.top) {
    console.log("This URL is opened in an iframe.");
    if (!checkPopupBlocked()) {
      const jsonData = {
        app: "Rxcopilot",
        action: "flex",
      };
      window.parent.postMessage(
        jsonData,
        "https://sfwyqa.mhealthcoach.net/"
      );
      alert("Please enable pop-ups to use Voice Call");
  
      return;
    }
  } 
  
if(!window.location.href.includes("logout")){
  const configuration = {
    ...predefinedConfig,
  };

  Flex
    .progress(mountNode)
    .provideLoginInfo(configuration, mountNode)
    .then(() => Flex.Manager.create(configuration))
    .then(manager => renderApp(manager))
    .catch(error => handleError(error));
  } else {
    ReactDOM.render(
      <Logout />,
      mountNode
    );
  };

  function renderApp(manager) {
    ReactDOM.render(
      <App manager={manager} />,
      mountNode
    );
  }
    
}

function handleError(error) {
  Flex.errorPage(error, mountNode);

/*   ReactDOM.render(
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Something went wrong!</h1>
      <p>Please try refreshing the page or contact support if the issue persists.</p>
    </div>,
    mountNode
  ); */
  console.error("Failed to initialize Flex", error);
}

registerServiceWorker();
