import { FeaturesConfig } from "~/backend/generated/Features/model/featuresConfig";
import { FeaturesList } from "~/backend/generated/Features/model/featuresList";
import { Client, ClientOptions } from "~/modules/client";
import { mergeUserOptions } from "~/utils/mergeUserOptions";
import { assertNotEmptyString } from "~/utils/assert";
import {
    TelemetrySdkEvent,
    TelemetrySdkEventGroup,
    TelemetrySdkEventName,
    TelemetrySdkEventSource,
    TelemetrySdkClient,
    SDKDependency
} from "~/modules/telemetrySdkClient";

import { getLogger, Logger, LoggerConfigImpl, LoggerName } from "~/modules/logger";
import { ErrorCode, ErrorSeverity } from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { DeepPartial } from "~/utils/DeepPartial";
import { ClientOptionsStore } from "../ClientOptions/ClientOptionsStore";
import { throwFlexSdkError, throwFlexSdkErrorFromErrorResponse } from "~/modules/error/ThrowError/ErrorHelper";
import { getTelemetrySdkClient } from "~/modules/telemetrySdkClient/telemetrySdkClient";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import { ClientImpl } from "../Client/ClientImpl/ClientImpl";
import { initErrorSubscriptions } from "~/modules/reporter/Subscriber";
import { TaskRouterImpl } from "~/packages/taskrouter/TaskRouterImpl";
import { CbmImpl } from "~/modules/cbm";
import { AnalyticsImpl } from "~/modules/analytics/AnalyticsImpl";
import { DataClientImpl } from "~/modules/dataClient/DataClientImpl";
import { featuresProviderImpl } from "~/modules/config/FeaturesProvider/featuresProviderImpl/featuresProviderImpl";
import { isFeatureFlagEnabled } from "~/modules/actions/ActionUtils";

const getFeatureNameById = (id: string, featuresConfig: FeaturesConfig) => {
    const features: FeaturesList[] = featuresConfig?.features;
    const feature: FeaturesList | undefined = features?.find((item) => item.id === id);
    return feature?.name;
};

const sendClientInitEvent = async (logger: Logger, telemetrySdkClient: TelemetrySdkClient, durationInMs: number) => {
    try {
        const group = telemetrySdkClient.createEventGroup<TelemetrySdkEvent>(TelemetrySdkEventGroup.Default);
        await group.addEvents({
            eventName: TelemetrySdkEventName.ClientInitialized,
            eventSource: TelemetrySdkEventSource.Client,
            durationMs: durationInMs
        });
    } catch (e) {
        logger.error("Failed to send client init event", e);
    }
};

let instanceCreated: boolean;

export function resetClientCreated() {
    instanceCreated = false;
}

export const createClient =
    (ctx?: ContextManager) =>
    async (token: string, userOptions?: DeepPartial<ClientOptions>): Promise<Client> => {
        assertNotEmptyString(token, "token");

        let ctxInUse = ContextManager.newInstance();
        if (!instanceCreated) {
            ctxInUse = ctx || ctxInUse;
            instanceCreated = true;
        }

        initErrorSubscriptions(ctxInUse);

        const t0Ms = Date.now();
        const clientOptions = ctxInUse.getInstanceOf(ClientOptionsStore);
        const loggerConfig = ctxInUse.getInstanceOf(LoggerConfigImpl);
        const logger = getLogger(ctxInUse)(LoggerName.Client);
        const throwError = throwFlexSdkError(ctxInUse);
        const throwErrorFromErrorResponse = throwFlexSdkErrorFromErrorResponse(ctxInUse);

        if (userOptions?.logger?.level) {
            loggerConfig.level = userOptions?.logger?.level;
        }

        mergeUserOptions(clientOptions, userOptions);

        const session = ctxInUse.getInstanceOf(SessionImpl);

        if (session) {
            try {
                await session.init(token);
            } catch (err) {
                const metadata = {
                    module: extractModuleFromPath(__dirname),
                    severity: ErrorSeverity.Error,
                    source: extractFileNameFromPath(__filename)
                };
                
                
                if (err.code === 51102 || err.code === 20003) {
                    metadata.source = "Twilsock";
                    
                    throwError(ErrorCode.Forbidden, metadata, "Insufficient permissions", err.source);
                } else {
                    throwErrorFromErrorResponse(err, metadata);
                }
            }
        }

        try {
            const telemetrySessionData = {
                dependencies: {
                    [SDKDependency.Twilsock]: "default"
                }
            };

            const telemetrySdkClient = getTelemetrySdkClient(ctxInUse);
            await telemetrySdkClient.setSessionData(telemetrySessionData);

            const durationMs = Date.now() - t0Ms;
            
            await sendClientInitEvent(logger, telemetrySdkClient, durationMs);
        } catch (e) {
            logger.error("Failed to set session data for telemetry", e);
        }

        const taskRouter = ctxInUse.getInstanceOf(TaskRouterImpl);

        try {
            await taskRouter.init(clientOptions?.worker, clientOptions?.workspace);
        } catch (e) {
            logger.error("Failed to initialise TaskRouter", e);
        }

        const cbmSdk = ctxInUse.getInstanceOf(CbmImpl);
        try {
            await cbmSdk.init();
        } catch (e) {
            logger.error("Failed to initialise CBM SDK", e);
        }

        const analytics = ctxInUse.getInstanceOf(AnalyticsImpl);
        const config = await featuresProviderImpl(ctxInUse)(token);

        const productAnalyticsDisabled = isFeatureFlagEnabled("disable-flex-sdk-telemetry-beta", config);
        const analyticsKey = getFeatureNameById("flex-telemetry-key", config);
        if (productAnalyticsDisabled) {
            logger.info("Product analytics is disabled");
        } else if (!analyticsKey) {
            logger.info("Analytics Key for Telemetry not found in features config");
        } else {
            try {
                analytics.init(analyticsKey);
            } catch (e) {
                logger.warn("Failed to initialise Analytics", e);
            }
        }

        if (!clientOptions.disableDataClient) {
            const dataClient = ctxInUse.getInstanceOf(DataClientImpl);
            try {
                await dataClient.init();
            } catch (e) {
                logger.error("Failed to initialize DataClient", e);
            }
        }

        return ctxInUse.getInstanceOf(ClientImpl);
    };
