import { createTelemetryClient } from "~/modules/telemetry/TelemetryClientFactory/createTelemetryClient";
import { TelemetryErrorEvent } from "~/modules/telemetry/TelemetryEventGroup/TelemetryErrorEvent";
import { TelemetrySDKSessionData } from "~/modules/telemetrySdkClient/TelemetrySdkClient/TelemetrySDKSessionData";
import { ContextManager } from "../contextManager/ContextManager";

export const getTelemetrySdkClient = (ctx: ContextManager) => {
    return createTelemetryClient<TelemetrySDKSessionData>(ctx)("sdk-v1");
};

export const getTelemetrySdkErrorEventGroup = (ctx: ContextManager) => {
    const errorClient = getTelemetrySdkClient(ctx);
    const group = errorClient.createEventGroup<TelemetryErrorEvent>();
    return group;
};
