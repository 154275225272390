import { ErrorCode } from "~/modules/error/ErrorCode/ErrorCodes";
import { getPublicErrorCode, isInternalErrorCode, isKnownErrorCode } from "~/modules/error/ErrorCode/ErrorCodeHelper";
import { FlexSdkError } from "~/modules/error/FlexSdkError/FlexSdkError";

import { InternalErrorCode } from "~/modules/error/ErrorCode//InternalErrorCodes";
import { FlexSdkErrorMetadata } from "~/modules/error/FlexSdkErrorMetadata/FlexSdkErrorMetadata";
import ErrorReporter from "~/modules/reporter/ErrorReporter";
import { ContextManager } from "~/modules/contextManager/ContextManager";

export interface ErrorResponse {
    code?: number;
    message?: string;
}

export function createFlexSdkError(ctx: ContextManager) {
    return (errorCode: number, metadata?: FlexSdkErrorMetadata, message?: string, error?: Error): FlexSdkError => {
        
        if (error instanceof FlexSdkError) {
            return error;
        }

        if (!errorCode || !isKnownErrorCode(errorCode)) {
            console.warn("Unknown error code"); 
        }

        let publicErrorCode = errorCode;
        if (isInternalErrorCode(errorCode)) {
            publicErrorCode = getPublicErrorCode(errorCode as InternalErrorCode);
        }

        const err = new FlexSdkError(publicErrorCode, metadata, message, error);

        ctx.getInstanceOf(ErrorReporter).publishTelemetryError(err);
        return err;
    };
}



export function throwFlexSdkError(ctx: ContextManager) {
    return (errorCode: number, metadata?: FlexSdkErrorMetadata, message?: string, error?: Error): never => {
        throw createFlexSdkError(ctx)(errorCode, metadata, message, error);
    };
}

export function reThrowFlexSdkErrorWithCustomMessage(error: FlexSdkError, customMessage: string): never {
    throw new FlexSdkError(error.code, error.metadata, customMessage, error.wrappedError);
}

export function throwFlexSdkErrorFromErrorResponse(ctx: ContextManager) {
    return (errorResponse: ErrorResponse, metadata?: FlexSdkErrorMetadata): void => {
        if (errorResponse instanceof FlexSdkError) {
            throw errorResponse;
        }
        const errorCode = errorResponse.code ? errorResponse.code : ErrorCode.Unknown;
        throwFlexSdkError(ctx)(errorCode, metadata, errorResponse.message);
    };
}

export function throwFlexSdkErrorFromResponse(ctx: ContextManager) {
    return async (response: Response, metadata?: FlexSdkErrorMetadata, message?: string): Promise<void> => {
        const errorResponse: ErrorResponse = await response.json();
        errorResponse.message = message || errorResponse.message;
        throwFlexSdkErrorFromErrorResponse(ctx)(errorResponse, metadata);
    };
}
