import { ErrorCode, FlexSdkError } from "~/modules/error";

export enum AuthenticationMethod {
    HostedFlex = "hosted-flex",
    Basic = "basic"
}

export const makeAuthenticationHeaders = (
    method: AuthenticationMethod,
    token: string,
    requestContentType: string = "application/json"
) => {
    if (method === AuthenticationMethod.Basic) {
        return new Headers({
            Authorization: `Basic ${btoa(`token:${token}`)})`,
            "Content-Type": requestContentType
        });
    }

    if (method === AuthenticationMethod.HostedFlex) {
        return new Headers({
            "x-flex-jwe": token,
            "Content-Type": requestContentType
        });
    }

    throw new FlexSdkError(ErrorCode.InvalidParams);
};
