import { getRoute, CREATE_INTERACTION_CHANNEL } from '../util/Routes';
import { post } from '../util/Request';
import {
    CreateChannelParticipantParams,
    CreateInteractionChannelParams,
    CreateInteractionChannelResponse,
    InteractionChannel
} from './types/CreateInteractionChannel';
import { MediaChannelType } from './types/MediaChannelType';











export default async function createInteractionChannel(
    interactionSid: string,
    channelMediaType: MediaChannelType,
    initiatedBy: string,
    participants?: [CreateChannelParticipantParams]
): Promise<InteractionChannel> {
    const requestURL = getRoute(CREATE_INTERACTION_CHANNEL, interactionSid).path;

    const requestParams: CreateInteractionChannelParams = {
        interactionSid,
        type: channelMediaType,
        initiated_by: initiatedBy,
        properties: {
            type: channelMediaType
        }
    };

    const requestParamsWithParticipants = {
        ...requestParams,
        participants
    };

    const response = await post<CreateInteractionChannelResponse>(requestURL, requestParamsWithParticipants);

    return {
        sid: response.sid,
        interactionSid: response.interaction_sid,
        type: response.type,
        status: response.status,
        mediaSid: response.media_sid,
        mediaProperties: response.media_properties
    };
}
