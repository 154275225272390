import { Task } from 'twilio-taskrouter';
import CbmParticipant, { ParticipantEventData } from '../CbmParticipant';
import TwilioError from '../util/TwilioError';
import { getRoute, INTERACTION_MODIFY_PARTICIPANT } from '../util/Routes';
import { post } from '../util/Request';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { getParticipantSidFromEvent } from '../util/ParticipantSid';
import getChannels from './GetChannels';
import { ParticipantResponse } from './types/AddParticipant';
import { promiseOrTimeout } from '../util/EventsTimeout';








export default async function removeVoiceParticipant(task: Task, sid: string): Promise<CbmParticipant> {
    let pendingParticipant: ParticipantResponse | undefined;

    const eventReceived = new Promise((resolve: (value: CbmParticipant) => void, reject) => {
        
        const participantRemovedHandler = (_: any, event: ParticipantEventData) => {
            const eventParticipantSid = getParticipantSidFromEvent(event);
            if (eventParticipantSid === pendingParticipant?.sid) {
                task.removeListener('participantRemoved', participantRemovedHandler);
                resolve(new CbmParticipant(event));
            }
        };
        task.on('participantRemoved', participantRemovedHandler);

        
        const participantRemoveFailedHandler = (_: any, event: any) => {
            const eventParticipantSid = getParticipantSidFromEvent(event);
            if (eventParticipantSid === pendingParticipant?.sid) {
                task.removeListener('participantRemoveFailed', participantRemoveFailedHandler);
                reject(
                    new TwilioError(
                        event,
                        `Failed to remove Participant from Task sid=${task.sid}. Reason: ${event.reason}`
                    )
                );
            }
        };
        task.on('participantRemoveFailed', participantRemoveFailedHandler);
    });

    const channels = await getChannels(task);
    const channelSid = channels.find((channel) => channel.type === 'voice')!.sid;
    const interactionSid = getInteractionSid(task);
    const requestURL = getRoute(INTERACTION_MODIFY_PARTICIPANT, interactionSid, channelSid, sid).path;
    const requestParams = { status: 'closed' };
    pendingParticipant = await post<ParticipantResponse>(requestURL, requestParams);

    return promiseOrTimeout<CbmParticipant>(eventReceived, 'Task.participantRemoved');
}
