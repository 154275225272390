



export enum MediaChannelType {
    Email = 'email',
    Sms = 'sms',
    WhatsApp = 'whatsapp',
    Web = 'web',
    Voice = 'voice',
    Messenger = 'messenger',
    Chat = 'chat',
    Gbm = 'gbm',
    Video = 'video'
}
