import { Authenticator } from "~/modules/auth";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { AuthenticatorImpl } from "../../Authenticator/AuthenticatorImpl";
import { AuthenticatorDataContainerImpl } from "../../AuthenticatorDataContainer/AuthenticatorDataContainerImpl";

export const createAuthenticator =
    (ctx: ContextManager) =>
    (accountSid: string): Authenticator => {
        const authenticatorDataContainer = ctx.getInstanceOf(AuthenticatorDataContainerImpl);
        authenticatorDataContainer.accountSid = accountSid;

        const auth = new AuthenticatorImpl(ctx);
        return auth;
    };
