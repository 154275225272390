
import { BodyInit } from "node-fetch";
import {
    ErrorCode,
    ErrorSeverity,
    InternalError,
    ThrowErrorFromResponseFunction,
    ThrowErrorFunction
} from "~/modules/error";
import { extractFileNameFromPath } from "~/utils/extractFromPath";
import { retry as performApiCallWithRetry } from "~/utils/retry/retry";
import { retryableErrorMap, retryableStatusCodes } from "~/utils/retry/retryUtil";
import { defaultRetryConditionOnFlexSdkError } from "~/utils/defaultRetryConditionOnFlexSdkError";
import { AuthenticationMethod, makeAuthenticationHeaders } from "../commons/authenticationMethods";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { buildRegionalHost, buildRegionalHostWithEdge } from "~/utils/regionUtil";
import { throwFlexSdkError, throwFlexSdkErrorFromResponse } from "~/modules/error/ThrowError/ErrorHelper";
import { getEnvironmentConfig } from "~/modules/config/EnvironmentConfig/EnvironmentConfigImpl";
import { TokenRegistry } from "~/backend/TokenRegistry";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { HttpStatusCode } from "~/utils/HttpStatusCode";
import { getLogger, Logger, LoggerName } from "~/modules/logger";

export class SimpleHttpAdapterImpl implements HttpAdapter {
    readonly #session: TokenRegistry;

    readonly #throwError: ThrowErrorFunction;

    readonly #throwErrorFromResponse: ThrowErrorFromResponseFunction;

    readonly #retryLogger: Logger;

    #isEdgeSupported: Boolean;

    constructor(ctx: ContextManager) {
        this.#session = ctx.getInstanceOf(TokenRegistry);
        this.#retryLogger = getLogger(ctx)(LoggerName.Retry);
        this.#throwError = throwFlexSdkError(ctx);
        this.#throwErrorFromResponse = throwFlexSdkErrorFromResponse(ctx);
    }

    public get<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        options?: { [key: string]: unknown; headers?: object }
    ): Promise<T> {
        return performApiCallWithRetry({
            functionToRetry: () => this.#performNetworkCallOnce<T>(url, "GET", authMethod, undefined, options),
            retryCondition: defaultRetryConditionOnFlexSdkError,
            logger: this.#retryLogger
        });
    }

    public post<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        body?: BodyInit,
        options?: { [key: string]: unknown; headers?: object },
        requestContentType?: string
    ): Promise<T> {
        return performApiCallWithRetry({
            functionToRetry: () =>
                this.#performNetworkCallOnce<T>(url, "POST", authMethod, body, options, requestContentType),
            retryCondition: defaultRetryConditionOnFlexSdkError,
            logger: this.#retryLogger
        });
    }

    public put<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        body?: BodyInit,
        requestContentType?: string
    ): Promise<T> {
        return performApiCallWithRetry({
            functionToRetry: () =>
                this.#performNetworkCallOnce<T>(url, "PUT", authMethod, body, undefined, requestContentType),
            retryCondition: defaultRetryConditionOnFlexSdkError,
            logger: this.#retryLogger
        });
    }

    public delete<T>(url: string, authMethod?: AuthenticationMethod): Promise<T> {
        return performApiCallWithRetry({
            functionToRetry: () => this.#performNetworkCallOnce<T>(url, "DELETE", authMethod),
            retryCondition: defaultRetryConditionOnFlexSdkError,
            logger: this.#retryLogger
        });
    }

    public setIsEdgeSupported(isEdgeSupported: Boolean = false): void {
        this.#isEdgeSupported = isEdgeSupported;
    }

    #getEnvironmentSpecificUrl(url: string): string {
        const config = getEnvironmentConfig();

        const region = config.region || "";
        const edge = config.edge || "";
        const regionNonFlex = config.regionNonFlex || region;
        const isFlexApi = /^https?:\/\/flex\[region\]\./.test(url);
        const regionToUse = isFlexApi ? region : regionNonFlex;

        return url.replace(
            "[region]",
            this.#isEdgeSupported ? buildRegionalHostWithEdge(regionToUse, edge) : buildRegionalHost(regionToUse)
        );
    }

    #getToken = (token?: unknown) => {
        if (!token) {
            return this.#session.sessionToken;
        }

        if (typeof token === "string") {
            return token;
        }

        throw new InternalError("No token in request body");
    };

    #mapStatusCodeToFlexSdkErrorCode = (statusCode: number): [ErrorCode | undefined, ErrorCode | undefined] => {
        let errorCode;
        let translatedErrorCode;

        if (retryableStatusCodes.includes(statusCode)) {
            translatedErrorCode = retryableErrorMap[statusCode as HttpStatusCode];
        }
        if (statusCode === HttpStatusCode.TooManyRequests) {
            errorCode = ErrorCode.TooManyRequests;
        } else if (statusCode >= HttpStatusCode.InternalServerError) {
            errorCode = ErrorCode.Unknown;
        }
        return [errorCode, translatedErrorCode];
    };

    #performNetworkCallOnce = async <T>(
        url: string,
        method: string,
        authMethod?: AuthenticationMethod,
        body?: BodyInit,
        options?: { [key: string]: unknown; headers?: object },
        requestContentType?: string
    ): Promise<T> => {
        const environmentSpecificUrl = this.#getEnvironmentSpecificUrl(url);
        let response: Response | null = null;

        try {
            let headers = new Headers({
                "Content-Type": requestContentType || "application/json"
            });

            if (authMethod) {
                headers = makeAuthenticationHeaders(authMethod, this.#getToken(options?.token), requestContentType);
            }

            const optionHeader: object = options?.headers || {};
            Object.entries(optionHeader).forEach(([key, value]) => {
                headers.append(key, value);
            });

            response = await fetch(environmentSpecificUrl, {
                headers,
                method,
                body
            });
        } catch (e) {
            this.#throwError(
                ErrorCode.NetworkError,
                { severity: ErrorSeverity.Error, translatedErrorCode: ErrorCode.NetworkError },
                undefined,
                e
            );
        }

        if (!response?.ok) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            const [flexSdkErrorCode, translatedErrorCode] = this.#mapStatusCodeToFlexSdkErrorCode(
                response?.status as number
            );

            if (flexSdkErrorCode) {
                this.#throwError(flexSdkErrorCode, {
                    ...metadata,
                    ...(translatedErrorCode && { translatedErrorCode })
                });
            }
            await this.#throwErrorFromResponse(response as Response, metadata);
        }
        const result = await response?.json();

        return result as Promise<T>;
    };
}
