import { Role } from "~/modules/auth";

const permissionsMap = new Map([
    ["/twilio/flex/agent-desktop-page/read", [Role.Agent, Role.Supervisor, Role.Admin]],
    ["/twilio/flex/agent-self-status/read", [Role.Agent, Role.Supervisor, Role.Admin]],
    ["/twilio/flex/agent-self-status/update", [Role.Agent, Role.Supervisor, Role.Admin]],
    ["/twilio/flex/agent-task-reservation/do", [Role.Agent, Role.Supervisor, Role.Admin]],
    [
        "/twilio/flex/queue-stats-page/read",
        [Role.FlexReadqueuestats, Role.Supervisor, Role.Admin, Role.FlexLead, Role.FlexReadonlyadmin]
    ],
    [
        "/twilio/flex/teams-page/read",
        [
            Role.FlexReadteams,
            Role.Supervisor,
            Role.Admin,
            Role.FlexLead,
            Role.FlexQualityanalyst,
            Role.FlexReadonlyadmin
        ]
    ],
    [
        "/twilio/flex/agent-skills/read",
        [
            Role.FlexReadteams,
            Role.Supervisor,
            Role.Admin,
            Role.FlexLead,
            Role.FlexQualityanalyst,
            Role.FlexReadonlyadmin
        ]
    ],
    [
        "/twilio/flex/agent-status/read",
        [
            Role.FlexReadteams,
            Role.Supervisor,
            Role.Admin,
            Role.FlexLead,
            Role.FlexQualityanalyst,
            Role.FlexReadonlyadmin
        ]
    ],
    [
        "/twilio/flex/agent-capacity/read",
        [
            Role.FlexReadteams,
            Role.Supervisor,
            Role.Admin,
            Role.FlexLead,
            Role.FlexQualityanalyst,
            Role.FlexReadonlyadmin
        ]
    ],
    [
        "/twilio/flex/agent-task-info/read",
        [
            Role.FlexReadteams,
            Role.Supervisor,
            Role.Admin,
            Role.FlexLead,
            Role.FlexQualityanalyst,
            Role.FlexReadonlyadmin
        ]
    ],
    [
        "/twilio/flex/agent-call-monitoring/do",
        [Role.FlexMonitorchatcall, Role.Supervisor, Role.Admin, Role.FlexQualityanalyst]
    ],
    [
        "/twilio/flex/agent-chat-monitoring/do",
        [Role.FlexMonitorchatcall, Role.Supervisor, Role.Admin, Role.FlexQualityanalyst]
    ],
    ["/twilio/flex/agent-skills/update", [Role.FlexEditskills, Role.Supervisor, Role.Admin, Role.FlexLead]],
    ["/twilio/flex/agent-skills/delete", [Role.FlexEditskills, Role.Supervisor, Role.Admin, Role.FlexLead]],
    ["/twilio/flex/agent-capacity/update", [Role.FlexEditskills, Role.Supervisor, Role.Admin, Role.FlexLead]],
    ["/twilio/flex/agent-status/update", [Role.FlexEditstatus, Role.Supervisor, Role.Admin]],
    ["/twilio/flex/admin-page/read", [Role.FlexReadadmin, Role.Admin, Role.FlexReadonlyadmin]],
    ["/twilio/flex/admin-page/update", [Role.Admin]]
]);




export const hasPermission = (permissionToCheck: string, tokenRoles: Array<Role>): boolean => {
    const correspondingRole: Array<Role> | undefined = permissionsMap.get(permissionToCheck);
    const intersection: Array<Role> = tokenRoles.filter((role) => correspondingRole?.includes(role));
    return intersection.length >= 1;
};
