import { TRANSFER_CHANNEL, getRoute } from '../util/Routes';
import { post } from '../util/Request';
import { TransferChannelResponse } from './types/Transfers';

function throwTypeError(paramName: string) {
    throw new TypeError(`Error calling method transferChannel(). <string>${paramName} is a required parameter.`);
}

function verifyRequiredParameters(
    instanceSId: string,
    interactionSid: string,
    channelSid: string,
    from: string,
    to: string
) {
    if (!instanceSId) {
        throwTypeError('instanceSId');
    }
    if (!interactionSid) {
        throwTypeError('interactionSid');
    }
    if (!channelSid) {
        throwTypeError('channelSid');
    }
    if (!from) {
        throwTypeError('from');
    }
    if (!to) {
        throwTypeError('to');
    }
}











export async function transferChannel(
    instanceSid: string,
    interactionSid: string,
    channelSid: string,
    from: string,
    to: string,
    noteSid?: string,
    type: 'warm' | 'cold' = 'cold'
): Promise<TransferChannelResponse> {
    verifyRequiredParameters(instanceSid, interactionSid, channelSid, from, to);
    const requestURL = getRoute(TRANSFER_CHANNEL, instanceSid, interactionSid, channelSid).path;

    return post<TransferChannelResponse>(requestURL, { noteSid, type, from, to });
}
