import { hasKey } from "./typeGuards";

export function convertDateStringValuesToDate<T extends object>(response: object): T {
    const dateRegex = /^\d{4}-\d{1,2}-\d{1,2}/;
    const dateKeyRegex = new RegExp("date|expiration", "i");
    const processedResponse: Record<string, unknown | Date> = {};
    Object.entries(response).forEach(([key, value]) => {
        if (hasKey(response, key) && dateKeyRegex.test(key) && dateRegex.test(value)) {
            processedResponse[key] = new Date(value);
        } else {
            processedResponse[key] = value;
        }
    });
    return processedResponse as T;
}
