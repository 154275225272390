import { Call, Device } from "@twilio/voice-sdk";
import type { IExtendedDeviceOptions } from "@twilio/voice-sdk/es5/twilio/device";
import packageInfo from "package.json";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import type { Session } from "~/modules/session/Session/Session";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { getEnvironmentConfig } from "~/modules/config";
import { VoiceController } from "~/modules/voice/VoiceController";
import type { Client } from "~/modules/client";
import { ClientEvent } from "~/modules/client";
import { VoiceOptions } from "~/modules/voice/voiceOptions";

export class VoiceControllerImpl implements VoiceController {
    readonly #session: Session;

    readonly #clientOptions: ClientOptionsStore;

    readonly #logger: Logger;

    readonly #ctx: ContextManager;

    #voiceOptions?: VoiceOptions;

    #voiceDevice: Device;

    #isInitialized: boolean;

    constructor(ctx: ContextManager) {
        this.#session = ctx.getInstanceOf(SessionImpl);
        this.#clientOptions = ctx.getInstanceOf(ClientOptionsStore);
        this.#logger = getLogger(ctx)(LoggerName.Voice);
        this.#ctx = ctx;
        this.#logger.debug("VoiceController constructed");
    }

    async init(voiceDevice?: Device): Promise<Device> {
        const { ClientImpl } = await import("~/modules/client/Client/ClientImpl/ClientImpl");
        const client: Client = this.#ctx.getInstanceOf(ClientImpl);
        this.#voiceOptions = this.#clientOptions.voiceOptions;
        client.addListener(ClientEvent.TokenUpdated, this.#onNewToken);
        client.addListener(ClientEvent.ClientDestroyed, this.#onClientDestroyed);

        if (voiceDevice) {
            this.#voiceDevice = voiceDevice;
            this.#setUpEventHandlers();
            this.#isInitialized = true;
            return Promise.resolve(this.#voiceDevice);
        }

        const environmentConfig = getEnvironmentConfig();
        const { region, regionNonFlex } = environmentConfig || {};
        const configRegion = regionNonFlex || this.#clientOptions.regionNonFlex || region || this.#clientOptions.region;

        const formattedConfigRegion = this.#formatRegionValue(configRegion);
        const regionSuffix = formattedConfigRegion ? `.${formattedConfigRegion}` : "";

        const chunderWUrl = `chunderw-vpc-gll${regionSuffix}.twilio.com`;
        const eventGWUrl = `eventgw-vpc-gll${regionSuffix}.twilio.com`;

        const sdkVersion = packageInfo.version;
        const voiceDeviceOptions = {
            allowIncomingWhileBusy: true,
            appName: "flex-sdk",
            appVersion: sdkVersion,
            logLevel: this.#clientOptions?.logger?.level,
            chunderw: chunderWUrl,
            eventgw: eventGWUrl
        };

        this.#voiceDevice = await this.#createAndRegisterDevice(this.#session.token, voiceDeviceOptions);
        this.#setUpEventHandlers();
        this.#isInitialized = true;
        return Promise.resolve(this.#voiceDevice);
    }

    #onClientDestroyed = (): void => {
        if (this.#voiceDevice) {
            this.#voiceDevice.destroy();
        }
    };

    subscribeToIncomingCallEvent = (callback: (call: Call) => void): void => {
        if (!this.#voiceDevice) {
            throw new Error("Voice device is not initialized");
        }

        this.#voiceDevice.on(Device.EventName.Incoming, callback);
    };

    unsubscribeFromIncomingCallEvent = (callback: (call: Call) => void): void => {
        if (!this.#voiceDevice) {
            throw new Error("Voice device is not initialized");
        }

        this.#voiceDevice.removeListener(Device.EventName.Incoming, callback);
    };

    #setUpEventHandlers(): void {
        this.#voiceDevice.on(Device.EventName.Incoming, this.#onIncomingCall);
        this.#voiceDevice.on(Device.EventName.Error, this.#onDeviceError);
    }

    #setUpCallEventHandlers(call: Call): void {
        call.on("accept", this.#onCallStatusChange);
        call.on("cancel", this.#onCallStatusChange);
        call.on("disconnect", this.#onCallStatusChange);
    }

    #removeCallEventHandlers(call: Call): void {
        call.off("accept", this.#onCallStatusChange);
        call.off("cancel", this.#onCallStatusChange);
        call.off("disconnect", this.#onCallStatusChange);
    }

    #onIncomingCall = (call: Call): void => {
        this.#logger.debug("Incoming voice call in VoiceController", call);
        this.#setUpCallEventHandlers(call);
        if (this.#voiceOptions?.autoAcceptIncomingCalls) {
            call.accept();
        }
    };

    #onCallStatusChange = (call: Call): void => {
        const callStatus = call.status();
        this.#logger.debug("Voice call status changed, call status", callStatus);
        if (callStatus === Call.State.Closed) {
            this.#removeCallEventHandlers(call);
        }
    };

    #onDeviceError = (error: Error, call: Call): void => {
        this.#logger.error("Voice device error", error);
    };

    #onNewToken = (token: string): void => {
        if (!this.#voiceDevice) {
            this.#logger.warn("onNewToken in VoiceController, but there is no voiceDevice");
        }

        this.#voiceDevice.updateToken(token);
    };

    get voiceDevice(): Device {
        return this.#voiceDevice;
    }

    get isInitialized(): boolean {
        return this.#isInitialized;
    }

    isAudioInputDeviceAvailable(): boolean {
        if (!this.#voiceDevice) {
            this.#logger.error("Voice device is not initialized");
            return false;
        }
        this.#logger.debug("Current device", this.#voiceDevice);
        this.#logger.debug("Available input devices:", this.#voiceDevice.audio?.availableInputDevices);

        const ids: Array<string> = Array.from(this.#voiceDevice.audio?.availableInputDevices.keys() || []);
        if (!ids.length) {
            this.#logger.error("No audio input device");
            return false;
        }
        this.#logger.debug("Available input device IDs:", ids);
        const activeDeviceID = ids.find((id) => id === "default") || ids[0];
        this.#logger.debug(`Active device ID: ${activeDeviceID}`);
        return true;
    }

    





    async #createAndRegisterDevice(token: string, options: IExtendedDeviceOptions): Promise<Device> {
        const device = new Device(token, options);
        await device.register();

        if (device.state === Device.State.Registered) {
            this.#logger.info("Voice device is already registered");
            return device;
        }

        return new Promise<Device>((resolve, reject) => {
            const timeout = setTimeout(() => {
                const errorMsg = "Voice device registration timed out";
                this.#logger.error(errorMsg);
                reject(new Error(errorMsg));
            }, 10000);

            const eventHandler = (eventDevice: Device) => {
                if (eventDevice.state === Device.State.Registered) {
                    this.#logger.info("Voice device registered successfully");
                    clearTimeout(timeout);
                    device.off(Device.EventName.Registered, eventHandler);
                    resolve(eventDevice);
                }
            };

            device.on(Device.EventName.Registered, eventHandler);
        });
    }

    #formatRegionValue(inputString: string) {
        const parts = inputString?.split("-") || [];

        
        if (parts.length > 1) {
            return parts[0];
        }

        
        return inputString;
    }
}
