import { ContextManager } from "~/modules/contextManager/ContextManager";
import { isDebuggerErrorCode } from "~/modules/error/ErrorCode/ErrorCodeHelper";
import { FlexSdkError } from "~/modules/error/FlexSdkError/FlexSdkError";
import { ErrorResponse } from "~/modules/error/ThrowError/ErrorHelper";
import { getLogger, LoggerName } from "~/modules/logger";
import { getTelemetrySdkErrorEventGroup } from "~/modules/telemetrySdkClient/telemetrySdkClient";

export function reportError(ctx: ContextManager) {
    return async (error: FlexSdkError) => {
        try {
            const errorEventGroup = getTelemetrySdkErrorEventGroup(ctx);

            const wrappedError = error.wrappedError as ErrorResponse;
            const debuggerFlag = isDebuggerErrorCode(error.code);

            await errorEventGroup.addErrorEvent({
                eventName: "error_reported",
                error: {
                    code: error.code,
                    debugger: debuggerFlag,
                    message: error.message,
                    module: error.metadata.module,
                    reporter: "flex-sdk",
                    resourceSid: error.metadata.resourceSid,
                    severity: error.metadata.severity,
                    source: error.metadata.source || "flex-sdk",
                    stackTrace: error.stack,
                    unhandled: error.metadata.unhandled,
                    wrappedErrorCode: wrappedError?.code,
                    wrappedErrorMessage: wrappedError?.message ?? undefined
                }
            });
        } catch (e) {
            const logger = getLogger(ctx)(LoggerName.Error);
            logger.debug("Failed to report the error", e);
        }
    };
}
