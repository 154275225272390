import { AccountConfigData } from "~/backend/generated/Configuration/model/accountConfigData";
import { ConfigDataContainer } from "~/modules/config/AccountConfig/ConfigDataContainer";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { InternalError } from "~/modules/error";

export class AccountConfigDataContainer implements ConfigDataContainer<AccountConfigData> {
    #data?: AccountConfigData;

    constructor(ctx?: ContextManager) {}

    get(): AccountConfigData {
        if (!this.#data) {
            throw new InternalError("Config data hasn't been fetched");
        }
        return this.#data;
    }

    set(data: AccountConfigData): void {
        this.#data = data;
    }
}
