import { FeaturesConfig } from "../FeaturesProvider";
import { getLogger, LoggerName } from "~/modules/logger";
import { ErrorSeverity, ErrorCode } from "~/modules/error";
import { LocalStorageKeys } from "~/modules/storage";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { throwAndReportFlexSdkError } from "~/modules/error/ThrowError/ThrowAndReportErrorHelper";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { FeaturesServiceImpl } from "~/backend/generated/Features/api/features.service";
import { LocalStorageImpl } from "~/modules/storage/LocalStorage/LocalStorageImpl";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

export const featuresProviderImpl =
    (ctx: ContextManager) =>
    async (sessionToken: string, uiVersion?: string): Promise<FeaturesConfig> => {
        const featuresService = ctx.getInstanceOf(FeaturesServiceImpl);
        const throwAndReportError = throwAndReportFlexSdkError(ctx);
        const storage = ctx.getInstanceOf(LocalStorageImpl);
        const logger = getLogger(ctx)(LoggerName.Config);

        let featuresData: FeaturesConfig;

        try {
            featuresData = await featuresService.fetchFeatures({
                token: sessionToken,
                
                ...(uiVersion && { headers: { "X-Flex-Version": uiVersion } })
            });
            storage.setCachedItem(LocalStorageKeys.FeaturesConfig, featuresData);
        } catch (err) {
            const cachedFeatures = storage.getCachedItem<FeaturesConfig>(LocalStorageKeys.FeaturesConfig);

            if (cachedFeatures === undefined) {
                const message = `Failed to fetch features: ${err}. No cache found.`;
                const errorCode = err.code || ErrorCode.Unknown;
                return throwAndReportError(
                    errorCode,
                    { ...metadata, severity: ErrorSeverity.Error },
                    message
                ) as unknown as Promise<never>;
            }

            logger.warn(`Failed to fetch features: ${err}. Using cache instead`);
            featuresData = cachedFeatures;
        }

        return featuresData;
    };
