

















export enum Role {
    Guest = 'guest',
    Agent = 'agent',
    Admin = 'admin',
    Supervisor = 'supervisor',
    FlexReadqueuestats = 'flex.readqueuestats',
    FlexReadteams = 'flex.readteams',
    FlexEditstatus = 'flex.editstatus',
    FlexEditskills = 'flex.editskills',
    FlexMonitorchatcall = 'flex.monitorchatcall',
    FlexReadadmin = 'flex.readadmin',
    FlexLead = 'flex.lead',
    FlexQualityanalyst = 'flex.qualityanalyst',
    FlexReadonlyadmin = 'flex.readonlyadmin'
}
