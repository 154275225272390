import isString from 'lodash/isString';
import { EventEmitter } from 'events';
import Configuration from './util/Configuration';
import Logger, { LogLevel } from './util/Logger';
import { configureRequest } from './util/Request';
import completeReservation from './cbm_sdk_client/CompleteReservation';
import acceptReservation from './cbm_sdk_client/AcceptReservation';
import rejectReservation from './cbm_sdk_client/RejectReservation';
import wrapReservation from './cbm_sdk_client/WrapReservation';
import getParticipants from './cbm_sdk_client/GetParticipants';
import getChannels from './cbm_sdk_client/GetChannels';
import endConference from './cbm_sdk_client/EndConference';
import setEndConferenceOnExit from './cbm_sdk_client/SetEndConferenceOnExit';
import { holdParticipant, unHoldParticipant } from './cbm_sdk_client/HoldUnholdParticipant';
import {
    addEmailParticipant,
    addSmsParticipant,
    addWebChatParticipant,
    addWhatsAppParticipant,
    addInteractionChannelParticipant
} from './cbm_sdk_client/AddParticipant';
import addVoiceParticipant from './cbm_sdk_client/AddVoiceParticipant';
import removeParticipant from './cbm_sdk_client/RemoveParticipant';
import removeVoiceParticipant from './cbm_sdk_client/RemoveVoiceParticipant';
import { setEventsTimeout } from './util/EventsTimeout';
import createEmailTask from './cbm_sdk_client/CreateEmailTask';
import createSmsTask from './cbm_sdk_client/CreateSmsTask';
import createWhatsAppTask from './cbm_sdk_client/CreateWhatsAppTask';
import createWebTask from './cbm_sdk_client/CreateWebTask';
import leaveConversation from './cbm_sdk_client/LeaveConversation';
import leaveChannel from './cbm_sdk_client/LeaveChannel';
import createInteractionChannel from './cbm_sdk_client/CreateInteractionChannel';
import { closeInteractionChannel, updateInteractionChannel } from './cbm_sdk_client/UpdateInteractionChannel';
import pauseEmailConversation from './cbm_sdk_client/PauseEmailConversation';
import pauseChannel from './cbm_sdk_client/PauseChannel';
import resumeEmailConversation from './cbm_sdk_client/ResumeEmailConversation';
import resumeChannel from './cbm_sdk_client/ResumeChannel';
import getPausedEmailConversations from './cbm_sdk_client/GetPausedEmailConversations';
import getPausedChannels from './cbm_sdk_client/GetPausedChannels';
import { transferChannel } from './cbm_sdk_client/TransferChannel';
import { getChannelTransfers } from './cbm_sdk_client/GetChannelTransfers';





export type CbmSdkClientOptions = {
    


    connectActivitySid?: string;
    


    ebServer?: string;
    


    wsServer?: string;
    


    region?: string;
    


    edge?: string;
    


    logLevel?: LogLevel;
    


    eventsTimeout?: number;
};





class CbmSdkClient extends EventEmitter {
    


    readonly token: string;

    


    readonly #logLevel: LogLevel;

    


    readonly #config: Configuration;

    


    readonly #log: Logger;

    constructor(token: string, options: CbmSdkClientOptions) {
        super();
        if (!isString(token) || !token) {
            throw new TypeError('Failed to instantiate CbmSdkClient. <string>token is a required parameter.');
        }
        this.#logLevel = options.logLevel || LogLevel.Error;
        this.#config = new Configuration(token, options);
        this.#log = new Logger(`Worker-${this.#config.getLogIdentifier()}`, this.#logLevel);
        configureRequest(this.#config);
        setEventsTimeout(options.eventsTimeout);
    }

    





    addEmailParticipant = addEmailParticipant;

    





    addSmsParticipant = addSmsParticipant;

    





    addWhatsAppParticipant = addWhatsAppParticipant;

    





    addWebChatParticipant = addWebChatParticipant;

    





    addVoiceParticipant = addVoiceParticipant;

    







    addInteractionChannelParticipant = addInteractionChannelParticipant;

    





    removeParticipant = removeParticipant;

    





    removeVoiceParticipant = removeVoiceParticipant;

    




    getChannels = getChannels;

    





    getParticipants = getParticipants;

    




    acceptReservation = acceptReservation;

    




    completeReservation = completeReservation;

    





    holdParticipant = holdParticipant;

    





    unHoldParticipant = unHoldParticipant;

    




    endConference = endConference;

    






    setEndConferenceOnExit = setEndConferenceOnExit;

    




    rejectReservation = rejectReservation;

    




    wrapReservation = wrapReservation;

    








    createEmailTask = createEmailTask;

    








    createWhatsAppTask = createWhatsAppTask;

    








    createSmsTask = createSmsTask;

    








    createWebTask = createWebTask;

    




    getLogger(prefix: string): Logger {
        return new Logger(`${prefix}`, this.#config.logLevel);
    }

    




    updateToken(newToken: string): void {
        if (!isString(newToken) || !newToken) {
            throw new TypeError(
                'To update the Twilio token, a new Twilio token must be passed in. <string>newToken is a required parameter.'
            );
        }

        this.#log.info("Proceeding to update the CbmSdkClient's current active token with a new token.");
        this.#log.debug(`New token: ${newToken}`);

        try {
            this.#config.updateToken(newToken);
            this.emit('tokenUpdated');
        } catch (err) {
            this.emit('error', err);
        }
    }

    







    leaveConversation = leaveConversation;

    






    leaveChannel = leaveChannel;

    









    createInteractionChannel = createInteractionChannel;

    








    updateInteractionChannel = updateInteractionChannel;

    







    closeInteractionChannel = closeInteractionChannel;

    







    pauseEmailConversation = pauseEmailConversation;

    







    pauseChannel = pauseChannel;

    






    resumeEmailConversation = resumeEmailConversation;

    






    resumeChannel = resumeChannel;

    





    getPausedEmailConversations = getPausedEmailConversations;

    




    getPausedChannels = getPausedChannels;

    











    transferChannel = transferChannel;

    






    getChannelTransfers = getChannelTransfers;
}
export default CbmSdkClient;











