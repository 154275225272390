import { ContextManager } from "~/modules/contextManager/ContextManager";
import { Twilsock, TwilsockResult } from "~/modules/websocket";
import { TwilsockImpl } from "~/modules/websocket/Twilsock/TwilsockImpl";
import { TwilsockAdapter } from "./TwilsockAdapter";

export class TwilsockAdapterImpl implements TwilsockAdapter {
    readonly #twilsock: Twilsock;

    constructor(ctx: ContextManager) {
        this.#twilsock = ctx.getInstanceOf(TwilsockImpl);
    }

    public async post<U>(url: string, body: object): Promise<TwilsockResult<U>> {
        const result = await this.#twilsock.post<U>(url, {}, body);
        return result;
    }
}
