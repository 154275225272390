import { Reservation } from 'twilio-taskrouter';
import { getRoute, INTERACTION_CHANNEL_INVITE_DECLINE } from '../util/Routes';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { post } from '../util/Request';
import { promiseOrTimeout } from '../util/EventsTimeout';
import { TaskAttributes } from './types/TaskAttributes';







export default async function rejectReservation(reservation: Reservation): Promise<Reservation> {
    const rejectedReservation = new Promise((resolve: (value: Reservation) => void) => {
        reservation.once('rejected', resolve);
    });
    const attributes = reservation.task.attributes as TaskAttributes;

    if (attributes && attributes.flexInteractionChannelSid) {
        if (!attributes.flexChannelInviteSid) {
            throw new Error('flexChannelInviteSid is missing in CBM task attributes');
        }

        const interactionSid = getInteractionSid(reservation.task);
        const channelSid = attributes.flexInteractionChannelSid;
        const inviteSid = attributes.flexChannelInviteSid;

        const requestURL = getRoute(INTERACTION_CHANNEL_INVITE_DECLINE, interactionSid, channelSid, inviteSid).path;
        const requestParam = {
            action: 'decline',
            routing: {
                type: 'taskrouter',
                reservation: {
                    sid: reservation.sid
                }
            }
        };

        await post(requestURL, requestParam);
    } else {
        await reservation.reject();
    }

    return promiseOrTimeout<Reservation>(rejectedReservation, 'Reservation.rejected');
}
