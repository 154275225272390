import { FeaturesConfig } from "~/backend/generated/Features/model/featuresConfig";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { featuresProviderImpl } from "../FeaturesProvider/featuresProviderImpl/featuresProviderImpl";

export const getFeaturesConfig =
    (ctx: ContextManager) =>
    async (token: string, uiVersion?: string): Promise<FeaturesConfig> => {
        const configProvider = featuresProviderImpl(ctx);
        const config = await configProvider(token, uiVersion);
        return config;
    };
