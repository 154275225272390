












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { SimpleHttpAdapterImpl } from "~/backend/SimpleHttpAdapter/SimpleHttpAdapterImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { VirtualAgentData } from "../model/virtualAgentData";

import { VirtualAgentDataService }  from "./virtualAgentData.serviceInterface";

export class VirtualAgentDataServiceImpl implements VirtualAgentDataService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.simpleHttpAdapter = new SimpleHttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.simpleHttpAdapter.setIsEdgeSupported();
        
    }

    






    public async getV1VirtualAgentData(queryParamName: 'ContextLookupSid', queryParamValue: string, options?: object): Promise<VirtualAgentData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (queryParamName === null || queryParamName === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter queryParamName was null or undefined.");
        }
        if (queryParamValue === null || queryParamValue === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter queryParamValue was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.get<VirtualAgentData>(`https://flex-api[region].twilio.com/v1/VirtualAgentData?${encodeURIComponent(String(queryParamName))}=${encodeURIComponent(String(queryParamValue))}`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `VirtualAgentDataService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }
}

