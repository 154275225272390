












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { SimpleHttpAdapterImpl } from "~/backend/SimpleHttpAdapter/SimpleHttpAdapterImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { FlexAuth0Token } from "../model/flexAuth0Token";

import { PreviewFlexTokenService }  from "./previewFlexToken.serviceInterface";

export class PreviewFlexTokenServiceImpl implements PreviewFlexTokenService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.simpleHttpAdapter = new SimpleHttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.simpleHttpAdapter.setIsEdgeSupported(false);
        
    }

    





    public async createFlexAuth0Token(body: string, options?: object): Promise<FlexAuth0Token> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (body === null || body === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter body was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.post<FlexAuth0Token>(`https://preview[region].twilio.com/Flex/Tokens`, undefined, body, options, "application/x-www-form-urlencoded");
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `PreviewFlexTokenService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }
}

