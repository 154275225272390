import { RESUME_CHANNEL, getRoute } from '../util/Routes';
import { post } from '../util/Request';
import { ResumeEmailConversationResponse } from './types/ResumeEmailConversation';









export default async function resumeEmailConversation(
    interactionSid: string,
    channelSid: string,
    taskStatus: string
): Promise<ResumeEmailConversationResponse> {
    if (!interactionSid) {
        throw new TypeError(
            'Error calling method resumeEmailConversation(). <string>interactionSid is a required parameter.'
        );
    }
    if (!channelSid) {
        throw new TypeError(
            'Error calling method resumeEmailConversation(). <string>channelSid is a required parameter.'
        );
    }
    if (!taskStatus) {
        throw new TypeError(
            'Error calling method resumeEmailConversation(). <string>taskStatus is a required parameter.'
        );
    }
    const requestURL = getRoute(RESUME_CHANNEL, interactionSid, channelSid).path;

    return post<ResumeEmailConversationResponse>(requestURL, { status: taskStatus });
}
