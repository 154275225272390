



export enum ErrorCode {
    


    PermissionDeniedError = 20003,
    


    InvalidAccessToken = 20101,
    


    AccessTokenExpired = 20104,
    


    TooManyRequests = 20429,
    


    InternalServerError = 20500,
    


    BadGateway = 20502,
    


    ServiceUnavailable = 20503,
    


    GatewayTimeout = 20504,
    


    AuthorizationError = 45003,
    


    ValidationError = 45004,
    


    ConnectionError = 45011,
    


    SDK = 45500,
    


    Unknown = 45501,
    


    Forbidden = 45502,
    


    InvalidParams = 45503,
    


    NotFound = 45504,
    


    InvalidState = 45505,
    


    NetworkError = 45508,
    


    TwilsockConnectionError = 45511,
    


    DownstreamServiceError = 45512,
    


    BadRequest = 70002,
    


    InvalidCertificate = 70251
}

export type PublicErrorInfoMap = { [TKey in ErrorCode]: string };

export const publicErrorDetails: PublicErrorInfoMap = {
    [ErrorCode.PermissionDeniedError]:
        "PermissionDeniedError: You lack permission to the resource and method you requested",
    [ErrorCode.InvalidAccessToken]: "Invalid Access Token",
    [ErrorCode.AccessTokenExpired]: "Access Token expired or expiration date invalid",
    [ErrorCode.TooManyRequests]: "TooManyRequests: Twilsock rate limit exceeded",
    [ErrorCode.InternalServerError]: "InternalServerError",
    [ErrorCode.BadGateway]: "BadGateway",
    [ErrorCode.ServiceUnavailable]: "Service Unavailable",
    [ErrorCode.GatewayTimeout]: "Gateway timed out",
    [ErrorCode.AuthorizationError]: "Attempt to access resource where access is not allowed for given credentials",
    [ErrorCode.ValidationError]: "Validation error: request body validation fails",
    [ErrorCode.ConnectionError]: "Connection error",
    [ErrorCode.SDK]: "Something went wrong, error from inner SDK",
    [ErrorCode.Unknown]: "Unknown error",
    [ErrorCode.Forbidden]: "User is not authorized to perform the requested operation",
    [ErrorCode.InvalidParams]: "Invalid parameters",
    [ErrorCode.NotFound]: "Resource not found",
    [ErrorCode.InvalidState]: "Invalid state",
    [ErrorCode.NetworkError]: "NetworkError: Not able to reach the server",
    [ErrorCode.TwilsockConnectionError]: "Twilsock connection error",
    [ErrorCode.BadRequest]: "BadRequest: The data in the request is unparsable",
    [ErrorCode.InvalidCertificate]: "Validation error: invalid certificate",
    [ErrorCode.DownstreamServiceError]: "Downstream error"
};


export const debuggerErrorCodes: Array<ErrorCode> = [
    ErrorCode.PermissionDeniedError,
    ErrorCode.InvalidAccessToken,
    ErrorCode.AccessTokenExpired,
    ErrorCode.TooManyRequests,
    ErrorCode.AuthorizationError,
    ErrorCode.ValidationError,
    ErrorCode.BadRequest,
    ErrorCode.InvalidCertificate
];
