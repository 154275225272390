


export enum SDKDependency {
    TwilioClient = "twilio_client",
    Twilsock = "twilsock"
}




export interface TelemetrySDKSessionData {
    


    dependencies?: { [key in SDKDependency]?: string };
}
