export class ContextManager {
    readonly #instancePool: Map<{ new (t: ContextManager): Object }, Object>;

    static newInstance() {
        return new ContextManager();
    }

    constructor() {
        this.#instancePool = new Map();
    }

    getInstanceOf<T extends Object>(name: { new (t: ContextManager): T }): T {
        if (!this.#instancePool.has(name)) {
            
            this.#instancePool.set(name, new name(this));
        }

        return this.#instancePool.get(name) as T;
    }

    





    setInstance<T extends Object>(name: { new (t: ContextManager): T }, instance: T) {
        
        if (typeof global === "undefined" || !process.env) {
            throw new Error("setInstance is not meant to be used externally");
        }

        this.#instancePool.set(name, instance);
    }

    destroy(): void {
        const iterator = this.#instancePool.entries();

        let isDone = false;
        while (!isDone) {
            const { value, done } = iterator.next();
            isDone = !!done;
            if (value) {
                const [, instance] = value;
                instance.destroy && instance.destroy();
            }
        }

        this.#instancePool.clear();
    }
}
