import { PAUSE_CHANNEL, getRoute } from '../util/Routes';
import { post } from '../util/Request';
import { PauseEmailConversationResponse } from './types/PauseEmailConversation';










export default async function pauseEmailConversation(
    interactionSid: string,
    channelSid: string,
    taskStatus: string,
    routingStatus: string
): Promise<PauseEmailConversationResponse> {
    if (!interactionSid) {
        throw new TypeError(
            'Error calling method pauseEmailConversation(). <string>interactionSid is a required parameter.'
        );
    }
    if (!channelSid) {
        throw new TypeError(
            'Error calling method pauseEmailConversation(). <string>channelSid is a required parameter.'
        );
    }

    if (!taskStatus) {
        throw new TypeError(
            'Error calling method pauseEmailConversation(). <string>taskStatus is a required parameter.'
        );
    }

    const requestURL = getRoute(PAUSE_CHANNEL, interactionSid, channelSid).path;
    const requestParams = {
        status: taskStatus,
        routing: {
            status: routingStatus
        }
    };
    return post<PauseEmailConversationResponse>(requestURL, requestParams);
}
