import { ActivityOptions, ConferenceOptions, Reservation, Task, TransferOptions } from "twilio-taskrouter";
import merge from "lodash/merge";
import type { FlexCall } from "~/modules/FlexCall";
import { ErrorCode, ErrorSeverity, FlexSdkError } from "~/modules/error";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { TaskRouterImpl } from "~/packages/taskrouter/TaskRouterImpl";
import type { TaskRouter } from "~/packages/taskrouter/TaskRouter";
import type { Cbm } from "~/modules/cbm";
import { CbmImpl } from "~/modules/cbm";
import { Actions, StartOutboundCallOptions } from "./Actions";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { canHold, getReservationsByTask, isCallTask, sendTrackingEvent } from "./ActionUtils";
import type { VoiceActions } from "~/modules/actions/VoiceActions";
import { VoiceActionsImpl } from "~/modules/actions/VoiceActionsImpl";
import { AnalyticsInstance, EVENTS } from "~/modules/analytics/Analytics";
import { AnalyticsImpl } from "~/modules/analytics/AnalyticsImpl";

export class ActionsImpl implements Actions {
    readonly #taskRouter: TaskRouter;

    readonly #cbm: Cbm;

    readonly #analytics: AnalyticsInstance;

    readonly #logger: Logger;

    readonly #ctx: ContextManager;

    #voiceActions: VoiceActions;

    constructor(ctx: ContextManager) {
        this.#taskRouter = ctx.getInstanceOf(TaskRouterImpl);
        this.#cbm = ctx.getInstanceOf(CbmImpl);
        this.#analytics = ctx.getInstanceOf(AnalyticsImpl);
        this.#logger = getLogger(ctx)(LoggerName.Actions);
        this.#logger.debug("Actions constructed");
        this.#ctx = ctx;
    }

    async kickParticipant(taskSid: string, targetSid: string): Promise<void> {
        if (!this.#cbm) {
            const errorMsg = "kickParticipant: CBM SDK is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "kickParticipant: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations?.find((res) => res.status === "accepted");
        if (!reservation) {
            const errorMsg = `kickParticipant: Reservation for task ${taskSid} not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.KickParticipantCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        const task = reservation.task;

        const participants = await this.#cbm.getParticipantsByTask(task);
        const workerParticipant = participants.find((p) => p.routingProperties?.workerSid === targetSid);

        if (workerParticipant && worker.sid === targetSid) {
            const errorMsg = `kickParticipant: Worker ${targetSid} cannot kick self from task ${taskSid}`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.KickParticipantCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        let customerParticipant;
        if (workerParticipant?.routingProperties?.workerSid !== targetSid) {
            customerParticipant = await this.#cbm.getParticipantBySid(targetSid, task);
        }

        if (!customerParticipant && workerParticipant?.routingProperties?.workerSid !== targetSid) {
            const errorMsg = `kickParticipant: Participant ${targetSid} for task ${taskSid} not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.KickParticipantCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        try {
            if (customerParticipant && customerParticipant.type === "external") {
                await this.#cbm.removeVoiceParticipant(task, customerParticipant.participantSid);
                sendTrackingEvent(EVENTS.KickParticipantCompleted, this.#analytics, worker);
                return Promise.resolve();
            }

            await task.kick(targetSid);
            sendTrackingEvent(EVENTS.KickParticipantCompleted, this.#analytics, worker);
            return Promise.resolve();
        } catch (e) {
            const errorMsg = `kickParticipant: Failed to kick participant ${targetSid} \
            from reservation ${reservation.sid}`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.KickParticipantCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async setWorkerAttributes(
        workerSid: string,
        attributes: Record<string, any>,
        mergeExisting?: boolean
    ): Promise<void> {
        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "setWorkerAttributes: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const newAttributes = mergeExisting ? merge(this.#taskRouter.worker.attributes, attributes) : attributes;

        try {
            await this.#taskRouter.worker.setWorkerAttributes(workerSid, newAttributes);

            sendTrackingEvent(EVENTS.SetWorkerAttributesCompleted, this.#analytics, worker);

            return Promise.resolve();
        } catch (error) {
            const errorMsg = `setWorkerAttributes: Could not set worker attributes: ${error.message}`;
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }
    }

    async setWorkerActivity(
        workerSid: string,
        activitySid: string,
        activityUpdateOptions?: ActivityOptions
    ): Promise<void> {
        this.#logger.debug(`setWorkerActivity invoked with activitySid: ${activitySid} and workerSid: ${workerSid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "setWorkerActivity: worker is not initialized";
            this.#logger.error(errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        if (activitySid === worker.activity?.sid) {
            const warnMsg = `setWorkerActivity: Activity with sid ${activitySid} is already set`;

            sendTrackingEvent(EVENTS.SetWorkerActivityCompleted, this.#analytics, worker, warnMsg);

            this.#logger.warn(warnMsg);
            return Promise.resolve();
        }

        try {
            await worker.setWorkerActivity(workerSid, activitySid, activityUpdateOptions);

            sendTrackingEvent(EVENTS.SetWorkerActivityCompleted, this.#analytics, worker);

            return Promise.resolve();
        } catch (error) {
            const response = error?.message;

            let errorMsg: string;

            if (
                !!response &&
                response.match(/Worker WK.* cannot have its activity updated while it has .* pending reservations/)
            ) {
                errorMsg = `setWorkerActivity: Failed to set current activity to activity ${activitySid} due to \
pending reservations`;
            } else {
                errorMsg = `setWorkerActivity: Failed to set current activity to activity ${activitySid}`;
            }

            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.SetWorkerActivityCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }
    }

    



    async startOutboundCall(
        toNumber: string,
        fromNumber?: string,
        workflowSid?: string,
        taskQueueSid?: string,
        options: StartOutboundCallOptions = {}
    ): Promise<FlexCall> {
        if (!this.#voiceActions) {
            this.#voiceActions = this.#ctx.getInstanceOf(VoiceActionsImpl);
        }
        return this.#voiceActions.startOutboundCall(toNumber, fromNumber, workflowSid, taskQueueSid, options);
    }

    



    async acceptTask(taskSid: string, conferenceOptions?: ConferenceOptions): Promise<Reservation> {
        this.#logger.debug(`acceptTask invoked with taskSid: ${taskSid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "acceptTask: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations.find((res) => res.status === "pending");

        if (!reservation) {
            const errorMsg = `acceptTask: Reservation for task ${taskSid} not found`;

            sendTrackingEvent(EVENTS.AcceptTaskCompleted, this.#analytics, worker, errorMsg);

            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!isCallTask(reservation.task)) {
            if (!this.#cbm) {
                const errorMsg = "acceptTask: CBM SDK is not initialized";
                this.#logger.error(errorMsg);
                sendTrackingEvent(EVENTS.AcceptTaskCompleted, this.#analytics, worker, errorMsg);
                return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
            }

            try {
                const createdReservation = await this.#cbm.acceptReservation(reservation);
                sendTrackingEvent(EVENTS.AcceptTaskCompleted, this.#analytics, worker);
                return createdReservation;
            } catch (error) {
                const errorMsg = `acceptTask: Failed to accept reservation ${reservation.sid}`;
                this.#logger.error(errorMsg);
                return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
            }
        }
        try {
            await reservation.conference(conferenceOptions);

            sendTrackingEvent(EVENTS.AcceptTaskCompleted, this.#analytics, worker);

            return reservation;
        } catch (e) {
            const errorMsg = `acceptTask: Failed to create conference for ${reservation.sid}`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.AcceptTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    



    async rejectTask(taskSid: string): Promise<Reservation> {
        this.#logger.debug(`rejectTask invoked with taskSid: ${taskSid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "rejectTask: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations.find((res) => res.status === "pending");

        if (!reservation) {
            const errorMsg = `rejectTask: Reservation for task ${taskSid} not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.RejectTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        try {
            await this.#cbm.rejectReservation(reservation);
            sendTrackingEvent(EVENTS.RejectTaskCompleted, this.#analytics, worker);
            return reservation;
        } catch (error) {
            const errorMsg = `rejectTask: Failed to reject reservation ${reservation.sid}`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.RejectTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async setAttributes(attributes: Record<any, any>, mergeExisting?: boolean): Promise<void> {
        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "setAttributes: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const newAttributes = mergeExisting ? merge(this.#taskRouter.worker.attributes, attributes) : attributes;

        try {
            await this.#taskRouter.worker.setAttributes(newAttributes);

            sendTrackingEvent(EVENTS.SetAttributesCompleted, this.#analytics, worker);

            return Promise.resolve();
        } catch (error) {
            const errorMsg = `setAttributes: Could not set worker attributes: ${error.message}`;
            sendTrackingEvent(EVENTS.SetAttributesCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    



    async setCurrentActivity(activitySid: string, activityUpdateOptions?: ActivityOptions): Promise<void> {
        this.#logger.debug(`setCurrentActivity invoked with activitySid: ${activitySid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "setCurrentActivity: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const activity = worker.activities.get(activitySid);
        const workerActivity = worker.activity;

        if (activitySid === workerActivity?.sid) {
            const warnMsg = `setCurrentActivity: Activity with sid ${activitySid} is already set`;
            this.#logger.warn(warnMsg);
            sendTrackingEvent(EVENTS.SetCurrentActivityCompleted, this.#analytics, worker, warnMsg);
            return Promise.resolve();
        }

        try {
            await activity?.setAsCurrent(activityUpdateOptions);
            sendTrackingEvent(EVENTS.SetCurrentActivityCompleted, this.#analytics, worker);
            return Promise.resolve();
        } catch (error) {
            const response = error?.message;

            let errorMsg: string;

            if (
                !!response &&
                response.match(/Worker WK.* cannot have its activity updated while it has .* pending reservations/)
            ) {
                errorMsg = `setCurrentActivity: Failed to set current activity to activity ${activitySid} due to \
pending reservations`;
            } else {
                errorMsg = `setCurrentActivity: Failed to set current activity to activity ${activitySid}`;
            }
            sendTrackingEvent(EVENTS.SetCurrentActivityCompleted, this.#analytics, worker, errorMsg);
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async wrapupTask(taskSid: string): Promise<Reservation> {
        this.#logger.debug(`wrapupTask invoked with taskSid: ${taskSid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "wrapupTask: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!this.#cbm) {
            const errorMsg = "wrapupTask: CBM SDK is not initialized";
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.WrapupTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations.find((res) => res.status === "accepted");

        if (!reservation) {
            const errorMsg = `wrapupTask: Reservation for task ${taskSid} is not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.WrapupTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        try {
            const resultReservation = await this.#cbm.wrapReservation(reservation);

            sendTrackingEvent(EVENTS.WrapupTaskCompleted, this.#analytics, worker);

            return resultReservation;
        } catch (error) {
            const errorMsg = `wrapupTask: Failed to wrap-up reservation ${reservation.sid}`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.WrapupTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async completeTask(taskSid: string): Promise<Task> {
        this.#logger.debug(`completeTask invoked with taskSid: ${taskSid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "completeTask: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!this.#cbm) {
            const errorMsg = "completeTask: CBM SDK is not initialized";
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.CompleteTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid).filter(
            (res) => res.status === "wrapping" || res.status === "accepted"
        );

        if (!reservations.length) {
            const errorMsg = `completeTask: Reservation for task ${taskSid} not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.CompleteTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        try {
            await Promise.all(reservations.map((reservation) => this.#cbm.completeReservation(reservation)));
            sendTrackingEvent(EVENTS.CompleteTaskCompleted, this.#analytics, worker);
        } catch (error) {
            const errorMsg = `completeTask: Failed to complete reservation`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.CompleteTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        return reservations[0].task;
    }

    async setTaskAttributes(taskSid: string, attributes: Record<any, any>, mergeExisting?: boolean): Promise<Task> {
        this.#logger.debug(`setTaskAttributes invoked with taskSid: ${taskSid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "setTaskAttributes: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);

        if (!reservations.length) {
            const errorMsg = `setTaskAttributes: task for taskSid: ${taskSid} is not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.SetTaskAttributesCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const task = reservations[0].task;

        try {
            const newAttributes = mergeExisting ? merge(task?.attributes, attributes) : attributes;
            await task.setAttributes(newAttributes);

            sendTrackingEvent(EVENTS.SetTaskAttributesCompleted, this.#analytics, worker);

            return task;
        } catch (error) {
            const errorMsg = `setTaskAttributes: Could not set task attributes: ${error.message}`;
            sendTrackingEvent(EVENTS.SetTaskAttributesCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async transferTask(taskSid: string, to: string, options: TransferOptions): Promise<Task> {
        this.#logger.debug(`transferTask invoked with taskSid: ${taskSid} and to: ${to}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "transferTask: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations.find((res) => res.status === "accepted");

        if (!reservation) {
            const errorMsg = `transferTask: Reservation for task ${taskSid} is not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.TransferTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!isCallTask(reservation.task)) {
            const errorMsg = `transferTask: Reservation ${taskSid} is not an active call`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.TransferTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (reservation.task.transfers.outgoing?.status === "INITIATED") {
            const errorMsg = `transferTask: Reservation ${taskSid} has already inititated transfer`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.TransferTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (reservation.workerSid !== worker.sid) {
            const errorMsg = `transferTask: Reservation ${taskSid} cannot be transferred by a different worker`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.TransferTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        try {
            if (options.mode === "COLD") {
                await reservation.updateParticipant({ endConferenceOnExit: false });
            }
            const task = await reservation?.task.transfer(to, options);

            sendTrackingEvent(EVENTS.TransferTaskCompleted, this.#analytics, worker);

            return task;
        } catch (error) {
            if (options.mode === "COLD") {
                await reservation.updateParticipant({ endConferenceOnExit: true });
            }
            const errorMsg = `transferTask: Could not transfer task: ${error.message}`;
            sendTrackingEvent(EVENTS.TransferTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async cancelTaskTransfer(taskSid: string): Promise<Task> {
        this.#logger.debug(`cancelTaskTransfer invoked with taskSid: ${taskSid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "cancelTaskTransfer: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations.find((res) => res.status === "accepted");

        if (!reservation) {
            const errorMsg = `cancelTaskTransfer: Reservation for task ${taskSid} is not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.CancelTaskTransfer, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!isCallTask(reservation.task)) {
            const errorMsg = `cancelTaskTransfer: Reservation for task ${taskSid} is not an active call`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.CancelTaskTransfer, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!reservation.task?.transfers?.outgoing) {
            const errorMsg = `cancelTaskTransfer: Reservation for task ${taskSid} has no outgoing transfer to cancel`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.CancelTaskTransfer, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
        try {
            await reservation.task.transfers.outgoing.cancel();

            sendTrackingEvent(EVENTS.CancelTaskTransfer, this.#analytics, worker);

            return reservation.task;
        } catch (error) {
            const errorMsg = `cancelTaskTransfer: Could not transfer task: ${error.message}`;
            sendTrackingEvent(EVENTS.CancelTaskTransfer, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async endTask(taskSid: string): Promise<Task> {
        this.#logger.debug(`endTask invoked with taskSid: ${taskSid}`);

        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "endTask: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!this.#cbm) {
            const errorMsg = "endTask: CBM SDK is not initialized";
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.EndTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations.find((res) => res.status === "accepted");
        const wrappingReservation = reservations.find((res) => res.status === "wrapping");

        if (!reservation && !wrappingReservation) {
            const errorMsg = `endTask: Reservation for task ${taskSid} is not found`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.EndTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        try {
            if (reservation && isCallTask(reservation.task)) {
                return (await this.#cbm.endConference(reservation)).task;
            }
            if (wrappingReservation) {
                return Promise.resolve(await this.completeTask(taskSid));
            }
            const { task } = await this.wrapupTask(taskSid);

            sendTrackingEvent(EVENTS.EndTaskCompleted, this.#analytics, worker);

            return Promise.resolve(task);
        } catch (error) {
            const errorMsg = `endTask: Could not end task: ${error.message}`;
            sendTrackingEvent(EVENTS.EndTaskCompleted, this.#analytics, worker, errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    



    async holdParticipant(
        targetSid: string,
        taskSid: string,
        holdMusicUrl?: string,
        holdMusicMethod: string = "GET"
    ): Promise<void> {
        const worker = this.#taskRouter.worker;
        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations.find((res) => res.status === "accepted");
        if (!reservation || !canHold(reservation, worker)) {
            const errorMsg = "holdParticipant: Can't hold participant";
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.HoldParticipantCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        const task = reservation.task;

        const participant = await this.#cbm.getParticipantBySid(targetSid, task);

        if (participant && participant.type === "external") {
            try {
                await this.#cbm.holdParticipant(task, targetSid);

                sendTrackingEvent(EVENTS.HoldParticipantCompleted, this.#analytics, worker);

                return Promise.resolve();
            } catch (error) {
                const errorMsg = `holdParticipant: Could not hold external participant ${targetSid}`;
                sendTrackingEvent(EVENTS.HoldParticipantCompleted, this.#analytics, worker, errorMsg);
                throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
            }
        }

        const participants = await this.#cbm.getParticipantsByTask(task);
        const workerParticipant = participants.find((p) => p.routingProperties?.workerSid === targetSid);
        if (workerParticipant && workerParticipant.routingProperties?.workerSid && workerParticipant.type === "agent") {
            await task.hold(workerParticipant.routingProperties?.workerSid, true, {
                ...(holdMusicUrl && { holdUrl: holdMusicUrl }),
                ...(holdMusicMethod && { holdMethod: holdMusicMethod })
            });

            sendTrackingEvent(EVENTS.HoldParticipantCompleted, this.#analytics, worker);

            return Promise.resolve();
        }

        try {
            await task.updateParticipant({
                hold: true,
                ...(holdMusicUrl && { holdUrl: holdMusicUrl }),
                holdMethod: holdMusicMethod
            });

            sendTrackingEvent(EVENTS.HoldParticipantCompleted, this.#analytics, worker);

            return Promise.resolve();
        } catch (error) {
            const errorMsg = `holdParticipant: Could not hold participant ${targetSid}`;
            sendTrackingEvent(EVENTS.HoldParticipantCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }
    }

    async unholdParticipant(targetParticipantSid: string, taskSid: string): Promise<void> {
        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "unholdParticipant: Worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservations = getReservationsByTask(worker, taskSid);
        const reservation = reservations.find((res) => res.status === "accepted");
        if (!reservation || !canHold(reservation, worker)) {
            const errorMsg = "unholdParticipant: Reservation not found or worker cannot unhold it";
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.UnholdParticipantCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        const task = reservation.task;
        const participant = await this.#cbm.getParticipantBySid(targetParticipantSid, task);

        if (participant && participant.type === "external") {
            try {
                await this.#cbm.unholdParticipant(task, targetParticipantSid);
                sendTrackingEvent(EVENTS.UnholdParticipantCompleted, this.#analytics, worker);
                return Promise.resolve();
            } catch (error) {
                const errorMsg = `unholdParticipant: Could not unhold external participant ${targetParticipantSid}`;
                this.#logger.error(errorMsg);
                sendTrackingEvent(EVENTS.UnholdParticipantCompleted, this.#analytics, worker, errorMsg);
                throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
            }
        }
        const participants = await this.#cbm.getParticipantsByTask(task);
        const workerParticipant = participants.find((p) => p.routingProperties?.workerSid === targetParticipantSid);

        if (workerParticipant && workerParticipant.routingProperties?.workerSid && workerParticipant.type === "agent") {
            try {
                await task.hold(workerParticipant.routingProperties?.workerSid, false);
                sendTrackingEvent(EVENTS.UnholdParticipantCompleted, this.#analytics, worker);
                return Promise.resolve();
            } catch (error) {
                const errorMsg = `unholdParticipant: Could not unhold agent participant ${targetParticipantSid}`;
                this.#logger.error(errorMsg);
                sendTrackingEvent(EVENTS.UnholdParticipantCompleted, this.#analytics, worker, errorMsg);
                throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
            }
        }

        try {
            await task.updateParticipant({
                hold: false
            });
            sendTrackingEvent(EVENTS.UnholdParticipantCompleted, this.#analytics, worker);
            return Promise.resolve();
        } catch (error) {
            const errorMsg = `unholdParticipant: Could not unhold participant ${targetParticipantSid}`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.UnholdParticipantCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }
    }
}
