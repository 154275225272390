import { ContextManager } from "~/modules/contextManager/ContextManager";
import { FlexSdkErrorMetadata } from "~/modules/error";
import { createFlexSdkError, throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import ErrorReporter from "~/modules/reporter/ErrorReporter";

export function throwAndReportFlexSdkError(ctx: ContextManager) {
    return (errorCode: number, metadata?: FlexSdkErrorMetadata, message?: string, error?: Error): void => {
        const errorToThrow = createFlexSdkError(ctx)(errorCode, metadata, message, error);

        ctx.getInstanceOf(ErrorReporter).publishTelemetryError(errorToThrow);
        throwFlexSdkError(ctx)(errorCode, metadata, message, errorToThrow);
    };
}
