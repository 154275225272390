import { Task } from 'twilio-taskrouter';
import isEmpty from 'lodash/isEmpty';
import { getRoute, INTERACTION_GET_CHANNELS } from '../util/Routes';
import { get } from '../util/Request';
import { getInteractionSid } from '../util/FlexInteractionSid';
import CbmChannel, { ChannelData } from '../CbmChannel';

type GetChannelsResponse = {
    channels: Array<ChannelData>;
};







export default async function getChannels(task: Task): Promise<CbmChannel[]> {
    if (isEmpty(task.attributes)) {
        throw new Error('Task attributes missing - getChannels expects the task to have attributes');
    }

    const interactionSid = getInteractionSid(task);
    const requestURL = getRoute(INTERACTION_GET_CHANNELS, interactionSid).path;
    const response = await get<GetChannelsResponse>(requestURL);

    const channelList: Array<CbmChannel> = [];
    if (response.channels) {
        response.channels.forEach((channel: ChannelData) => {
            channelList.push({
                ...channel,
                interactionSid: channel.interaction_sid,
                mediaSid: channel.media_sid
            });
        });
    }
    return channelList;
}
