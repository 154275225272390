import { Reservation } from 'twilio-taskrouter';
import { getRoute, INTERACTION_CHANNEL_INVITE_ACCEPT } from '../util/Routes';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { post } from '../util/Request';
import { promiseOrTimeout } from '../util/EventsTimeout';
import TwilioError from '../util/TwilioError';
import { TaskAttributes } from './types/TaskAttributes';







export default async function acceptReservation(reservation: Reservation): Promise<Reservation> {
    const acceptedReservation = new Promise(
        (resolve: (value: Reservation) => void, reject: (value: TwilioError) => void) => {
            reservation.once('accepted', resolve);

            
            const participantAddFailedHandler = (_: any, event: any) => {
                const eventReason = event.error_data && event.error_data.error_message;
                reject(
                    new TwilioError(
                        event,
                        `Failed to add Participant to Task sid=${reservation.task.sid}, Participant sid=${event.participant_sid}. Reason: ${eventReason}`
                    )
                );
            };
            reservation.task.once('participantAddFailed', participantAddFailedHandler);
        }
    );
    const attributes = reservation.task.attributes as TaskAttributes;

    if (attributes && attributes.flexInteractionChannelSid) {
        if (!attributes.flexChannelInviteSid) {
            throw new Error('flexChannelInviteSid is missing in CBM task attributes');
        }

        const interactionSid = getInteractionSid(reservation.task);
        const channelSid = attributes.flexInteractionChannelSid;
        const inviteSid = attributes.flexChannelInviteSid;

        const requestURL = getRoute(INTERACTION_CHANNEL_INVITE_ACCEPT, interactionSid, channelSid, inviteSid).path;
        const requestParams = {
            action: 'accept',
            routing: {
                type: 'taskrouter',
                reservation: {
                    sid: reservation.sid
                }
            }
        };

        await post(requestURL, requestParams);
    } else {
        await reservation.accept();
    }

    return promiseOrTimeout<Reservation>(acceptedReservation, 'Reservation.accepted');
}
