import { Reservation } from 'twilio-taskrouter';
import { getRoute, INTERACTION_CHANNEL } from '../util/Routes';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { promiseOrTimeout } from '../util/EventsTimeout';
import { post } from '../util/Request';
import { TaskAttributes } from './types/TaskAttributes';








export default async function completeReservation(reservation: Reservation): Promise<Reservation> {
    if (!reservation.task) {
        throw new Error('Failed to complete Reservation. Task is missing from Reservation.');
    }

    const completedReservation = new Promise((resolve: (value: Reservation) => void) => {
        reservation.once('completed', resolve);
    });

    const attributes = reservation.task.attributes as TaskAttributes;

    const reservationWithConversation = attributes && attributes.flexInteractionChannelSid;

    if (reservationWithConversation) {
        const interactionSid = getInteractionSid(reservation.task);
        const channelSid = attributes.flexInteractionChannelSid;

        const requestURL = getRoute(INTERACTION_CHANNEL, interactionSid, channelSid!).path;
        const requestParams = {
            status: 'closed',
            routing: {
                status: 'closed'
            }
        };

        await post(requestURL, requestParams);
    } else {
        await reservation.complete();
    }

    return promiseOrTimeout<Reservation>(completedReservation, 'Reservation.completed');
}
