import { PAUSE_CHANNEL, getRoute } from '../util/Routes';
import { post } from '../util/Request';
import { PauseChannelResponse } from './types/PauseChannel';









export default async function pauseChannel(
    interactionSid: string,
    channelSid: string,
    taskStatus: string,
    routingStatus: string
): Promise<PauseChannelResponse> {
    if (!interactionSid) {
        throw new TypeError('Error calling method pauseChannel(). <string>interactionSid is a required parameter.');
    }
    if (!channelSid) {
        throw new TypeError('Error calling method pauseChannel(). <string>channelSid is a required parameter.');
    }

    if (!taskStatus) {
        throw new TypeError('Error calling method pauseChannel(). <string>taskStatus is a required parameter.');
    }

    const requestURL = getRoute(PAUSE_CHANNEL, interactionSid, channelSid).path;
    const requestParams = {
        status: taskStatus,
        routing: {
            status: routingStatus
        }
    };
    return post<PauseChannelResponse>(requestURL, requestParams);
}
