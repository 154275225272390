import { Worker } from 'twilio-taskrouter';
import { getRoute, CREATE_INTERACTION } from '../util/Routes';
import { post } from '../util/Request';
import { CreateCbmTaskParams, CreateCbmTaskResponse, CreateWhatsAppTaskOptions } from './types/CreateTask';
import { MediaChannelType } from './types/MediaChannelType';












export default async function createWhatsAppTask(
    worker: Worker,
    workflowSid: string,
    taskQueueSid: string,
    options: CreateWhatsAppTaskOptions
): Promise<CreateCbmTaskResponse> {
    if (!options.to) {
        throw new TypeError('Error calling method createTask(). <string>to is a required parameter.');
    }

    if (!options.from) {
        throw new TypeError('Error calling method createTask(). <string>from is a required parameter.');
    }

    if (!workflowSid) {
        throw new TypeError('Error calling method createTask(). <string>workflowSid is a required parameter.');
    }

    if (!taskQueueSid) {
        throw new TypeError('Error calling method createTask(). <string>taskQueueSid is a required parameter.');
    }

    const requestURL = getRoute(CREATE_INTERACTION).path;

    const requestParams: CreateCbmTaskParams = {
        channel: {
            type: MediaChannelType.WhatsApp,
            initiated_by: 'agent',
            properties: {
                type: MediaChannelType.WhatsApp
            },
            participants: [
                {
                    address: options.to,
                    proxy_address: options.from
                }
            ]
        },
        routing: {
            type: 'TaskRouter',
            properties: {
                workspace_sid: worker.workspaceSid,
                workflow_sid: workflowSid,
                queue_sid: taskQueueSid,
                worker_sid: worker.sid,
                attributes: {
                    customerName: options.toName || '',
                    customerAddress: options.to
                }
            }
        }
    };

    // All except 'attributes', 'mediaChannelType', 'toName', 'fromName'
    const { taskChannelSid, taskUniqueChannelName } = options;
    requestParams!.taskChannelSid = taskChannelSid;
    requestParams!.taskUniqueChannelName = taskUniqueChannelName;

    requestParams!.routing.properties.attributes = {
        ...requestParams!.routing.properties.attributes,
        ...options.attributes
    };

    
    
    const response = await post<any>(requestURL, requestParams as Object);
    return {
        interactionSid: response.sid,
        taskSid: response.routing.properties.sid
    };
}
