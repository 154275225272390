import { stringify } from "query-string";
import { FederatedAuthServiceImpl } from "~/backend/generated/FederatedAuth/api/federatedAuth.service";
import { FederatedAuthService } from "~/backend/generated/FederatedAuth/api/federatedAuth.serviceInterface";
import { PreviewFlexTokenService } from "~/backend/generated/PreviewFlex/api/previewFlexToken.serviceInterface";
import { PreviewFlexTokenServiceImpl } from "~/backend/generated/PreviewFlex/api/previewFlexToken.service";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";
import { getEnvironmentConfig } from "~/modules/config";
import { InternalError } from "~/modules/error";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { assertNotEmptyString } from "~/utils/assert";
import { AuthenticatorDataContainer } from "../AuthenticatorDataContainer/AuthenticatorDataContainer";
import { AuthenticatorDataContainerImpl } from "../AuthenticatorDataContainer/AuthenticatorDataContainerImpl";
import { getSSOLoginRequestBody, generateProofKey } from "./AuthenticatorHelper";
import {
    Authenticator,
    IdpConfig,
    TokenData,
    TokenRefreshResult,
    LoginDetailsResponse,
    ExchangeTokenParams,
    FlexAuthToken
} from "./Authenticator";
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";
import { buildRegionalHost, parseRegionForTwilsock } from "~/utils/regionUtil";

export class AuthenticatorImpl implements Authenticator {
    readonly #authenticatorDataContainer: AuthenticatorDataContainer;

    readonly #logger: Logger;

    readonly #federatedAuthService: FederatedAuthService;

    readonly #previewFlexTokenService: PreviewFlexTokenService;

    readonly #clientOptions: ClientOptionsStore;

    constructor(ctx: ContextManager) {
        this.#logger = getLogger(ctx)(LoggerName.Auth);
        this.#authenticatorDataContainer =
            ctx.getInstanceOf<AuthenticatorDataContainerImpl>(AuthenticatorDataContainerImpl);
        this.#clientOptions = ctx.getInstanceOf(ClientOptionsStore);
        this.#federatedAuthService = ctx.getInstanceOf(FederatedAuthServiceImpl);
        this.#previewFlexTokenService = ctx.getInstanceOf(PreviewFlexTokenServiceImpl);
    }

    async getIdpUrl(config: IdpConfig): Promise<string> {
        assertNotEmptyString(config.redirectUrl, "redirect url");

        const payload = getSSOLoginRequestBody(config);
        const accountSid = this.#authenticatorDataContainer.accountSid;

        const data = await this.#federatedAuthService.getIdpUrl(accountSid, payload);
        if (!data.location) {
            this.#logger.error("No redirect location from /authenticate request, data: ", data);
            throw new InternalError("Invalid response from /authenticate endpoint");
        }
        return data.location;
    }

    async validateToken(token: string): Promise<TokenData> {
        const accountSid = this.#authenticatorDataContainer.accountSid;
        const tokenData = await this.#federatedAuthService.validateToken(accountSid, { token }, { token });

        return {
            roles: tokenData.roles,
            valid: tokenData.valid,
            dateExpired: tokenData.expiration,
            identity: tokenData.identity,
            flexUserSid: tokenData.flexUserSid
        };
    }

    async refreshToken(token: string): Promise<TokenRefreshResult> {
        const accountSid = this.#authenticatorDataContainer.accountSid;
        const tokenRefreshResult = await this.#federatedAuthService.refreshToken(accountSid, {
            token
        });
        return { token: tokenRefreshResult.token, dateExpired: tokenRefreshResult.expiration };
    }

    async getLoginDetails(
        clientId: string,
        connectionName: string,
        redirectUrl?: string
    ): Promise<LoginDetailsResponse> {
        const environmentConfig = getEnvironmentConfig();
        const { region, regionNonFlex } = environmentConfig || {};
        const configRegion = regionNonFlex || this.#clientOptions.regionNonFlex || region || this.#clientOptions.region;
        const regionToUse = buildRegionalHost(configRegion || "");
        const twilioSSORedirectUrl = `https://flex${regionToUse}.twilio.com/callback`;
        const twilioSSOServiceUrl = `https://login.flex.${parseRegionForTwilsock(configRegion)}.twilio.com/authorize`;
        const twilioSSOAuthCallback = `https://services${regionToUse}.twilio.com/v1/Flex/Authentication/Callback`;

        const redirectUrlParam = redirectUrl || twilioSSORedirectUrl;
        const { nonce, codeChallenge, state, codeVerifier } = await generateProofKey(connectionName, redirectUrlParam);
        const queryParams = stringify(
            {
                client_id: clientId,
                connection: connectionName,
                response_type: "code",
                state,
                nonce,
                redirect_uri: encodeURIComponent(twilioSSOAuthCallback),
                code_challenge_method: "S256",
                code_challenge: codeChallenge,
                scope: encodeURIComponent("openid offline_access profile email")
            },
            { encode: false, sort: false }
        );

        return { loginUrl: `${twilioSSOServiceUrl}?${queryParams}`, nonce, codeChallenge, state, codeVerifier };
    }

    async exchangeToken(config: ExchangeTokenParams): Promise<FlexAuthToken> {
        const { clientId, authCode, codeVerifier, nonce, state } = config;
        const environmentConfig = getEnvironmentConfig();
        const { region, regionNonFlex } = environmentConfig || {};
        const configRegion = regionNonFlex || this.#clientOptions.regionNonFlex || region || this.#clientOptions.region;
        const regionToUse = buildRegionalHost(configRegion || "");
        const twilioSSOAuthCallback = `https://services${regionToUse}.twilio.com/v1/Flex/Authentication/Callback`;

        const data = new URLSearchParams({
            ClientId: clientId,
            CodeVerifier: codeVerifier,
            AuthCode: authCode,
            RedirectUrl: twilioSSOAuthCallback,
            State: state,
            Nonce: nonce
        }).toString();

        const response = await this.#previewFlexTokenService.createFlexAuth0Token(data);
        return convertDateStringValuesToDate(response);
    }
}
