import { TelemetryServiceImpl } from "~/backend/generated/Telemetry/api/telemetry.service";
import { TelemetryService } from "~/backend/generated/Telemetry/api/telemetry.serviceInterface";
import { ProcessingStats } from "~/backend/generated/Telemetry/model/processingStats";
import { TelemetryBackendEvent } from "~/backend/generated/Telemetry/model/telemetryBackendEvent";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { ErrorSeverity, ThrowAndReportErrorFunction, ThrowErrorFromErrorResponseFunction } from "~/modules/error";
import { throwFlexSdkErrorFromErrorResponse } from "~/modules/error/ThrowError/ErrorHelper";
import { throwAndReportFlexSdkError } from "~/modules/error/ThrowError/ThrowAndReportErrorHelper";
import { getLogger, Logger, TelemetryLoggerName } from "~/modules/logger";
import { TelemetryEvent } from "~/modules/telemetry";
import { TelemetryProcessingResult } from "~/modules/telemetry/TelemetryProcessor/TelemetryProcessingResult";
import { TelemetryProcessor } from "~/modules/telemetry/TelemetryProcessor/TelemetryProcessor";
import { assertNotEmptyString } from "~/utils/assert";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { toSdkBackendEvents } from "./toSdkBackendEvent";

const TELEMETRY_DISABLED_HTTP_STATUS_CODE = 409;
const TOO_MANY_REQUESTS_HTTP_STATUS_CODE = 429;

const MAX_NUMBER_OF_EVENTS_IN_BATCH = 50;
const REQUEST_ERROR_PAUSE_THRESHOLD = 5;

const ONE_MINUTE_MS = 60000;
const FIVE_MINUTES_MS = 300000;

export class TwilioTelemetryProcessor implements TelemetryProcessor {
    readonly #logger: Logger;

    readonly #telemetryService: TelemetryService;

    #isTelemetryDisabled = false;

    #isTelemetryPaused = false;

    #consecutiveRequestErrorCount = 0;

    readonly #throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction;

    readonly #throwAndReportError: ThrowAndReportErrorFunction;

    constructor(ctx: ContextManager) {
        this.#logger = getLogger(ctx)(TelemetryLoggerName.TelemetryProcessor);
        this.#telemetryService = new TelemetryServiceImpl(ctx);
        this.#throwAndReportError = throwAndReportFlexSdkError(ctx);
        this.#throwErrorFromErrorResponse = throwFlexSdkErrorFromErrorResponse(ctx);
    }

    async processEvents(
        payloadType: string,
        groupName?: string,
        sessionData?: object,
        ...events: TelemetryEvent[]
    ): Promise<TelemetryProcessingResult> {
        assertNotEmptyString(payloadType, "payload type");

        if (typeof groupName !== "undefined") {
            assertNotEmptyString(groupName, "group name");
        }

        events.forEach(({ eventName, eventSource }) => {
            assertNotEmptyString(eventName, "event name");
            if (typeof eventSource !== "undefined") {
                assertNotEmptyString(eventSource, "event source");
            }
        });

        const telemetryNotSentResult = {
            eventsNotProcessed: events.length,
            eventsSucceeded: 0,
            eventsFailed: 0
        };

        if (this.#isTelemetryDisabled) {
            this.#logger.trace("Events not sent: telemetry disabled");
            return telemetryNotSentResult;
        }

        if (this.#isTelemetryPaused) {
            this.#logger.trace("Events not sent: telemetry is paused due to server errors");
            return telemetryNotSentResult;
        }

        this.#logger.debug("common attributes:", sessionData);
        const backendEvents = toSdkBackendEvents(
            this.#throwAndReportError,
            payloadType,
            groupName,
            sessionData,
            ...events
        );

        let eventsSucceeded = 0;
        let eventsFailed = 0;

        if (backendEvents.length) {
            let backendEventsBatch;
            const arrayOfPromises = [];
            for (let i = 0; i < backendEvents.length; i += MAX_NUMBER_OF_EVENTS_IN_BATCH) {
                
                if (this.#isTelemetryDisabled || this.#isTelemetryPaused) {
                    break;
                }

                backendEventsBatch = backendEvents.slice(i, i + MAX_NUMBER_OF_EVENTS_IN_BATCH);
                arrayOfPromises.push(this.#sendTelemetryEvents(...backendEventsBatch));
            }
            const batchResults = await Promise.all(arrayOfPromises);
            eventsSucceeded = batchResults.reduce((acc, batch) => acc + batch.number_of_successful_events, 0);
            eventsFailed = batchResults.reduce((acc, batch) => acc + batch.number_of_failed_events, 0);
        }

        const eventsNotProcessed = events.length - eventsSucceeded - eventsFailed;

        return {
            eventsSucceeded,
            eventsFailed,
            eventsNotProcessed
        };
    }

    #sendTelemetryEvents = async (...events: TelemetryBackendEvent[]): Promise<ProcessingStats> => {
        this.#logger.debug("Sending", events.length, "telemetry events");
        this.#logger.trace("Events", events);
        let stats: ProcessingStats = {
            number_of_successful_events: 0,
            number_of_failed_events: 0
        };

        try {
            const { body } = await this.#telemetryService.postTelemetryEvents({ events });
            this.#consecutiveRequestErrorCount = 0;
            if (body) {
                stats = body;
                this.#logger.debug("Telemetry sent successfully");
            }
        } catch (error) {
            const httpErrorCode = error.wrappedError?.status;

            if (httpErrorCode === TELEMETRY_DISABLED_HTTP_STATUS_CODE) {
                this.#logger.warn("Telemetry is disabled for this account");
                this.#isTelemetryDisabled = true;
                return stats;
            }
            this.#consecutiveRequestErrorCount++;

            if (this.#consecutiveRequestErrorCount >= REQUEST_ERROR_PAUSE_THRESHOLD) {
                this.#consecutiveRequestErrorCount = 0;
                this.#pauseTelemetry(FIVE_MINUTES_MS);
            } else if (httpErrorCode === TOO_MANY_REQUESTS_HTTP_STATUS_CODE) {
                this.#logger.warn("Telemetry rate limit hit");
                this.#pauseTelemetry(ONE_MINUTE_MS);
            }

            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                eventSource: extractFileNameFromPath(__filename)
            };

            this.#throwErrorFromErrorResponse(error, metadata);
        }

        return stats;
    };

    #pauseTelemetry = (durationMs: number) => {
        this.#isTelemetryPaused = true;
        setTimeout(() => {
            this.#isTelemetryPaused = false;
        }, durationMs);
    };
}
