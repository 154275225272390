import { LEAVE_CHANNEL, getRoute } from '../util/Routes';
import { post } from '../util/Request';
import { LeaveConversationResponse } from './types/LeaveChannel';










export default async function leaveConversation(
    interactionSid: string,
    channelSid: string,
    channelStatus: string,
    taskStatus: string
): Promise<LeaveConversationResponse> {
    if (!interactionSid) {
        throw new TypeError(
            'Error calling method leaveConversation(). <string>interactionSid is a required parameter.'
        );
    }

    if (!channelSid) {
        throw new TypeError('Error calling method leaveConversation(). <string>channelSid is a required parameter.');
    }

    const requestURL = getRoute(LEAVE_CHANNEL, interactionSid, channelSid).path;
    return post<LeaveConversationResponse>(requestURL, { status: channelStatus, routing: { status: taskStatus } });
}
