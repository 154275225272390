import { LogLevelDesc } from "loglevel";
import { Reservation, Task } from "twilio-taskrouter";
import { CbmParticipant, CbmSdk, CbmSdkClientOptions, ChannelStatus, LogLevel } from "./dist";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import type { Session } from "~/modules/session/Session/Session";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { getEnvironmentConfig } from "~/modules/config";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";
import type { Cbm } from "~/packages/cbm/Cbm";
import { ErrorCode, ErrorSeverity, FlexSdkError } from "~/modules/error";

export class CbmImpl implements Cbm {
    readonly #logger: Logger;

    readonly #session: Session;

    readonly #clientOptions: ClientOptionsStore;

    #cbm: CbmSdk;

    constructor(ctx: ContextManager) {
        this.#session = ctx.getInstanceOf(SessionImpl);
        this.#clientOptions = ctx.getInstanceOf(ClientOptionsStore);
        this.#logger = getLogger(ctx)(LoggerName.CBM);
    }

    async init(cbmInstance?: CbmSdk): Promise<void> {
        if (cbmInstance) {
            this.#cbm = cbmInstance;
            return Promise.resolve();
        }

        const environmentConfig = getEnvironmentConfig();
        const { region, regionNonFlex } = environmentConfig || {};
        const configRegion = regionNonFlex || this.#clientOptions.regionNonFlex || region || this.#clientOptions.region;

        const cbmOptions: CbmSdkClientOptions = {
            logLevel: this.mapToLogLevel(this.#clientOptions?.logger?.level)
        };

        if (configRegion !== undefined) {
            cbmOptions.region = configRegion;
        }

        this.#cbm = new CbmSdk(this.#session.token, cbmOptions);
        return Promise.resolve();
    }

    updateToken(token: string): void {
        this.#cbm.updateToken(token);
    }

    async acceptReservation(reservation: Reservation): Promise<Reservation> {
        

        
        return this.#cbm.acceptReservation(reservation);
    }

    async removeVoiceParticipant(task: Task, participantSid: string): Promise<CbmParticipant> {
        

        
        return this.#cbm.removeVoiceParticipant(task, participantSid);
    }

    async endConference(reservation: Reservation): Promise<Reservation> {
        

        
        await this.#cbm.endConference(reservation.task);
        return reservation;
    }

    async rejectReservation(reservation: Reservation): Promise<Reservation> {
        

        
        return this.#cbm.rejectReservation(reservation);
    }

    async holdParticipant(task: Task, participantSid: string): Promise<CbmParticipant> {
        

        
        return this.#cbm.holdParticipant(task, participantSid);
    }

    async getParticipantsByTask(task: Task): Promise<CbmParticipant[]> {
        const channels = await this.#cbm.getChannels(task);
        return this.#cbm.getParticipants(task, channels[0].sid);
    }

    async getParticipantBySid(sid: string, task: Task): Promise<CbmParticipant | undefined> {
        const channels = await this.#cbm.getChannels(task);

        const activeChannel = channels.find((channel) => channel.status === ChannelStatus.Active);
        if (!activeChannel || !activeChannel.sid) {
            const errorMsg = `Active channel for task: ${task.sid} not found`;
            this.#logger.error(errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }
        const participants = await this.#cbm.getParticipants(task, activeChannel.sid);

        const participant = participants.find((p) => p.participantSid === sid);

        if (!participant) {
            const warnMsg = `Participant with sid: ${sid} not found`;
            this.#logger.warn(warnMsg);
        }

        return participant;
    }

    async completeReservation(reservation: Reservation): Promise<Reservation> {
        

        
        return this.#cbm.completeReservation(reservation);
    }

    wrapReservation(reservation: Reservation): Promise<Reservation> {
        

        
        return this.#cbm.wrapReservation(reservation);
    }

    private mapToLogLevel(logLevel: LogLevelDesc): LogLevel {
        if (typeof logLevel === "number") {
            switch (logLevel) {
                case 0:
                    return LogLevel.Trace;
                case 1:
                    return LogLevel.Debug;
                case 2:
                    return LogLevel.Info;
                case 3:
                    return LogLevel.Warn;
                case 4:
                    return LogLevel.Error;
                case 5:
                    return LogLevel.Silent;
                default:
                    return LogLevel.Warn;
            }
        } else if (typeof logLevel === "string") {
            const lowercaseLogLevel = logLevel.toLowerCase();
            if (lowercaseLogLevel in LogLevel) {
                return LogLevel[lowercaseLogLevel as keyof typeof LogLevel];
            }
        }
        return LogLevel.Warn;
    }
}
