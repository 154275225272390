












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { SimpleHttpAdapterImpl } from "~/backend/SimpleHttpAdapter/SimpleHttpAdapterImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { AuthenticationConfigResponse } from "../model/authenticationConfigResponse";

import { SsoSetupService }  from "./ssoSetup.serviceInterface";

export class SsoSetupServiceImpl implements SsoSetupService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.simpleHttpAdapter = new SimpleHttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.simpleHttpAdapter.setIsEdgeSupported();
        
    }

    









    public async getAuthenticationConfig(accountSid?: string, flexInstanceSid?: string, runtimeDomain?: string, sSOProfileSid?: string, appType?: 'SinglePageApp' | 'MobileApp'): Promise<AuthenticationConfigResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.simpleHttpAdapter.get<AuthenticationConfigResponse>(`https://services[region].twilio.com/v1/Flex/Authentication/Config?AccountSid=${encodeURIComponent(String(accountSid))}&FlexInstanceSid=${encodeURIComponent(String(flexInstanceSid))}&RuntimeDomain=${encodeURIComponent(String(runtimeDomain))}&SSOProfileSid=${encodeURIComponent(String(sSOProfileSid))}&AppType=${encodeURIComponent(String(appType))}`, undefined);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `SsoSetupService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }
}

