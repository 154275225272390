import { LogLevelNumbers, MethodFactory } from "loglevel";
import { LogEntry } from "~/modules/logger";

const toTwoDigits = (number: number) => {
    if (number < 10) {
        return `0${number}`;
    }
    return `${number}`;
};

const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = toTwoDigits(date.getMonth() + 1);
    const day = toTwoDigits(date.getDate());
    const hours = toTwoDigits(date.getHours());
    const minutes = toTwoDigits(date.getMinutes());
    const seconds = toTwoDigits(date.getSeconds());
    const miliseconds = toTwoDigits(date.getMilliseconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${miliseconds}`;
};

export const formatEntry =
    (originalFactory: MethodFactory) => (methodName: string, logLevel: LogLevelNumbers, loggerName: string) => {
        return (logEntry: LogEntry, ...messages: unknown[]): void => {
            const timestamp = formatDate(logEntry.timestamp);
            const level = logEntry.severity.toUpperCase();
            const messagePrefix = `[${timestamp}] Flex SDK → ${loggerName} (${level}): `;
            const method = originalFactory(methodName, logLevel, loggerName);
            return method(messagePrefix, ...messages);
        };
    };
