import { Task } from 'twilio-taskrouter';
import CbmParticipant, { ParticipantEventData } from '../CbmParticipant';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { post } from '../util/Request';
import { getRoute, INTERACTION_MODIFY_PARTICIPANT } from '../util/Routes';
import TwilioError from '../util/TwilioError';
import { ParticipantResponse } from './types/AddParticipant';
import getChannels from './GetChannels';
import { promiseOrTimeout } from '../util/EventsTimeout';




export type Modification = {
    


    hold: boolean;
};









async function modifyParticipantEndpoint(
    task: Task,
    participantSid: string,
    modification: Modification,
    methodName: string
): Promise<ParticipantResponse> {
    if (!participantSid) {
        throw new TypeError(`Error calling method ${methodName}(). <string>participantSid is a required parameter.`);
    }
    if (typeof task.attributes === 'undefined') {
        throw new Error("Task's attributes are not set");
    }

    const channels = await getChannels(task);
    const channelSid = channels.find((channel) => channel.type === 'voice')!.sid;
    const interactionSid = getInteractionSid(task);

    const requestURL = getRoute(INTERACTION_MODIFY_PARTICIPANT, interactionSid, channelSid, participantSid).path;
    const requestParams = {
        media_properties: modification
    };
    return post<ParticipantResponse>(requestURL, requestParams);
}









async function modifyParticipantHoldStatus(
    task: Task,
    participantSid: string,
    modification: Modification,
    methodName: string
): Promise<CbmParticipant> {
    let participant: ParticipantResponse | undefined;

    const eventReceived = new Promise((resolve: (value: CbmParticipant) => void, reject) => {
        
        const participantModifiedHandler = (_: any, eventData: ParticipantEventData) => {
            if (eventData.participant_sid === participant?.sid) {
                task.removeListener('participantModified', participantModifiedHandler);
                resolve(new CbmParticipant(eventData));
            }
        };
        task.on('participantModified', participantModifiedHandler);
        
        const participantAddFailedHandler = (_: any, eventData: any) => {
            if (eventData.participant_sid === participant?.sid) {
                task.removeListener('participantModifyFailed', participantAddFailedHandler);
                reject(new TwilioError(eventData, `Failed to modify Participant to Task sid=${task.sid}`));
            }
        };
        task.on('participantModifyFailed', participantAddFailedHandler);
    });

    participant = await modifyParticipantEndpoint(task, participantSid, modification, methodName);

    return promiseOrTimeout<CbmParticipant>(eventReceived, 'Task.participantModified');
}








export function holdParticipant(task: Task, participantSid: string): Promise<CbmParticipant> {
    return modifyParticipantHoldStatus(task, participantSid, { hold: true }, 'holdParticipant');
}








export function unHoldParticipant(task: Task, participantSid: string): Promise<CbmParticipant> {
    return modifyParticipantHoldStatus(task, participantSid, { hold: false }, 'unHoldParticipant');
}
