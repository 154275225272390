import isString from 'lodash/isString';
import Logger, { LogLevel } from './Logger';
import { buildRegionalHost } from './Routes';





export type ConfigurationOptions = {
    


    ebServer?: string;
    


    wsServer?: string;
    


    region?: string;
    


    edge?: string;
    


    logLevel?: LogLevel;
};





export default class Configuration {
    


    token: string;

    


    readonly logIdentifier: Date;

    


    readonly ebServer: string;

    


    readonly wsServer: string;

    


    readonly logLevel: LogLevel;

    


    readonly #log: Logger;

    






    constructor(token: string, options?: ConfigurationOptions) {
        if (!isString(token) || !token) {
            throw new TypeError('Failed to initialize Configuration. <string>token is a required parameter.');
        }
        this.token = token;
        this.logIdentifier = new Date();
        this.logLevel = options?.logLevel ?? LogLevel.Warn;
        this.#log = new Logger(`Configuration-${this.logIdentifier}`, this.logLevel);

        if (options?.ebServer) {
            this.ebServer = options.ebServer;
        }

        if (options?.wsServer) {
            this.wsServer = options.wsServer;
        }

        if (options?.ebServer || options?.wsServer) {
            this.#log.warn(
                '"ebServer" and "wsServer" parameter will be removed in next major version. ' +
                    'You may start using "region" and "edge".'
            );
            return;
        }

        const edgeAndRegionSlice = buildRegionalHost(options?.edge, options?.region);
        this.ebServer = `https://event-bridge${edgeAndRegionSlice}.twilio.com/v1/wschannels`;
        this.wsServer = `wss://event-bridge${edgeAndRegionSlice}.twilio.com/v1/wschannels`;
    }

    



    updateToken(newToken: string): void {
        if (!isString(newToken) || !newToken) {
            throw new TypeError(
                'To update the Twilio token, a new Twilio token must be passed in. <string>newToken is a required parameter.'
            );
        }

        this.token = newToken;
    }

    



    getLogIdentifier(): Date {
        return this.logIdentifier;
    }
}
