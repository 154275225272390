









export enum LocalStorageKeys {
    AccountConfig = "FLEX_SDK_CACHE_ACCOUNT_CONFIG",
    PublicConfig = "FLEX_SDK_CACHE_PUBLIC_CONFIG",
    FeaturesConfig = "FLEX_SDK_CACHE_FEATURES_CONFIG"
}
