












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { HttpAdapterImpl } from "~/backend/HttpAdapter/HttpAdapterImpl";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods"
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { IdpUrlResponse } from "../model/idpUrlResponse";
import { SSOLoginRequestBody } from "../model/sSOLoginRequestBody";
import { Token } from "../model/token";
import { TokenData } from "../model/tokenData";
import { TokenRefreshResult } from "../model/tokenRefreshResult";

import { FederatedAuthService }  from "./federatedAuth.serviceInterface";

export class FederatedAuthServiceImpl implements FederatedAuthService {
    private httpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.httpAdapter = new HttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.httpAdapter.setIsEdgeSupported(true);
    }

    






    public async getIdpUrl(accountSid: string, sSOLoginRequestBody: SSOLoginRequestBody): Promise<IdpUrlResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (sSOLoginRequestBody === null || sSOLoginRequestBody === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter sSOLoginRequestBody was null or undefined.");
        }

        let response;
        try {
            response = await this.httpAdapter.post<IdpUrlResponse>(`https://iam[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/authenticate`, undefined, sSOLoginRequestBody);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `FederatedAuthService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;
            }

            return Promise.reject(e)
        }
        const processedResponse = convertDateStringValuesToDate<IdpUrlResponse>(response);
        return processedResponse;
    }

    






    public async refreshToken(accountSid: string, token: Token): Promise<TokenRefreshResult> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (token === null || token === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter token was null or undefined.");
        }

        let response;
        try {
            response = await this.httpAdapter.post<TokenRefreshResult>(`https://iam[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/Tokens/refresh`, undefined, token);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `FederatedAuthService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;
            }

            return Promise.reject(e)
        }
        const processedResponse = convertDateStringValuesToDate<TokenRefreshResult>(response);
        return processedResponse;
    }

    






    public async validateToken(accountSid: string, token: Token, options?: object): Promise<TokenData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (token === null || token === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter token was null or undefined.");
        }

        let response;
        try {
            response = await this.httpAdapter.post<TokenData>(`https://iam[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/Tokens/validate`, AuthenticationMethod.Basic, token, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `FederatedAuthService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;
            }

            return Promise.reject(e)
        }
        const processedResponse = convertDateStringValuesToDate<TokenData>(response);
        return processedResponse;
    }
}

