












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { HttpAdapterImpl } from "~/backend/HttpAdapter/HttpAdapterImpl";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods"
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { FeaturesConfig } from "../model/featuresConfig";

import { FeaturesService }  from "./features.serviceInterface";

export class FeaturesServiceImpl implements FeaturesService {
    private httpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.httpAdapter = new HttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.httpAdapter.setIsEdgeSupported(true);
    }

    




    public async fetchFeatures(options?: object): Promise<FeaturesConfig> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.httpAdapter.get<FeaturesConfig>(`https://flex[region].twilio.com/api/v1/Session`, AuthenticationMethod.HostedFlex, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `FeaturesService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;
            }

            return Promise.reject(e)
        }
        const processedResponse = convertDateStringValuesToDate<FeaturesConfig>(response);
        return processedResponse;
    }
}

