import { Logger } from "~/modules/logger";
import { generateBackoffInterval, isValidNumber } from "~/utils/retry/retryUtil";

export interface RetryOptions<T> {
    functionToRetry: () => Promise<T>;

    retryCondition: (error?: object) => boolean;

    logger: Logger;

    initialDelay?: number;

    maxDelay?: number;

    maxAttempts?: number;
}

export async function retry<T>(options: RetryOptions<T>): Promise<T> {
    const logger = options.logger;
    let retryCount = 0;
    let initialDelay = 800;
    let maxDelay = 3 * 1000; 
    let maxAttempts = 3;
    if (isValidNumber(options.initialDelay)) {
        initialDelay = options.initialDelay as number;
    }
    if (isValidNumber(options.maxDelay)) {
        maxDelay = options.maxDelay as number;
    }
    if (isValidNumber(options.maxAttempts)) {
        maxAttempts = options.maxAttempts as number;
    }

    if (initialDelay > maxDelay) {
        logger.warn("Initial delay cannot be greater than Max delay, hence defaulting to Max delay");
    }
    if (maxAttempts < 1) {
        logger.warn("The value of max retry attempts has to be greater than 0, hence reset to 1");
        maxAttempts = 1;
    }

    let errorToReturn;
    do {
        try {
            if (retryCount > 0) {
                logger.debug("Retry attempt: ", retryCount);
            }
            
            return await options.functionToRetry();
        } catch (error) {
            if (!options.retryCondition(error)) {
                return Promise.reject(error);
            }
            if (retryCount >= maxAttempts - 1) {
                errorToReturn = error;
                break;
            }
        }
        const delay = generateBackoffInterval(initialDelay, maxDelay, retryCount + 1);
        logger.debug("Retry after(ms): ", delay);

        
        await new Promise((resolve) => setTimeout(resolve, delay));
    } while (retryCount++ < maxAttempts - 1);

    return Promise.reject(errorToReturn);
}
