export class TokenRegistry {
    #sessionToken: string;

    get sessionToken(): string {
        return this.#sessionToken;
    }

    set sessionToken(token) {
        this.#sessionToken = token;
    }
}
