



export enum ChannelStatus {
    Active = 'active',
    Setup = 'setup',
    Closed = 'closed',
    Failed = 'failed'
}
