import { TwilsockClient as TwilsockSdkClient } from "twilsock";
import { Result } from "twilsock/lib/client";

export { TwilsockSdkClient };

export type TwilsockOptions = {
    region?: string;
    clientMetadata: {
        type: string;
        sdk: string;
        sdkv: string;
        app?: string;
        appv?: string;
    };
};

export enum TwilsockClientEvent {
    TokenExpired = "tokenExpired",
    TokenAboutToExpire = "tokenAboutToExpire",
    StateChanged = "stateChanged",
    ConnectionError = "connectionError",
    Connected = "connected",
    Disconnected = "disconnected"
}

export interface NewableTwilsockClient {
    new (token: string, productId: string, options?: TwilsockOptions): TwilsockClient;
}

export interface TwilsockClient {
    isConnected: boolean;

    connect(): void;

    disconnect(): Promise<void>;

    updateToken(token: string): Promise<void>;

    post(url: string, headers: unknown, body: unknown, grant?: string): Promise<Result>;

    on(event: TwilsockClientEvent, listener: (...args: unknown[]) => void): TwilsockClient;

    removeListener(eventName: TwilsockClientEvent, listener: (...args: unknown[]) => void): TwilsockClient;

    emit(event: string, ...args: unknown[]): boolean;
}
