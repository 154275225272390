import mergeWith from "lodash/mergeWith";
import { assertObject } from "./assert";
import { DeepPartial } from "./DeepPartial";

export function mergeUserOptions<T>(options: T, userOptions?: DeepPartial<T>): void {
    if (typeof userOptions !== "undefined") {
        assertObject(userOptions, "userOptions");
    }

    mergeWith(options, userOptions);
}
