



export type RoutingData = {
    


    task_sid: string;
    


    worker_sid?: string;
    


    reservation_sid?: string;
};





class RoutingProperties {
    


    readonly taskSid: string;

    


    readonly workerSid?: string;

    


    readonly reservationSid?: string;

    constructor(routingData: RoutingData) {
        this.taskSid = routingData.task_sid;
        this.workerSid = routingData.worker_sid;
        this.reservationSid = routingData.reservation_sid;
    }
}

export default RoutingProperties;
