import isObject from 'lodash/isObject';
import Configuration from './Configuration';
import { DEFAULT_HTTP_TIMEOUT, API_V2 } from './Constants';

import { version } from '../../package.json';

const enum HttpMethods {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT'
}

type ApiResponse<T> = {
    payload: T;
};

const CLIENT_VERSION = version;

const defaultHeaders = {
    'Content-Type': 'application/json',
    clientVersion: CLIENT_VERSION,
    apiVersion: API_V2
};

let config: Configuration;

async function fetchWithTimeout(resource: string, options: Object) {
    let abortOptions: Object = { ...options };
    let abortTimeout;

    if (globalThis.AbortController !== undefined) {
        const controller = new globalThis.AbortController();
        abortOptions = {
            ...abortOptions,
            signal: controller.signal
        };
        abortTimeout = setTimeout(() => controller.abort(), DEFAULT_HTTP_TIMEOUT);
    }

    const response = await fetch(resource, abortOptions);

    if (abortTimeout !== undefined) {
        clearTimeout(abortTimeout);
    }

    return response;
}

async function request<T>({
    method,
    server,
    url,
    params,
    token
}: {
    method: string;
    server: string;
    url: string;
    params: Object;
    token: string;
}): Promise<T> {
    if (!config) {
        throw new Error(
            `Failed to make ${method} request. Make sure 'configureRequest' is invoked prior to making a request .`
        );
    }

    if (!url) {
        throw new Error(`Failed to make ${method} request. <string>url is a required parameter.`);
    }

    const body = JSON.stringify({
        url,
        params,
        method,
        token
    });

    const response = await fetchWithTimeout(server, {
        body,
        method: HttpMethods.Post,
        headers: defaultHeaders
    });

    if (!response.ok) {
        throw new Error(`${method} request failed: ${response.statusText}`);
    }

    const data: ApiResponse<T> = await response.json();

    return data.payload;
}

export const get = async <T>(url: string, params: Object = {}): Promise<T> =>
    request({
        url,
        params,
        method: HttpMethods.Get,
        server: config.ebServer,
        token: config.token
    });

export const post = async <T>(url: string, params: Object): Promise<T> => {
    if (!config) {
        throw new Error('Failed to make POST request. Make sure `configureRequest` is invoked prior to `post` .');
    }

    if (!isObject(params)) {
        throw new Error('Failed to make POST request. <object>params is a required parameter.');
    }

    return request({
        url,
        params,
        method: HttpMethods.Post,
        server: config.ebServer,
        token: config.token
    });
};

export function configureRequest(userConfig: Configuration) {
    if (!(userConfig instanceof Configuration)) {
        throw new TypeError('Failed to initialize Request. <Configuration>userConfig is a required parameter.');
    }

    config = userConfig;
}
