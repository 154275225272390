
import { Task, TaskRouterEventHandler, Worker } from 'twilio-taskrouter';
import { ParticipantEventData } from '../CbmParticipant';
import Logger, { LogLevel } from '../util/Logger';

const participantEventsTypeMapping: { [key: string]: string } = {
    'participant.added': 'participantAdded',
    'participant.add.failed': 'participantAddFailed',
    'participant.removed': 'participantRemoved',
    'participant.remove.failed': 'participantRemoveFailed',
    'participant.modified': 'participantModified',
    'participant.modify.failed': 'participantModifyFailed'
};





export type ParticipantEventHandlerOptions = {
    logLevel?: LogLevel;
};





export default class ParticipantEventHandler extends TaskRouterEventHandler {
    
    readonly _worker: Worker;

    readonly _logLevel: LogLevel;

    readonly _log: Logger;

    constructor(worker: Worker, options: ParticipantEventHandlerOptions = {}) {
        super(worker, options);
        this._worker = worker;
        this._logLevel = options.logLevel || LogLevel.Error;
        this._log = new Logger('ParticipantEventHandler', this._logLevel);
    }

    getTREventsToHandlerMapping() {
        const taskRouterEventsToHandlerMapping = super.getTREventsToHandlerMapping();
        taskRouterEventsToHandlerMapping['participant.added'] = '_participantEventHandler';
        taskRouterEventsToHandlerMapping['participant.add.failed'] = '_participantEventHandler';
        taskRouterEventsToHandlerMapping['participant.removed'] = '_participantEventHandler';
        taskRouterEventsToHandlerMapping['participant.remove.failed'] = '_participantEventHandler';
        taskRouterEventsToHandlerMapping['participant.modified'] = '_participantEventHandler';
        taskRouterEventsToHandlerMapping['participant.modify.failed'] = '_participantEventHandler';
        return taskRouterEventsToHandlerMapping;
    }

    _participantEventHandler(eventData: ParticipantEventData, eventType: string) {
        const mappedEventType = participantEventsTypeMapping[eventType];
        this._log.info(`Worker ${this._worker.sid} received Event: ${eventType} mapped to ${mappedEventType}.`);
        const taskSid = eventData.routing_properties && eventData.routing_properties.task_sid;
        if (taskSid) {
            this._log.debug(`Task sid ${taskSid}, proceeding to get tasks.`);
            
            const tasks: Task[] = this._worker._dataServices.reservationsEntity.getTasks(taskSid);
            if (tasks && tasks.length > 0) {
                
                tasks[0]._emitEvent(mappedEventType, eventData);
                this._log.debug(`Emitted event ${mappedEventType} with data ${JSON.stringify(eventData)}`);
            } else {
                this._log.warn(
                    `The task ${taskSid} specified by Event: participant.${mappedEventType} does not exist in 
                    Worker ${this._worker.sid} Reservations map. Skipping event with data ${JSON.stringify(eventData)}.`
                );
            }
        } else {
            this._log.error(
                `Event: participant. ${mappedEventType} did not contain a Task sid. 
              Unable to emit event for Worker ${this._worker.sid}. Data ${JSON.stringify(eventData)}`
            );
            throw new Error(`Failed to emit event for Worker ${this._worker.sid}.`);
        }
    }
}
