import { DEFAULT_EVENTS_TIMEOUT } from './Constants';

let eventsTimeout: number;

export function setEventsTimeout(timeout?: number): void {
    eventsTimeout = timeout || DEFAULT_EVENTS_TIMEOUT;
}

export function promiseOrTimeout<T>(promise: Promise<T>, event: String, timeout?: number): Promise<T> {
    
    console.debug(`Waiting for event: ${event}`);
    const timeoutPromise = new Promise<T>((resolve, reject) => {
        setTimeout(() => {
            reject(new Error(`Event not received: ${event}`));
        }, timeout || eventsTimeout);
    });

    return Promise.race<T>([promise, timeoutPromise]);
}
