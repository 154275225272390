import { Reservation, Supervisor, Task, Worker } from "twilio-taskrouter";
import type { FeaturesList, FeaturesConfig, OutboundFlows } from "~/modules/config";
import { AnalyticsInstance, EVENTS } from "~/modules/analytics/Analytics";

export function isWorkerOffline(worker: Supervisor, taskrouterOfflineActivitySid?: string): boolean {
    const currentActivitySid = worker.activity.sid;
    return taskrouterOfflineActivitySid === currentActivitySid;
}

export function isOutboundCallingEnabled(outboundCallFlows: { [key: string]: OutboundFlows } | null): boolean {
    return Boolean(outboundCallFlows && outboundCallFlows.default && outboundCallFlows.default.enabled);
}

export function getDefaultQueueSid(outboundCallFlows: { [key: string]: OutboundFlows } | null): string | null {
    return outboundCallFlows && outboundCallFlows.default && outboundCallFlows.default.queue_sid;
}

export function getDefaultWorkflowSid(outboundCallFlows: { [key: string]: OutboundFlows } | null): string | null {
    return outboundCallFlows && outboundCallFlows.default && outboundCallFlows.default.workflow_sid;
}

export function getDefaultCallerID(outboundCallFlows: { [key: string]: OutboundFlows } | null): string | null {
    return outboundCallFlows && outboundCallFlows.default && outboundCallFlows.default.caller_id;
}

export function isOutboundCallPending(): boolean {
    return false;
}

export function getReservationsByTask(worker: Worker, taskSid: string): Reservation[] {
    const reservations = worker?.reservations ? Array.from(worker.reservations.values()) : [];
    return reservations.filter((reservation) => reservation.task && reservation.task.sid === taskSid);
}

export function getCallSidOfTask(worker: Worker, taskSid: string): string | null {
    if (worker && worker.reservations) {
        
        for (const [key, reservation] of worker.reservations.entries()) {
            if (reservation.task && reservation.task.sid === taskSid) {
                return reservation.task.attributes.callSid;
            }
        }
    }

    
    return null;
}

export function isCallTask(task: Task): boolean {
    return task && task.taskChannelUniqueName === "voice";
}

export function hasVoiceTaskWithStatus(worker: Worker, status: string): boolean {
    return worker && worker.reservations
        ? Array.from(worker.reservations.values()).some(
              (reservation) => reservation.task && reservation.status === status && isCallTask(reservation.task)
          )
        : false;
}

export function getVoiceReservation(worker: Worker): Reservation | null {
    if (worker && worker.reservations) {
        
        for (const [, reservation] of worker.reservations.entries()) {
            if (reservation.task && isCallTask(reservation.task)) {
                return reservation;
            }
        }
    }

    return null;
}

export function isFeatureFlagEnabled(flag: string, featuresConfig: FeaturesConfig): boolean {
    const features: FeaturesList[] = featuresConfig?.features;
    const feature: FeaturesList | undefined = features?.find((item) => item.id === flag);
    if (feature && feature.enabled) {
        return true;
    }
    return false;
}

export function isLiveCall(reservation: Reservation) {
    return isCallTask(reservation?.task) && reservation.status === "accepted";
}

export function isOutboundCallTask(task: Task): boolean {
    return isCallTask(task) && !!task.routingTarget && task.attributes.direction === "outbound";
}

function isTaskAssignedToCurrentWorker(reservation: Reservation, worker: Worker): boolean {
    return Boolean(reservation.task && reservation.workerSid === worker.sid);
}

export function canHold(reservation: Reservation, worker: Worker): boolean {
    return isLiveCall(reservation) && isTaskAssignedToCurrentWorker(reservation, worker);
}









export const addCallContextParameter = (existingRecipient: string, newContextParameter: string, contactUri: string) => {
    let recipientWithCallContext = existingRecipient;
    if (!recipientWithCallContext) {
        recipientWithCallContext = `${contactUri}`;
    }

    const separator = recipientWithCallContext.includes("?") ? "&" : "?";

    return `${recipientWithCallContext}${separator}${newContextParameter}`;
};

export const sendTrackingEvent = (
    event: EVENTS,
    analytics: AnalyticsInstance,
    worker?: Worker,
    error?: string
): void => {
    if (!worker) {
        return;
    }
    const properties = {
        groupId: worker.accountSid,
        userId: worker.sid,
        workerSid: worker.sid,
        ...(error && { error })
    };

    analytics.track(event, properties);
};
