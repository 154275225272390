
import packageInfo from "package.json";
import { createAuthenticator } from "~/modules/auth";
import { ContextManager } from "./modules/contextManager/ContextManager";
import { getAccountConfigData } from "./modules/config/AccountConfigDataGetter/getAccountConfigData";
import { createClient as fromModuleCreateClient } from "./modules/client/ClientProvider/createClient";
import { getPublicConfig as fromModuleGetPublicConfig } from "./modules/config/PublicConfigProvider/getPublicConfig";
import { getFeaturesConfig as fromModuleGetFeaturesConfig } from "./modules/config/FeaturesConfigDataGetter/getFeaturesConfig";
import { setLogLevel as fromModuleSetLogLevel } from "~/modules/logger/LoggerFactory/LoggerConfigImpl";
import { getAuthenticationConfig as fromModuleGetAuthenticationConfig } from "~/modules/auth/AuthenticationConfigGetter/getAuthenticationConfig";

export type { SessionOptions } from "./modules/session";
export type { DeepPartial } from "./utils/DeepPartial";
export type { Without, XOR } from "./utils/utilityTypes";
export type {
    AccountConfig,
    AccountConfigProvider,
    AccountConfigKeys,
    AccountConfigData,
    IntegrationsConfig,
    OutboundFlows,
    SkillDefinition,
    QueueStatsConfiguration,
    PublicConfig,
    PublicConfigAttributes,
    PublicConfigProvider,
    AccountSidOption,
    RuntimeDomainOption,
    TaskQueue,
    WorkerChannel,
    DefaultQueueStatsConfiguration,
    QueueConfiguration,
    QueueChannelConfiguration,
    FeaturesConfigDataGetter,
    FeaturesConfig,
    FeaturesList,
    AccountConfigDataGetter,
    AccountConfigOptions,
    ChannelConfig,
    CbmAttachments,
    DebuggerIntegration,
    FlexUiStatusReport,
    CitrixVoiceVdi
} from "./modules/config";
export type { Logger, LoggerOptions, LoggerLevel, LoggerLevelNumbers } from "./modules/logger";
export type { Client, ClientProvider, ClientOptions, ClientConfigType } from "./modules/client";
export { ClientEvent } from "./modules/client";
export type {
    Authenticator,
    AuthenticatorFactory,
    IdpConfig,
    Role,
    TokenData,
    AppType,
    TokenRefreshResult,
    AuthenticationConfigParams,
    AuthenticationConfigResponse,
    AuthenticationConfig,
    LoginDetailsResponse,
    ExchangeTokenParams,
    FlexAuthToken
} from "./modules/auth";
export type { EventPublisher } from "./modules/events";
export type { TelemetryOptions } from "./modules/telemetry";
export { hasPermission } from "~/modules/auth/Permission";
export type {
    VirtualAgentDataApi,
    VirtualAgentData,
    VirtualAgentDataSentences
} from "~/modules/virtualAgentData/Definitions";

export type {
    ProfileConnectorApis as ProfileConnector,
    ProfilesResponse,
    Profile,
    ProfileAddress,
    ProfileContact,
    ProfileAttribute,
    RankedProfile,
    FindProfilesRequest,
    IdentifiersResponse,
    TraitsResponse,
    Identifier,
    Trait
} from "~/modules/profileConnector/Definitions";

export type { Actions, StartOutboundCallOptions } from "~/modules/actions/Actions";

export type { FlexCall } from "~/modules/FlexCall";

export type { DataClientOptions, GetToken, TypePolicies } from "~/modules/dataClient";

export type { TaskRouterOptions } from "./packages/taskrouter/TaskRouterOptions";

export type { VoiceOptions } from "./modules/voice/voiceOptions";

export * from "./packages/taskrouter/types";


const ctx = ContextManager.newInstance();




export const auth = createAuthenticator(ctx);



export const setLogLevel = fromModuleSetLogLevel(ctx);




export const getPublicConfig = fromModuleGetPublicConfig(ctx);




export const getAccountConfig = getAccountConfigData(ctx);




export const getAuthenticationConfig = fromModuleGetAuthenticationConfig(ctx);




export const getFeaturesConfig = fromModuleGetFeaturesConfig(ctx);




export const createClient = fromModuleCreateClient(ctx);




export const version: string = packageInfo.version;

export type { FlexSdkErrorMetadata } from "./modules/error";
export { FlexSdkError, ErrorCode, ErrorSeverity } from "./modules/error";
