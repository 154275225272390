import isString from 'lodash/isString';
import { Task } from 'twilio-taskrouter';
import CbmParticipant, { Participant, ParticipantEventData, ParticipantType } from '../CbmParticipant';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { post } from '../util/Request';
import { getRoute, INTERACTION_ADD_PARTICIPANT } from '../util/Routes';
import TwilioError from '../util/TwilioError';
import { getParticipantSidFromEvent } from '../util/ParticipantSid';
import { promiseOrTimeout } from '../util/EventsTimeout';
import {
    ParticipantResponse,
    CreateInteractionChannelParticipantResponse,
    AddEmailParticipantOptions,
    AddSmsParticipantOptions,
    AddWhatsAppParticipantOptions,
    AddWebChatParticipantOptions
} from './types/AddParticipant';
import { MediaChannelType } from './types/MediaChannelType';
import { TaskAttributes } from './types/TaskAttributes';

type AddParticipantParams = {
    type: ParticipantType;
    media_properties: {
        type: MediaChannelType;
        name?: string;
        address?: string;
        proxy_address?: string;
        identity?: string;
    };
};







async function processAddParticipantRequest(task: Task, requestParams: AddParticipantParams): Promise<CbmParticipant> {
    let expectedParticipant: ParticipantResponse | undefined;

    const eventReceived = new Promise(
        (resolve: (value: CbmParticipant) => void, reject: (value: TwilioError) => void) => {
            const participantAddedHandler = (_: unknown, event: ParticipantEventData) => {
                const eventParticipantSid = getParticipantSidFromEvent(event);
                if (eventParticipantSid === expectedParticipant?.sid) {
                    task.removeListener('participantAdded', participantAddedHandler);
                    resolve(new CbmParticipant(event));
                }
            };
            task.on('participantAdded', participantAddedHandler);

            
            const participantAddFailedHandler = (_: unknown, event: any) => {
                const eventParticipantSid = getParticipantSidFromEvent(event);
                if (eventParticipantSid === expectedParticipant?.sid) {
                    task.removeListener('participantAddFailed', participantAddFailedHandler);

                    const eventReason = event.error_data && event.error_data.error_message;
                    reject(
                        new TwilioError(
                            event,
                            `Failed to add Participant to Task sid=${task.sid}. Reason: ${eventReason}`
                        )
                    );
                }
            };
            task.on('participantAddFailed', participantAddFailedHandler);
        }
    );

    const interactionSid = getInteractionSid(task);
    const channelSid = (task.attributes as TaskAttributes).flexInteractionChannelSid;
    const requestURL = getRoute(INTERACTION_ADD_PARTICIPANT, interactionSid, channelSid!).path;
    expectedParticipant = await post<ParticipantResponse>(requestURL, requestParams);

    return promiseOrTimeout<CbmParticipant>(eventReceived, 'Task.participantAdded');
}








export async function addEmailParticipant(task: Task, options: AddEmailParticipantOptions): Promise<CbmParticipant> {
    const attributes = task.attributes as TaskAttributes;

    if (!(attributes && attributes.channelType === 'email')) {
        throw new Error('Wrong channel type - addParticipant expects the task to have an email channel');
    }

    if (!attributes || !attributes.flexInteractionChannelSid) {
        throw new TypeError(`Failed to add Participant to Task sid=${task.sid}. Missing flexInteractionChannelSid.`);
    }

    const requestParams = {
        type: ParticipantType.Customer,
        media_properties: {
            level: options.level,
            name: options.toName,
            address: options.address,
            type: MediaChannelType.Email
        }
    };

    return processAddParticipantRequest(task, requestParams);
}








export async function addSmsParticipant(task: Task, options: AddSmsParticipantOptions): Promise<CbmParticipant> {
    if (!isString(options.from) || !options.from) {
        throw new TypeError('Error calling method addSmsParticipant(). <string>from is a required parameter.');
    }

    if (!isString(options.to) || !options.to) {
        throw new TypeError('Error calling method addSmsParticipant(). <string>to is a required parameter.');
    }

    const attributes = task.attributes as TaskAttributes;

    if (!(attributes && attributes.channelType === 'sms')) {
        throw new Error('Wrong channel type - addSmsParticipant expects the task to have a sms channel');
    }

    if (!(attributes && attributes.flexInteractionChannelSid)) {
        throw new Error(`Failed to add Participant to Task sid=${task.sid}. Missing flexInteractionChannelSid.`);
    }

    const requestParams = {
        type: ParticipantType.Customer,

        media_properties: {
            
            
            
            address: options.to,
            proxy_address: options.from,
            type: MediaChannelType.Sms
        }
    };

    return processAddParticipantRequest(task, requestParams);
}








export async function addWhatsAppParticipant(
    task: Task,
    options: AddWhatsAppParticipantOptions
): Promise<CbmParticipant> {
    if (!isString(options.from) || !options.from) {
        throw new TypeError('Error calling method addWhatsAppParticipant(). <string>from is a required parameter.');
    }

    if (!isString(options.to) || !options.to) {
        throw new TypeError('Error calling method addWhatsAppParticipant(). <string>to is a required parameter.');
    }

    const attributes = task.attributes as TaskAttributes;

    if (!(attributes && attributes.channelType === 'whatsapp')) {
        throw new Error('Wrong channel type - addWhatsAppParticipant expects the task to have a whatsapp channel');
    }

    if (!(attributes && attributes.flexInteractionChannelSid)) {
        throw new Error(`Failed to addWhatsAppParticipant to Task sid=${task.sid}. Missing flexInteractionChannelSid.`);
    }

    const requestParams = {
        type: ParticipantType.Customer,
        media_properties: {
            
            address: options.to,
            proxy_address: options.from,
            type: MediaChannelType.WhatsApp
        }
    };

    return processAddParticipantRequest(task, requestParams);
}








export async function addWebChatParticipant(
    task: Task,
    options: AddWebChatParticipantOptions
): Promise<CbmParticipant> {
    if (!isString(options.identity) || !options.identity) {
        throw new TypeError(
            'Error calling method addWebChatParticipant(). <string>participantIdentity is a required parameter.'
        );
    }

    const attributes = task.attributes as TaskAttributes;

    if (!(attributes && attributes.channelType === 'web')) {
        throw new Error('Wrong channel type - addWebChatParticipant expects the task to have a web channel');
    }

    if (!(attributes && attributes.flexInteractionChannelSid)) {
        throw new Error(`Failed to addWebChatParticipant to Task sid=${task.sid}. Missing flexInteractionChannelSid.`);
    }

    const requestParams = {
        type: ParticipantType.Customer,

        media_properties: {
            identity: options.identity,
            type: MediaChannelType.Web
        }
    };

    return processAddParticipantRequest(task, requestParams);
}











export async function addInteractionChannelParticipant(
    interactionSid: string,
    interactionChannelSid: string,
    participantType: ParticipantType,
    identity?: string
): Promise<Participant> {
    const requestParams = {
        type: participantType,
        media_properties: {
            ...(identity && { identity })
        }
    };

    const requestURL = getRoute(INTERACTION_ADD_PARTICIPANT, interactionSid, interactionChannelSid).path;
    const response = await post<CreateInteractionChannelParticipantResponse>(requestURL, requestParams);

    const participant: Participant = {
        sid: response.sid,
        type: response.type,
        channelSid: response.channel_sid,
        mediaProperties: response.media_properties || null
    };

    return participant;
}
