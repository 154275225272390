import { ContextManager } from "~/modules/contextManager/ContextManager";
import {
    AuthenticationConfig,
    AuthenticationConfigParams,
    AuthenticationConfigResponse
} from "~/modules/auth/Authenticator/Authenticator";
import { assertDefined, assertObject } from "~/utils/assert";
import type { AuthenticationConfigResponse as AuthenticationConfigHTTPResponse } from "~/backend/generated/SSOSetup/model/authenticationConfigResponse";
import { SsoSetupServiceImpl } from "~/backend/generated/SSOSetup/api/ssoSetup.service";
import { mapRootKeysToCamelCase } from "~/utils/mapKeys";
import { getEnvironmentConfig } from "~/modules/config";
import { getRuntimeDomainName } from "~/modules/auth/Authenticator/AuthenticatorHelper";

export const getAuthenticationConfig =
    (ctx: ContextManager) =>
    async (config: AuthenticationConfigParams): Promise<AuthenticationConfigResponse> => {
        assertDefined<AuthenticationConfigParams>(config, "config");
        assertObject<AuthenticationConfigParams>(config, "config");
        const ssoSetupService = ctx.getInstanceOf(SsoSetupServiceImpl);

        const {
            accountSid = "",
            instanceSid = "",
            runtimeDomain = "",
            ssoProfileSid = "",
            appType = "SinglePageApp"
        } = config;

        let runtimeDomainUrl = runtimeDomain;
        if (runtimeDomain) {
            const environmentConfig = getEnvironmentConfig();
            const { region } = environmentConfig || {};
            runtimeDomainUrl = getRuntimeDomainName(runtimeDomain, region);
        }

        const response: AuthenticationConfigHTTPResponse = await ssoSetupService.getAuthenticationConfig(
            accountSid,
            instanceSid,
            runtimeDomainUrl,
            ssoProfileSid,
            appType
        );
        const configList = response?.config_list || [];
        return {
            configList: configList.map((item) => mapRootKeysToCamelCase(item) as AuthenticationConfig)
        };
    };
