import { AccountConfigData } from "~/modules/config";
import { ConfigurationServiceImpl } from "~/backend/generated/Configuration/api/configuration.service";
import { ContextManager } from "~/modules/contextManager/ContextManager";

export const updateAccountConfig =
    (ctx: ContextManager) =>
    async (accountSid: string, config: Partial<AccountConfigData>): Promise<AccountConfigData> => {
        const configurationService = ctx.getInstanceOf(ConfigurationServiceImpl);
        const updatedConfiguration = await configurationService.updateConfiguration({ accountSid, ...config });
        return updatedConfiguration;
    };
