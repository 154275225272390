import { AccountConfigData } from "~/backend/generated/Configuration/model/accountConfigData";
import { accountConfigProvider, AccountConfigProvider } from "../AccountConfigProvider/AccountConfigProvider";
import { AccountConfigOptions } from "../AccountConfigOptions/AccountConfigOptions";
import { AccountConfigDataContainer } from "../AccountConfig/AccountConfigImpl/AccountConfigDataContainer/AccountConfigDataContainer";
import { ContextManager } from "~/modules/contextManager/ContextManager";

export const getAccountConfigData =
    (ctx: ContextManager) =>
    async (sessionToken: string, options?: AccountConfigOptions): Promise<AccountConfigData> => {
        const configProvider: AccountConfigProvider = accountConfigProvider(ctx);
        if (options?.fetchPolicy === "no-cache") {
            return configProvider(sessionToken, options);
        }

        const dataContainer = ctx.getInstanceOf(AccountConfigDataContainer);

        try {
            return dataContainer.get();
        } catch (e) {
            return configProvider(sessionToken, options);
        }
    };
