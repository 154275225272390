import { ContextManager } from "~/modules/contextManager/ContextManager";
import { TelemetryClient, TelemetryEventGroup } from "~/modules/telemetry";
import { TelemetryEventGroupImpl } from "~/modules/telemetry/TelemetryEventGroup/TelemetryEventGroupImpl/TelemetryEventGroupImpl";

export function createEventGroup<T>(ctx: ContextManager) {
    return (telemetryClient: TelemetryClient<object>, name?: string): TelemetryEventGroup<T> => {
        const eventGroup = new TelemetryEventGroupImpl(ctx);
        eventGroup.name = name;
        eventGroup.telemetryClient = telemetryClient;
        return eventGroup;
    };
}
