import { AccountConfig, AccountConfigData } from "~/modules/config";
import { assertKeyInObject } from "~/utils/assert";
import { AccountConfigUpdater } from "~/modules/config/AccountConfig/AccountConfigUpdater";
import { ConfigDataContainer } from "~/modules/config/AccountConfig/ConfigDataContainer";
import { AccountConfigDataContainer } from "../AccountConfigDataContainer/AccountConfigDataContainer";
import { updateAccountConfig } from "../AccountConfigUpdate/updateAccountConfig";
import { ContextManager } from "~/modules/contextManager/ContextManager";

export class AccountConfigStore implements AccountConfig {
    readonly #dataContainer: ConfigDataContainer<AccountConfigData>;

    readonly #accountConfigUpdater: AccountConfigUpdater;

    #currentUpdateConfigPromise: Promise<AccountConfigData> | undefined;

    constructor(ctx: ContextManager) {
        this.#dataContainer = ctx.getInstanceOf(AccountConfigDataContainer);
        this.#accountConfigUpdater = updateAccountConfig(ctx);
    }

    async set(config: Partial<AccountConfigData>): Promise<void> {
        const data = this.#dataContainer.get();

        
        const { accountSid, ...dataWithoutAccountSid } = data; 
        Object.keys(config).map((key: string) => assertKeyInObject(key, dataWithoutAccountSid));

        
        const me = this;
        const updatedConfigData = await this.updateInCallOrder(() => me.#accountConfigUpdater(accountSid, config));
        this.#dataContainer.set(updatedConfigData);

        return Promise.resolve();
    }

    updateInCallOrder(updateConfigCall: () => Promise<AccountConfigData>): Promise<AccountConfigData> {
        if (this.#currentUpdateConfigPromise) {
            
            
            this.#currentUpdateConfigPromise = this.#currentUpdateConfigPromise.then(() => {
                return updateConfigCall();
            });
        } else {
            this.#currentUpdateConfigPromise = updateConfigCall();
        }

        return this.#currentUpdateConfigPromise;
    }
}
