import { Worker } from 'twilio-taskrouter';
import { getRoute, CREATE_INTERACTION } from '../util/Routes';
import { post } from '../util/Request';
import { CreateEmailTaskOptions, CreateCbmTaskParams, CreateCbmTaskResponse } from './types/CreateTask';
import { MediaChannelType } from './types/MediaChannelType';











export default async function createEmailTask(
    worker: Worker,
    workflowSid: string,
    taskQueueSid: string,
    options: CreateEmailTaskOptions
): Promise<CreateCbmTaskResponse> {
    if (!options.to) {
        throw new TypeError('Error calling method createTask(). <string>to is a required parameter.');
    }

    if (!options.from) {
        throw new TypeError('Error calling method createTask(). <string>from is a required parameter.');
    }

    if (!workflowSid) {
        throw new TypeError('Error calling method createTask(). <string>workflowSid is a required parameter.');
    }

    if (!taskQueueSid) {
        throw new TypeError('Error calling method createTask(). <string>taskQueueSid is a required parameter.');
    }

    const requestURL = getRoute(CREATE_INTERACTION).path;

    const requestParams: CreateCbmTaskParams = {
        channel: {
            type: MediaChannelType.Email,
            initiated_by: 'agent',
            properties: {
                type: MediaChannelType.Email,
                direction: 'outbound',
                from_name: options.fromName || '',
                from: options.from
            },
            participants: [
                {
                    level: 'to',
                    name: options.toName || '',
                    address: options.to,
                    type: MediaChannelType.Email
                }
            ]
        },
        routing: {
            type: 'TaskRouter',
            properties: {
                workspace_sid: worker.workspaceSid,
                workflow_sid: workflowSid,
                task_channel_unique_name: 'email',
                queue_sid: taskQueueSid,
                worker_sid: worker.sid,
                attributes: {}
            }
        }
    };

    
    const { taskChannelSid, taskUniqueChannelName } = options;
    requestParams.taskChannelSid = taskChannelSid;
    requestParams.taskUniqueChannelName = taskUniqueChannelName;
    requestParams.routing.properties.attributes = options.attributes ?? {};

    
    
    const response = await post<any>(requestURL, requestParams as Object);
    return {
        interactionSid: response.sid,
        taskSid: response.routing.properties.sid
    };
}
