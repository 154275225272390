import { post } from '../util/Request';
import { getRoute, INTERACTION_CHANNEL } from '../util/Routes';
import { UpdateInteractionChannelResponse } from './types/UpdateInteractionChannel';









export async function updateInteractionChannel(
    interactionSid: string,
    interactionChannelSid: string,
    status: string
): Promise<UpdateInteractionChannelResponse> {
    const requestURL = getRoute(INTERACTION_CHANNEL, interactionSid, interactionChannelSid).path;
    const requestParams = { status };

    return post<UpdateInteractionChannelResponse>(requestURL, requestParams);
}








export async function closeInteractionChannel(
    interactionSid: string,
    interactionChannelSid: string
): Promise<UpdateInteractionChannelResponse> {
    return updateInteractionChannel(interactionSid, interactionChannelSid, 'close');
}
