import { RESUME_CHANNEL, getRoute } from '../util/Routes';
import { post } from '../util/Request';
import { ResumeChannelResponse } from './types/ResumeChannel';








export default async function resumeChannel(
    interactionSid: string,
    channelSid: string,
    taskStatus: string
): Promise<ResumeChannelResponse> {
    if (!interactionSid) {
        throw new TypeError('Error calling method resumeChannel(). <string>interactionSid is a required parameter.');
    }
    if (!channelSid) {
        throw new TypeError('Error calling method resumeChannel(). <string>channelSid is a required parameter.');
    }
    if (!taskStatus) {
        throw new TypeError('Error calling method resumeChannel(). <string>taskStatus is a required parameter.');
    }
    const requestURL = getRoute(RESUME_CHANNEL, interactionSid, channelSid).path;

    return post<ResumeChannelResponse>(requestURL, { status: taskStatus });
}
