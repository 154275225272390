












import { ErrorCode, ErrorSeverity, ThrowErrorFunction } from "~/modules/error";
import { TwilsockAdapter} from "~/backend/TwilsockAdapter/TwilsockAdapter"
import { TwilsockResult } from "~/modules/websocket";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { TwilsockAdapterImpl } from "~/backend/TwilsockAdapter/TwilsockAdapterImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { Events } from "../model/events";
import { ProcessingStats } from "../model/processingStats";

import { TelemetryService }  from "./telemetry.serviceInterface";

export class TelemetryServiceImpl implements TelemetryService {
    private basePath: string = 'http://flex-sdk.twilio.com/v1';
    
    private twilsockAdapter: TwilsockAdapter;
    
    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.twilsockAdapter = ctx.getInstanceOf(TwilsockAdapterImpl);
        this.#throwError = throwFlexSdkError(ctx);
    }

    




    public async postTelemetryEvents(events: Events): Promise<TwilsockResult<ProcessingStats>> {
        if (events === null || events === undefined) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            this.#throwError(ErrorCode.InvalidParams, metadata, 'Required parameter events was null or undefined when calling postTelemetryEvents.');
        }

        const response = await this.twilsockAdapter.post<ProcessingStats>(`${this.basePath}/Telemetry/Events`, events);
        return response;
    }

}
