import { Reservation } from 'twilio-taskrouter';
import { getRoute, INTERACTION_CHANNEL } from '../util/Routes';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { post } from '../util/Request';
import { promiseOrTimeout } from '../util/EventsTimeout';
import { TaskAttributes } from './types/TaskAttributes';








export default async function wrapReservation(reservation: Reservation): Promise<Reservation> {
    const wrappedReservationPromise = new Promise<Reservation>((resolve) => {
        reservation.once('wrapup', resolve);
    });

    const attributes = reservation.task.attributes as TaskAttributes;

    if (attributes && attributes.flexInteractionChannelSid) {
        const interactionSid = getInteractionSid(reservation.task);
        const channelSid = attributes.flexInteractionChannelSid;

        const requestURL = getRoute(INTERACTION_CHANNEL, interactionSid, channelSid!).path;
        const requestParams = {
            status: 'closed',
            routing: {
                status: 'wrapup'
            }
        };

        await post(requestURL, requestParams);
    } else {
        await reservation.wrap();
    }

    return promiseOrTimeout<Reservation>(wrappedReservationPromise, 'Reservation.wrapup');
}
