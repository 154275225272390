import { ConfigurationServiceImpl } from "~/backend/generated/Configuration/api/configuration.service";
import { PublicConfigResponse } from "~/backend/generated/Configuration/model/publicConfigResponse";
import { AccountSidOption, PublicConfig, RuntimeDomainOption } from "~/modules/config";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { ErrorCode, ErrorSeverity } from "~/modules/error";
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { throwAndReportFlexSdkError } from "~/modules/error/ThrowError/ThrowAndReportErrorHelper";
import { getLogger, LoggerName } from "~/modules/logger";
import { LocalStorageKeys } from "~/modules/storage";
import { LocalStorageImpl } from "~/modules/storage/LocalStorage/LocalStorageImpl";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { mapRootKeysToCamelCase } from "~/utils/mapKeys";
import { XOR } from "~/utils/utilityTypes";

const TWILIO_DOMAIN = ".twil.io";
const RUNTIME_DOMAIN_REGEXP = /^([a-z]+-[a-z]+-\d+)$/;

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

function sanitizeRuntimeDomain(domain: string): string {
    let sanitizedDomain = domain.trim();

    if (sanitizedDomain.endsWith("/")) {
        sanitizedDomain = sanitizedDomain.replace(/\/$/, "");
    }

    if (!sanitizedDomain.endsWith(TWILIO_DOMAIN)) {
        const parsedRuntimeDomain = sanitizedDomain.match(RUNTIME_DOMAIN_REGEXP);
        if (parsedRuntimeDomain && parsedRuntimeDomain[1]) {
            sanitizedDomain += TWILIO_DOMAIN;
        }
    }

    return sanitizedDomain;
}

function isAccountSidOption(option: XOR<AccountSidOption, RuntimeDomainOption>): option is AccountSidOption {
    return !!(option as AccountSidOption).accountSid;
}

function getPublicConfigQueryParam(option: XOR<AccountSidOption, RuntimeDomainOption>): {
    paramName: "AccountSid" | "RuntimeDomain";
    paramValue: string;
} {
    if (isAccountSidOption(option)) {
        const accountSid = (option as AccountSidOption).accountSid;
        return { paramName: "AccountSid", paramValue: accountSid };
    }

    const runtimeDomain = (option as RuntimeDomainOption).runtimeDomain;
    const sanitizedDomain = sanitizeRuntimeDomain(runtimeDomain);
    return { paramName: "RuntimeDomain", paramValue: sanitizedDomain };
}

export const getPublicConfig =
    (ctx: ContextManager) =>
    async (option: XOR<AccountSidOption, RuntimeDomainOption>): Promise<PublicConfig> => {
        const throwError = throwFlexSdkError(ctx);
        const configurationService = ctx.getInstanceOf(ConfigurationServiceImpl);
        const throwAndReportError = throwAndReportFlexSdkError(ctx);
        const storage = ctx.getInstanceOf(LocalStorageImpl);
        const logger = getLogger(ctx)(LoggerName.Config);
        const queryParam = getPublicConfigQueryParam(option);
        let responseData: PublicConfigResponse;

        try {
            responseData = await configurationService.fetchPublicConfiguration(
                queryParam.paramName,
                queryParam.paramValue
            );
            storage.setCachedItem(LocalStorageKeys.PublicConfig, responseData);
        } catch (err) {
            const cachedConfig = storage.getCachedItem<PublicConfigResponse>(LocalStorageKeys.PublicConfig);
            const foundInCache = cachedConfig?.configurations?.some(
                (config) =>
                    config.accountSid === queryParam.paramValue || config.runtimeDomain === queryParam.paramValue
            );

            if (foundInCache) {
                logger.warn(`Failed to fetch public configuration: ${err}. Using cache instead`);
                responseData = cachedConfig!;
            } else {
                const message = `Failed to fetch public configuration: ${err}. No cache found.`;
                const errorCode = err.code || ErrorCode.Unknown;
                return throwAndReportError(
                    errorCode,
                    { ...metadata, severity: ErrorSeverity.Error },
                    message
                ) as unknown as Promise<never>;
            }
        }

        if (!(responseData && responseData.configurations && responseData.configurations.length)) {
            throwError(
                ErrorCode.SDK,
                { ...metadata, severity: ErrorSeverity.Error },
                "Invalid response from public configuration endpoint"
            );
        }

        return mapRootKeysToCamelCase(responseData.configurations[0]) as PublicConfig;
    };
