












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { SimpleHttpAdapterImpl } from "~/backend/SimpleHttpAdapter/SimpleHttpAdapterImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { IdentifiersResponse } from "../model/identifiersResponse";
import { Profile } from "../model/profile";
import { ProfilesResponse } from "../model/profilesResponse";
import { TraitsResponse } from "../model/traitsResponse";

import { ProfileService }  from "./profile.serviceInterface";

export class ProfileServiceImpl implements ProfileService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.simpleHttpAdapter = new SimpleHttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.simpleHttpAdapter.setIsEdgeSupported(false);
        
    }

    





    public async findProfileByIdentifiers(body: string, options?: object): Promise<ProfilesResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (body === null || body === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter body was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.post<ProfilesResponse>(`https://preview[region].twilio.com/ProfileConnector/Profiles/Find`, AuthenticationMethod.Basic, body, options, "application/x-www-form-urlencoded");
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ProfileService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    






    public async findProfileByUniqueId(profileConnectSidPathParam: string, uniqueNameQueryParam?: string, options?: object): Promise<Profile> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (profileConnectSidPathParam === null || profileConnectSidPathParam === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter profileConnectSidPathParam was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.get<Profile>(`https://preview[region].twilio.com/ProfileConnector/Profiles/${encodeURIComponent(String(profileConnectSidPathParam))}?UniqueName=${encodeURIComponent(String(uniqueNameQueryParam))}`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ProfileService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    





    public async getIdentifiers(uniqueNameQueryParam?: string, options?: object): Promise<IdentifiersResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.simpleHttpAdapter.get<IdentifiersResponse>(`https://preview[region].twilio.com/ProfileConnector/Identifiers?UniqueName=${encodeURIComponent(String(uniqueNameQueryParam))}`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ProfileService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    





    public async getTraits(uniqueNameQueryParam?: string, options?: object): Promise<TraitsResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.simpleHttpAdapter.get<TraitsResponse>(`https://preview[region].twilio.com/ProfileConnector/Traits?UniqueName=${encodeURIComponent(String(uniqueNameQueryParam))}`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ProfileService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }
}

