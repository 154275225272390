








export function getParticipantSidFromEvent(event: any): string | undefined {
    return event.participant_sid;
}
