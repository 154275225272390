import { Task } from 'twilio-taskrouter';
import { getInteractionSid } from '../util/FlexInteractionSid';
import { post } from '../util/Request';
import { getRoute, INTERACTION_CHANNEL } from '../util/Routes';
import getChannels from './GetChannels';
import { promiseOrTimeout } from '../util/EventsTimeout';





export type EndConferenceResponse = {
    


    sid: string;
    


    status: string;
    


    media_sid?: string;
};







export default async function endConference(task: Task): Promise<EndConferenceResponse> {
    const channels = await getChannels(task);
    const voiceChannel = channels.find((channel) => channel.type === 'voice');

    if (!voiceChannel) {
        throw new TypeError(
            `Failed to close the conference in a channel in Task sid=${task.sid}. ` +
                `The task is expected to have a voice channel.`
        );
    }

    const interactionSid = getInteractionSid(task);
    const requestURL = getRoute(INTERACTION_CHANNEL, interactionSid, voiceChannel.sid).path;
    const requestParams = { status: 'closed' };

    let response: EndConferenceResponse;

    const eventReceived = new Promise((resolve: (value: EndConferenceResponse) => void) => {
        task.once('wrapup', () => {
            resolve(response);
        });
    });

    response = await post<EndConferenceResponse>(requestURL, requestParams);

    return promiseOrTimeout<EndConferenceResponse>(eventReceived, 'Task.wrapup');
}
