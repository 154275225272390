import { Emitter } from "~/modules/events/EventPublisher/Emitter";




export interface EventPublisher<E extends string | symbol, F = (...args: unknown[]) => void> {
    addListener(eventName: E, listener: F): this;
    removeListener(eventName: E, listener: F): this;
}

export function proxyEvent<T extends string | symbol, U extends string | symbol>(
    from: EventPublisher<T>,
    to: Emitter,
    event: T,
    alias: U
) {
    from.addListener(event, (...args: unknown[]) => {
        to.emit(alias, ...args);
    });
}
