import { makeExecutableSchema } from "@graphql-tools/schema";
import type { IResolvers, TypeSource } from "@graphql-tools/utils";
import { GraphQLSchema } from "graphql";
import { DataClientError } from "../error";

export interface LocalSchemaProps {
    resolvers: IResolvers | Array<IResolvers>;
    typeDefs: TypeSource;
}

export const fetchLocalSchema = ({ typeDefs, resolvers }: LocalSchemaProps): { schema: GraphQLSchema } | null => {
    try {
        if (Array.isArray(typeDefs) && !typeDefs.length && Array.isArray(resolvers) && !resolvers.length) {
            return null;
        }

        const localSchema = makeExecutableSchema({
            typeDefs,
            resolvers
        });

        return localSchema
            ? {
                  schema: localSchema
              }
            : null;
    } catch (error) {
        throw new Error(DataClientError.GraphqlLocalSchemaFailed);
    }
};
