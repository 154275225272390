import sha256 from "crypto-js/sha256";
import { Buffer } from "buffer";
import { SSOLoginRequestBody } from "~/backend/generated/FederatedAuth/model/sSOLoginRequestBody";
import { IdpConfig } from "~/modules/auth";
import { getEnvironmentSuffix } from "~/utils/regionUtil";

interface PrivateKeys {
    codeChallenge: string;
    codeVerifier: string;
    nonce: string;
    state: string;
}






function sanitizeSSOParams(s: string): string {
    return s.replace(/((Token|Expiration|Identity|Roles)=[^&]+[&]?)/g, "").replace(/[&?]$/, "");
}

export function getSSOLoginRequestBody(config: IdpConfig): SSOLoginRequestBody {
    return {
        products: ["flex"],
        resource: sanitizeSSOParams(config.redirectUrl)
    };
}

function urlEncodeB64(input: string) {
    return input.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

function generateState(connectionName: string, redirectUrl: string): string {
    const state = {
        connection_name: connectionName,
        redirect_url: redirectUrl
    };
    return urlEncodeB64(btoa(JSON.stringify(state)));
}

function getRandomBase64Code(bitLength: number): string {
    const array = crypto.getRandomValues(new Uint8Array(bitLength));
    const randomString = String.fromCharCode.apply(null, Array.from(array));
    const base64 = btoa(randomString);
    return urlEncodeB64(base64);
}

function generateCodeVerifier(): string {
    return getRandomBase64Code(48);
}

async function generateChallenge(verifier: string): Promise<string> {
    const hash = await sha256(verifier).toString();

    return urlEncodeB64(Buffer.from(hash, "hex").toString("base64"));
}

function generateNonce(): string {
    return getRandomBase64Code(24);
}







export async function generateProofKey(connection: string, redirectUrl: string): Promise<PrivateKeys> {
    const codeVerifier = await generateCodeVerifier();
    const codeChallenge = await generateChallenge(codeVerifier);
    const state = generateState(connection, redirectUrl);
    const nonce = generateNonce();

    return {
        codeVerifier,
        codeChallenge,
        nonce,
        state
    };
}

export const getRuntimeDomainName = (runtimeDomain: string, region?: string): string => {
    const environmentSubDomain = getEnvironmentSuffix(region);
    return `${runtimeDomain}${environmentSubDomain}.twil.io`;
};
