import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { DataClient } from "~/modules/dataClient/DataClient";
import { Logger, getLogger, LoggerName } from "~/modules/logger";
import { Session } from "~/modules/session";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import { createDataClient } from "~/packages/data-client";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";
import { getEnvironmentConfig } from "~/modules/config";

export class DataClientImpl implements DataClient {
    readonly #logger: Logger;

    readonly #session: Session;

    readonly #clientOptions: ClientOptionsStore;

    client: ApolloClient<NormalizedCacheObject> | null;

    constructor(ctx: ContextManager) {
        this.#session = ctx.getInstanceOf(SessionImpl);
        this.#logger = getLogger(ctx)(LoggerName.DataClient);
        this.#clientOptions = ctx.getInstanceOf(ClientOptionsStore);
        this.client = null;
    }

    async init(): Promise<ApolloClient<NormalizedCacheObject> | null> {
        this.#logger.debug("Initializing dataClient");

        const getTokenFromSession = () => {
            return this.#session.token;
        };

        if (this.client) {
            return this.client;
        }

        const environmentConfig = getEnvironmentConfig();
        const { region, regionNonFlex } = environmentConfig || {};
        const { dataClientOptions } = this.#clientOptions;
        const configRegion = regionNonFlex || this.#clientOptions.regionNonFlex || region || this.#clientOptions.region;

        this.client = await createDataClient(getTokenFromSession, { ...dataClientOptions, region: configRegion })
            .then((client) => {
                this.#logger.debug("Initialized dataClient successfully");
                return client;
            })
            .catch((err) => {
                this.#logger.error("Failed to initialize dataClient", err);
                return null;
            });
        return this.client;
    }

    destroy(): void {
        if (this.client) {
            
            this.client.stop();
        }

        this.client = null;
    }
}
