
import log, { LogLevelDesc } from 'loglevel';

const Errors = require('./Constants').twilioErrors;

const logLevels = ['trace', 'debug', 'info', 'warn', 'error', 'silent'];





export enum LogLevel {
    Trace = 'trace',
    Debug = 'debug',
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
    Silent = 'silent'
}





export default class Logger {
    


    readonly #log: log.Logger;

    



    constructor(moduleName: string, logLevel?: LogLevel) {
        if (!moduleName) {
            throw Errors.INVALID_ARGUMENT.clone(
                'Error instantiating Logger. <string>moduleName is a required parameter.'
            );
        }

        this.#log = log.getLogger(moduleName);
        
        
        
        

        if (logLevel) {
            this.setLevel(logLevel);
        }
    }

    trace(...args: any[]) {
        this.#log.trace(...args);
    }

    debug(...args: any[]) {
        this.#log.debug(...args);
    }

    info(...args: any[]) {
        this.#log.info(...args);
    }

    warn(...args: any[]) {
        this.#log.warn(...args);
    }

    error(...args: any[]) {
        this.#log.error(...args);
    }

    setLevel(level: string) {
        if (logLevels.indexOf(level) === -1) {
            throw Errors.INVALID_ARGUMENT.clone(
                "Error setting Logger level. <string>level must be one of ['trace', 'debug', 'info', 'warn', 'error', 'silent']"
            );
        }

        this.#log.setLevel(level as LogLevelDesc, false);
        this.#log.setDefaultLevel(level as LogLevelDesc);
    }

    
    getLogger() {
        return this.#log;
    }

    getLevel() {
        return logLevels[this.#log.getLevel()];
    }
}
