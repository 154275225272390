export type EventProperties = Record<string, any>;

export interface AnalyticsInstance {
    track: (eventName: string, additionalValues?: EventProperties) => void;
}

export enum EVENTS {
    AcceptTaskCompleted = "AcceptTask Completed",
    RejectTaskCompleted = "RejectTask Completed",
    SetAttributesCompleted = "SetAttributes Completed",
    SetCurrentActivityCompleted = "SetCurrentActivity Completed",
    WrapupTaskCompleted = "WrapupTask Completed",
    CompleteTaskCompleted = "CompleteTask Completed",
    SetTaskAttributesCompleted = "SetTaskAttributes Completed",
    TransferTaskCompleted = "TransferTask Completed",
    CancelTaskTransfer = "CancelTaskTransfer Completed",
    KickParticipantCompleted = "KickParticipant Completed",
    EndTaskCompleted = "EndTask Completed",
    HoldParticipantCompleted = "HoldParticipant Completed",
    UnholdParticipantCompleted = "UnholdParticipant Completed",
    SetWorkerActivityCompleted = "SetWorkerActivity Completed",
    SetWorkerAttributesCompleted = "SetWorkerAttributes Completed",
    StartOutboundCallCompleted = "StartOutboundCall Completed",
    UpdateTokenCompleted = "UpdateToken Completed",
    ClientRolesCompleted = "client.roles Completed",
    ClientTokenCompleted = "client.token Completed"
}
