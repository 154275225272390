import { Analytics } from "@segment/analytics-node";
import packageInfo from "package.json";
import { AnalyticsInstance, EventProperties } from "~/modules/analytics/Analytics";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";
import { getEnvironmentConfig } from "~/modules/config";
import { getLogger, LoggerName, Logger } from "~/modules/logger";
import { getOrigin } from "~/utils/getOrigin";

export class AnalyticsImpl implements AnalyticsInstance {
    private analytics: Analytics;

    private commonProperties: EventProperties;

    private readonly clientOptions: ClientOptionsStore;

    private readonly logger: Logger;

    constructor(ctx: ContextManager) {
        this.clientOptions = ctx.getInstanceOf(ClientOptionsStore);
        this.logger = getLogger(ctx)(LoggerName.Analytics);
    }

    public init(writeKey: string): void {
        this.analytics = new Analytics({ writeKey });

        const environmentConfig = getEnvironmentConfig();
        const { region } = environmentConfig || {};
        const configRegion = region || this.clientOptions.region || "us1";
        const origin = getOrigin();
        const flexUiVersion = origin === "flexui" && this.clientOptions.appVersion;

        this.commonProperties = {
            product: "Flex",
            source: "flexsdk",
            realm: configRegion,
            flexSdkVersion: packageInfo.version,
            origin,
            ...(flexUiVersion && { flexUiVersion })
        };
    }

    public track = (eventName: string, eventProperties?: EventProperties): void => {
        if (!this.analytics) {
            this.logger.warn("Analytics is not initialized");
            return;
        }

        try {
            this.analytics.track({
                userId: eventProperties?.userId,
                anonymousId: eventProperties?.anonymousId,
                event: eventName,
                properties: { ...this.commonProperties, ...eventProperties }
            });
        } catch (err) {
            this.logger.warn(err);
        }
    };
}
