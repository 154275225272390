import { PAUSED_CHANNELS, getRoute } from '../util/Routes';
import { get } from '../util/Request';
import { PausedChannelsResponse } from './types/PauseChannel';






export default async function getPausedChannels(pageSize: number): Promise<PausedChannelsResponse> {
    const requestURL = getRoute(PAUSED_CHANNELS).path;
    const param = {
        status: 'pause',
        pageSize: pageSize || 50
    };
    return get<PausedChannelsResponse>(requestURL, param);
}
