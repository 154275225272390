import path from 'path';

const Errors = require('./Constants').twilioErrors;

export const CREATE_INTERACTION = 'createInteraction';
export const INTERACTION_INSTANCE = 'interactionInstance';
export const INTERACTION_CHANNEL_INVITE_ACCEPT = 'interactionChannelInviteAccept';
export const INTERACTION_CHANNEL_INVITE_DECLINE = 'interactionChannelInviteDecline';
export const INTERACTION_ADD_PARTICIPANT = 'interactionAddParticipant';
export const INTERACTION_MODIFY_PARTICIPANT = 'interactionModifyParticipant';
export const CREATE_INTERACTION_CHANNEL = 'createInteractionChannel';
export const INTERACTION_CHANNEL = 'interactionChannel';
export const INTERACTION_GET_CHANNELS = 'interactionGetChannels';
export const INTERACTION_GET_CHANNEL_PARTICIPANTS = 'interactionGetChannelParticipants';
export const TRANSFER_CHANNEL = 'transferChannel';
export const LEAVE_CHANNEL = 'leaveChannel';
export const PAUSE_CHANNEL = 'pauseChannel';
export const RESUME_CHANNEL = 'resumeChannel';
export const PAUSED_CHANNELS = 'pausedChannels';

export type Route = {
    path: string;
};

export type InteractionsEndpoint =
    | typeof CREATE_INTERACTION
    | typeof INTERACTION_INSTANCE
    | typeof INTERACTION_CHANNEL_INVITE_ACCEPT
    | typeof INTERACTION_CHANNEL_INVITE_DECLINE
    | typeof INTERACTION_ADD_PARTICIPANT
    | typeof INTERACTION_MODIFY_PARTICIPANT
    | typeof CREATE_INTERACTION_CHANNEL
    | typeof INTERACTION_CHANNEL
    | typeof INTERACTION_GET_CHANNELS
    | typeof INTERACTION_GET_CHANNEL_PARTICIPANTS
    | typeof TRANSFER_CHANNEL
    | typeof LEAVE_CHANNEL
    | typeof PAUSE_CHANNEL
    | typeof RESUME_CHANNEL
    | typeof PAUSED_CHANNELS;

const routes = {
    [CREATE_INTERACTION]: {
        path: path.join('v1/Interactions')
    },
    [INTERACTION_INSTANCE]: {
        path: path.join('v1', 'Interactions', '%s')
    },
    [INTERACTION_CHANNEL_INVITE_ACCEPT]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s', 'Invites', '%s')
    },
    [INTERACTION_CHANNEL_INVITE_DECLINE]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s', 'Invites', '%s')
    },
    [INTERACTION_GET_CHANNELS]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels')
    },
    [CREATE_INTERACTION_CHANNEL]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels')
    },
    [INTERACTION_CHANNEL]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s')
    },
    [INTERACTION_GET_CHANNEL_PARTICIPANTS]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s', 'Participants')
    },
    [INTERACTION_ADD_PARTICIPANT]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s', 'Participants')
    },
    [INTERACTION_MODIFY_PARTICIPANT]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s', 'Participants', '%s')
    },
    [LEAVE_CHANNEL]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s')
    },
    [PAUSE_CHANNEL]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s')
    },
    [RESUME_CHANNEL]: {
        path: path.join('v1', 'Interactions', '%s', 'Channels', '%s')
    },
    [PAUSED_CHANNELS]: {
        path: path.join('v1/Channels')
    },
    [TRANSFER_CHANNEL]: {
        path: path.join('v1', 'Instances', '%s', 'Interactions', '%s', 'Channels', '%s', 'Transfers')
    }
};

export function getRoute(route: InteractionsEndpoint, ...args: Array<string>): Route {
    if (!routes[route]) {
        throw Errors.INVALID_ARGUMENT.clone(`Invalid route fetched <string>route "${route}" does not exist.`);
    }

    if (args.length) {
        const copy = { ...routes[route] };

        if (args.length !== (copy.path.match(/%s/g) || []).length) {
            throw Errors.INVALID_ARGUMENT.clone(`Invalid number of positional arguments supplied for route ${route}`);
        }

        args.forEach((arg) => {
            copy.path = copy.path.replace(/%s/, arg);
        });

        return copy;
    }

    return routes[route];
}

export function buildRegionalHost(edge: string = '', region: string = ''): string {
    let hostname = '';

    if (edge) {
        hostname += `.${edge}`;
    }
    if (region && region !== 'prod') {
        hostname += `.${region}`;
    }

    return hostname;
}
