import type { ContextManager } from "~/modules/contextManager/ContextManager";
import type { LoggerLevel, LoggerOptions } from "../Logger/Logger";
import { getDefaultLoggerOptions } from "./defaultLogger";

export class LoggerConfigImpl implements LoggerOptions {
    #level: LoggerLevel;

    constructor() {
        this.#level = getDefaultLoggerOptions().level;
    }

    get level(): LoggerLevel {
        return this.#level;
    }

    set level(level: LoggerLevel) {
        this.#level = level;
    }
}

export const setLogLevel = (ctx: ContextManager) => (level: LoggerLevel) => {
    ctx.getInstanceOf(LoggerConfigImpl).level = level;
};
