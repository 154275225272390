export type ErrorData = {
    


    name: string;
    


    message: string;
};




class TwilioError extends Error {
    


    readonly errorData: ErrorData;

    


    readonly name: string;

    


    readonly message: string;

    constructor(errorData: ErrorData, customMessage?: string) {
        super();
        this.errorData = errorData;
        this.name = errorData.name;
        this.message = customMessage ?? errorData.message;
    }

    clone(customMessage: string) {
        return new TwilioError(this.errorData, customMessage);
    }
}

export type TwilioErrors = {
    [key: string]: TwilioError;
};

export default TwilioError;
