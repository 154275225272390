import { Task } from 'twilio-taskrouter';
import isEmpty from 'lodash/isEmpty';
import { mapKeysToSnakeCase } from '../util/MapKeys';
import { RoutingData } from '../RoutingProperties';
import CbmParticipant, { ParticipantEventData, ParticipantType } from '../CbmParticipant';
import TwilioError from '../util/TwilioError';
import { getRoute, INTERACTION_ADD_PARTICIPANT } from '../util/Routes';
import { post } from '../util/Request';
import { getInteractionSid } from '../util/FlexInteractionSid';
import getChannels from './GetChannels';
import { promiseOrTimeout } from '../util/EventsTimeout';
import {
    AddVoiceParticipantMediaProperties,
    AddVoiceParticipantOptions,
    AddVoiceParticipantResponse,
    ParticipantResponse
} from './types/AddParticipant';








export default async function addVoiceParticipant(
    task: Task,
    options: AddVoiceParticipantOptions
): Promise<AddVoiceParticipantResponse> {
    if (isEmpty(task.attributes)) {
        throw new TypeError(`Failed to addVoiceParticipant in Task sid=${task.sid}. Missing attributes.`);
    }

    let pendingParticipantResponse: ParticipantResponse | undefined;

    const waitForParticipantToSettle = new Promise(
        (resolve: (value: CbmParticipant) => void, reject: (value: TwilioError) => void) => {
            const participantAddedHandler = (_task: Task, eventData: ParticipantEventData) => {
                if (eventData.participant_sid === pendingParticipantResponse?.sid) {
                    task.removeListener('participantAdded', participantAddedHandler);
                    resolve(new CbmParticipant(eventData));
                }
            };

            
            const participantAddFailedHandler = (_task: Task, eventData: any) => {
                if (eventData.participant_sid === pendingParticipantResponse?.sid) {
                    task.removeListener('participantAddFailed', participantAddFailedHandler);
                    reject(new TwilioError(eventData, `Failed to add Participant from Task sid=${task.sid}.`));
                }
            };

            const participantRemovedHandler = (_task: Task, eventData: ParticipantEventData) => {
                if (eventData.participant_sid === pendingParticipantResponse?.sid) {
                    task.removeListener('participantRemoved', participantRemovedHandler);
                    resolve(new CbmParticipant(eventData));
                }
            };

            
            const participantRemoveFailedHandler = (_task: Task, eventData: any) => {
                if (eventData.participant_sid === pendingParticipantResponse?.sid) {
                    task.removeListener('participantRemoved', participantRemoveFailedHandler);
                    reject(new TwilioError(eventData, `Failed to remove Participant from Task sid=${task.sid}.`));
                }
            };

            task.on('participantAdded', participantAddedHandler);
            task.on('participantAddFailed', participantAddFailedHandler);
            task.on('participantRemoved', participantRemovedHandler);
            task.on('participantRemoveFailed', participantRemoveFailedHandler);
        }
    );

    const interactionSid = getInteractionSid(task);
    const channels = await getChannels(task);
    const channelSid = channels.find((channel) => channel.type === 'voice')!.sid;
    const requestURL = getRoute(INTERACTION_ADD_PARTICIPANT, interactionSid, channelSid).path;

    const requestParams: {
        type: string;
        media_properties: {
            coordinates: {
                from: string;
                to: string;
            };
        } & AddVoiceParticipantMediaProperties;
        routing_properties?: RoutingData;
    } = {
        type: options.type,

        media_properties: {
            coordinates: {
                from: options.from,
                to: options.to
            },
            ...(options.mediaProperties && { ...mapKeysToSnakeCase(options.mediaProperties) })
        }
    };

    if (options.type === ParticipantType.Agent && options.routingProperties) {
        const { taskSid, workerSid, reservationSid } = options.routingProperties;
        requestParams.routing_properties = {
            task_sid: taskSid,
            worker_sid: workerSid,
            reservation_sid: reservationSid
        };
    }

    pendingParticipantResponse = await post<ParticipantResponse>(requestURL, requestParams);

    
    
    
    
    const fiveMinutesInMilliseconds = 5 * 60000;

    return {
        pendingParticipantResponse,
        waitForParticipantToSettle: promiseOrTimeout<CbmParticipant>(
            waitForParticipantToSettle,
            'Task.participantAdded',
            fiveMinutesInMilliseconds
        )
    };
}
