import { Reservation } from "twilio-taskrouter";
import type { Call } from "@twilio/voice-sdk";
import type { FlexCall } from "~/modules/FlexCall";
import { FlexCallImpl } from "~/modules/FlexCall";
import { ErrorCode, ErrorSeverity, FlexSdkError } from "~/modules/error";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { TaskRouterImpl } from "~/packages/taskrouter/TaskRouterImpl";
import type { TaskRouter } from "~/packages/taskrouter/TaskRouter";
import { VoiceActions } from "./VoiceActions";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import {
    getDefaultCallerID,
    getDefaultQueueSid,
    getDefaultWorkflowSid,
    hasVoiceTaskWithStatus,
    isOutboundCallingEnabled,
    isOutboundCallPending,
    isWorkerOffline,
    sendTrackingEvent
} from "./ActionUtils";
import { AccountConfigDataContainer } from "~/modules/config/AccountConfig/AccountConfigImpl/AccountConfigDataContainer/AccountConfigDataContainer";
import { VoiceControllerImpl } from "~/modules/voice/VoiceControllerImpl";
import { VoiceController } from "~/modules/voice/VoiceController";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";
import type { StartOutboundCallOptions } from "~/modules/actions/Actions";
import { AnalyticsInstance, EVENTS } from "~/modules/analytics/Analytics";
import { AnalyticsImpl } from "~/modules/analytics/AnalyticsImpl";

export class VoiceActionsImpl implements VoiceActions {
    readonly #taskRouter: TaskRouter;

    readonly #voiceController: VoiceController;

    readonly #accountConfig: AccountConfigDataContainer;

    readonly #clientOptions: ClientOptionsStore;

    readonly #analytics: AnalyticsInstance;

    readonly #logger: Logger;

    readonly #ctx: ContextManager;

    constructor(ctx: ContextManager) {
        this.#taskRouter = ctx.getInstanceOf(TaskRouterImpl);
        this.#voiceController = ctx.getInstanceOf(VoiceControllerImpl);
        this.#accountConfig = ctx.getInstanceOf(AccountConfigDataContainer);
        this.#clientOptions = ctx.getInstanceOf(ClientOptionsStore);
        this.#analytics = ctx.getInstanceOf(AnalyticsImpl);
        this.#logger = getLogger(ctx)(LoggerName.Actions);
        this.#logger.debug("VoiceActions constructed");
        this.#ctx = ctx;
    }

    



    async startOutboundCall(
        toNumber: string,
        fromNumber?: string,
        workflowSid?: string,
        taskQueueSid?: string,
        options: StartOutboundCallOptions = {}
    ): Promise<FlexCall> {
        this.#logger.debug(`startOutboundCall invoked to number: ${toNumber}`);

        const worker = this.#taskRouter.worker;
        const { attributesForTaskCreation, conferenceOptions } = options;

        if (!worker) {
            const errorMsg = `startOutboundCall: worker is not initialized`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        if (!toNumber) {
            const errorMsg = `startOutboundCall: toNumber is a required parameter`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        const fromNumberParam = fromNumber || getDefaultCallerID(this.#accountConfig.get().outboundCallFlows);
        if (!fromNumberParam) {
            const errorMsg = `startOutboundCall: fromNumber is required`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        const taskQueueSidParam = taskQueueSid || getDefaultQueueSid(this.#accountConfig.get().outboundCallFlows);
        if (!taskQueueSidParam) {
            const errorMsg = `startOutboundCall: taskQueueSid is required`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        const workflowSidParam = workflowSid || getDefaultWorkflowSid(this.#accountConfig.get().outboundCallFlows);
        if (!workflowSidParam) {
            const errorMsg = `startOutboundCall: workflowSid is required`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        const taskrouterOfflineActivitySid = this.#accountConfig.get().taskrouterOfflineActivitySid;
        if (!taskrouterOfflineActivitySid) {
            const errorMsg = "startOutboundCall: taskrouterOfflineActivitySid is undefined";
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        if (isWorkerOffline(worker, taskrouterOfflineActivitySid)) {
            const errorMsg = `startOutboundCall: worker is offline, outbound call cancelled`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        if (!isOutboundCallingEnabled(this.#accountConfig.get().outboundCallFlows)) {
            const errorMsg = `startOutboundCall: Outbound calling is disabled in Flex account configuration,
                                outbound call cancelled`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        
        if (isOutboundCallPending()) {
            const errorMsg = `startOutboundCall: Another outbound call is already pending, outbound call cancelled`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        if (hasVoiceTaskWithStatus(worker, "pending")) {
            const errorMsg = `startOutboundCall: Inbound call is pending, outbound call cancelled`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        if (hasVoiceTaskWithStatus(worker, "accepted")) {
            const errorMsg = `startOutboundCall: Another voice task is already in accepted status,
                                outbound call cancelled`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        await this.initVoiceController();

        if (!this.#voiceController.isAudioInputDeviceAvailable()) {
            const errorMsg = `startOutboundCall: no audio input device, outbound call cancelled`;
            this.#logger.error(errorMsg);
            sendTrackingEvent(EVENTS.StartOutboundCallCompleted, this.#analytics, worker, errorMsg);
            throw new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg);
        }

        
        return new Promise(async (resolve, reject) => {
            let timeoutId: number | NodeJS.Timeout;
            const handleIncomingCall = (call: Call) => {
                this.#logger.info(
                    "Incoming call event received in startOutboundCall",
                    call?.parameters?.From,
                    call?.parameters?.To
                );
                if (call?.parameters?.From === fromNumberParam) {
                    this.#logger.debug("From phone numbers match");
                } else {
                    this.#logger.error(
                        "Incoming call is coming from a different number, not from the one given as an argument to startOutboundCall action"
                    );
                }

                clearTimeout(timeoutId);
                this.#voiceController.unsubscribeFromIncomingCallEvent(handleIncomingCall);

                const flexCall = new FlexCallImpl(this.#ctx, call, this.#voiceController.voiceDevice);

                resolve(flexCall);
            };

            this.#voiceController.subscribeToIncomingCallEvent(handleIncomingCall);

            timeoutId = setTimeout(() => {
                this.#voiceController.unsubscribeFromIncomingCallEvent(handleIncomingCall);
                const errorMsg = `Timeout: No incoming call event received within 30 seconds`;
                this.#logger.error(errorMsg);
                reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
            }, 30000);

            let handleReservationFailed: (reservation: Reservation) => void;

            const handleReservationCreated = async (reservation: Reservation) => {
                try {
                    const conferenceCreated = await reservation.conference(conferenceOptions);
                    this.#logger.debug("Conference created", conferenceCreated);
                } catch (error) {
                    this.#logger.error("Error creating conference", error);
                } finally {
                    worker.off("reservationCreated", handleReservationCreated);
                    worker.off("reservationFailed", handleReservationFailed);
                }
            };

            handleReservationFailed = (reservation: Reservation) => {
                this.#logger.error("Failed to create reservation for task:", reservation?.task?.sid);
                worker.off("reservationCreated", handleReservationCreated);
                worker.off("reservationFailed", handleReservationFailed);
            };

            worker.on("reservationCreated", handleReservationCreated);
            worker.on("reservationFailed", handleReservationFailed);

            try {
                const outboundAttributes = { ...attributesForTaskCreation, direction: "outbound" };
                const taskOptions = {
                    taskChannelUniqueName: "voice",
                    attributes: outboundAttributes
                };

                await worker.createTask(toNumber, fromNumberParam, workflowSidParam, taskQueueSidParam, taskOptions);
            } catch (error) {
                clearTimeout(timeoutId);
                this.#voiceController.unsubscribeFromIncomingCallEvent(handleIncomingCall);
                reject(error);
            }
        });
    }

    async initVoiceController() {
        try {
            if (!this.#voiceController.isInitialized) {
                await this.#voiceController.init(this.#clientOptions?.voiceDevice);
            }
        } catch (e) {
            this.#logger.error("Failed to initialise VoiceController", e);
        }
    }
}
