import React from "react";

const Logout = () => {
    const login = () => {
        const jsonData = {
            app: "Rxcopilot",
            action: "flex-relogin",
            url:"https://aichatdev.sentrihub.com/"
          };
          window.parent.postMessage(
            jsonData,
            "https://sfwyqa.mhealthcoach.net/"
          );
    }
    
    return (
        <div className="logoutBtnDiv">
          <button className="logoutBtn" onClick={login}>
            Log In Again
        </button>
        </div>
    )
};

export default Logout;
    