import { TRANSFER_CHANNEL, getRoute } from '../util/Routes';
import { get } from '../util/Request';
import { GetTransferResponse, Transfer } from './types/Transfers';

function throwTypeError(paramName: string) {
    throw new TypeError(`Error calling method getChannelTransfers(). <string>${paramName} is a required parameter.`);
}

function verifyRequiredParameters(instanceSId: string, interactionSid: string, channelSid: string) {
    if (!instanceSId) {
        throwTypeError('instanceSId');
    }
    if (!interactionSid) {
        throwTypeError('interactionSid');
    }
    if (!channelSid) {
        throwTypeError('channelSid');
    }
}








export async function getChannelTransfers(
    instanceSid: string,
    interactionSid: string,
    channelSid: string
): Promise<Transfer[]> {
    verifyRequiredParameters(instanceSid, interactionSid, channelSid);
    const requestURL = getRoute(TRANSFER_CHANNEL, instanceSid, interactionSid, channelSid).path;

    const response = await get<GetTransferResponse[]>(requestURL);
    if (!Array.isArray(response)) {
        
        throw response;
    }

    return response.map((transfer) => ({
        sid: transfer.sid,
        accountSid: transfer.account_sid,
        instanceSid: transfer.instance_sid,
        interactionSid: transfer.interaction_sid,
        channelSid: transfer.channel_sid,
        type: transfer.type,
        from: transfer.from,
        to: transfer.to,
        status: transfer.status,
        executionSid: transfer.note_sid,
        noteSid: transfer.note_sid,
        summarySid: transfer.note_sid,
        reason: transfer.note_sid,
        dateCreated: transfer.date_created,
        dateUpdated: transfer.date_updated
    }));
}
