import { buildHTTPExecutor } from "@graphql-tools/executor-http";
import { schemaFromExecutor } from "@graphql-tools/wrap";
import { GraphQLSchema } from "graphql";
import { DataClientError } from "../error";

export interface RemoteSchemaProps {
    uri: string;
    token: string;
}

export const fetchRemoteSchema = async ({
    uri,
    token
}: 
RemoteSchemaProps): Promise<{ schema: GraphQLSchema; executor?: any }> => {
    const auth = btoa(`token:${token}`);
    try {
        const remoteExecutor = buildHTTPExecutor({
            endpoint: uri,
            useGETForQueries: true,
            headers: {
                Authorization: `Basic ${auth}`
            },
            retry: 5
        });

        return {
            schema: (await schemaFromExecutor(remoteExecutor)) as GraphQLSchema,
            executor: remoteExecutor
        };
    } catch (error) {
        return Promise.reject(new Error(DataClientError.GraphqlFetchRemoteSchemaFailed));
    }
};
