import isEmpty from 'lodash/isEmpty';
import { Task } from 'twilio-taskrouter';
import { TaskAttributes } from '../cbm_sdk_client/types/TaskAttributes';







export function getInteractionSid(task: Task): string {
    if (!task) {
        throw new Error('No task specified for getting interaction sid');
    }
    if (isEmpty(task.attributes)) {
        throw new Error("Task's attributes are not set");
    }

    let interactionSid = (task.attributes as TaskAttributes).flexInteractionSid;

    if (!interactionSid) {
        interactionSid = `KD${task.sid.slice(2)}`;
    }

    return interactionSid;
}
