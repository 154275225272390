import { HttpStatusCode } from "~/utils/HttpStatusCode";
import { ErrorCode } from "~/modules/error";











function generateRandomNumber(x: number = 100) {
    return Math.ceil(Math.random() * x);
}


export function generateBackoffInterval(initialDelay: number, maxDelay: number, retryCount: number): number {
    const calculatedBackoff = Math.max(initialDelay, (initialDelay * (2 ** retryCount - 1)) / 2);
    return Math.min(Math.max(initialDelay, calculatedBackoff), maxDelay) + generateRandomNumber();
}

export function isValidNumber(x: unknown) {
    return Number.isFinite(x) && Number(x) >= 0;
}

export const retryableStatusCodes = [
    HttpStatusCode.TooManyRequests,
    HttpStatusCode.InternalServerError,
    HttpStatusCode.BadGateway,
    HttpStatusCode.ServiceUnavailable,
    HttpStatusCode.GatewayTimeout
];

export const retryableErrorCodes = [
    ErrorCode.TooManyRequests,
    ErrorCode.InternalServerError,
    ErrorCode.BadGateway,
    ErrorCode.ServiceUnavailable,
    ErrorCode.GatewayTimeout,
    ErrorCode.NetworkError
];

export type RetryableErrorMap = { [TKey in HttpStatusCode]: ErrorCode };

export const retryableErrorMap: RetryableErrorMap = {
    [HttpStatusCode.TooManyRequests]: ErrorCode.TooManyRequests,
    [HttpStatusCode.InternalServerError]: ErrorCode.InternalServerError,
    [HttpStatusCode.BadGateway]: ErrorCode.BadGateway,
    [HttpStatusCode.ServiceUnavailable]: ErrorCode.ServiceUnavailable,
    [HttpStatusCode.GatewayTimeout]: ErrorCode.GatewayTimeout
};
