import { ContextManager } from "~/modules/contextManager/ContextManager";
import { getTelemetrySdkClient } from "../telemetrySdkClient";

export enum TelemetrySdkEventGroup {
    Default = "default",
    Error = "error"
}

export const getTelemetrySdkErrorEventGroup = (ctx: ContextManager) => {
    const errorClient = getTelemetrySdkClient(ctx);
    const group = errorClient.createEventGroup();
    return group;
};
